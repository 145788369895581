import {
  merge, cloneDeep, find, findIndex,
  assign, each, get, set, filter,
  slice, reduce, map, includes,
  pickBy, split, toUpper, join,
  isEqual, forEach, sortBy, indexOf,
  has, values as lodashValues, isNil,
  identity, concat, isEmpty, toLower,
  omit,
} from 'lodash';
import {
  addHours, endOfDay, format, parse, startOfDay, subHours, addDays,
} from 'date-fns';
import router from '@/router';
import * as api from '@/services/api';
import socket from '@/services/socket';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Ultimate from '@/services/helpers/ultimate';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import base64 from '@/utils/base64';
import randomUUID from '@/utils/random-uuid';
import {
  mappingTypes,
  getResponseObjectNameBySportId,
  getSportNameBySportId,
  getSportLabelBySportId,
  parseMappingDateOption,
  feedsOrder,
} from '@/services/helpers/mappings-mapper';
import sportIds from '@/services/helpers/sports';
import definedUserRoles from '@/services/helpers/user-roles';
import {
  mergeMarkets, mergeManualResultingMarkets, mergeTradingUiMarket, mergeOddsCheckerMarkets,
} from '@/services/helpers/market-updater';
import eventHelper from '@/services/helpers/event';
import { partitionCompetitions } from '@/services/helpers/hierarchy';
import { socketEventTypes } from '@/services/helpers/socket-events';
import computeStartsAt from '@/utils/computeStartsAt';
import {
  mapPlayerSetupData,
  mapUpdatedPlayerSetupData,
  mapPlayerSetupPayloadData,
  mapPlayerSetupTableDataResponse,
  checkIsPlayerSetupSubmitEnabled,
  mapMarketsByPlayerIndex,
  mergePlayerDataWithMarketPrices,
  mapPriceOnDemandPayload,
  updatePlayerPropsData,
  updatePlayerPropsDataTradingUI,
  checkIfRequiredFieldsEnteredByRow,
  requiredFieldsBySport,
  mapPlayerSetupInferMarketData,
  mergePlayerParamsOnDemand,
  extractUpdatedPlayerSetupInferData,
  mapPlayerSetupBaseballTeamLineupData,
} from '@/services/helpers/player-setup-mapper';

import {
  mapPlayerProps,
  isTradingPlayerPropsStateChanged,
  mapTradingPlayerPropsPublishPayload,
  mapTradingPlayerPropsPricesSimulatePayload,
  checkIsTradingPropsModelValid,
  mapSquadData,
} from '@/services/helpers/trading-ui-mapper';
import {
  mapPlayerSetupGameParams, mapPlayerSetupGameParamsSubmitPayload,
  mapPlayerSetupGameParamsSocketUpdate, getParamsManagerMarketsBySport,
  mapPlayerSetupBaseballGameParams, isBaseballGameParamsError,
  isBaseballGameParamsChanged, getBasketballGlobalParamsModelType,
  getBasketballGlobalParamsSportTypeByCompetition,
} from '@/components/player-setup/params-helper';
import marketMapper from '@/services/helpers/market-mapper';
import types from './mutation-types';
import { getSuperAdminData } from '../services/helpers/super-admin';
import {
  parseEventModel,
} from '@/services/helpers/event-drawer';
import { isAllowedUserRole } from '@/services/helpers/navbar';
import { getAllowedRoles } from '@/../config/userRoles';

const { DPS_TRADER } = definedUserRoles;

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

const encodeQueryParam = (value) => base64.encode(JSON.stringify(value));

const formatAndZoneTime = (date, dateModifier = (parsedDate) => parsedDate) => {
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const parsedDate = dateModifier(parse(date, 'yyyy-LL-dd', new Date()));
  const offsetDate = addHours(parsedDate, timezoneOffset);
  return format(offsetDate, 'yyyy-LL-dd\'T\'HH:mm:ss');
};

export default {
  setLatestRoute({ commit }, route) {
    commit(types.SET_LATEST_ROUTE, route);
  },
  updateColumns({ getters, commit }, columns = []) {
    let columnsToCommit = columns;
    if (getters.isDpsTraderRole) {
      columnsToCommit = pickBy(columns, (value) => !value.unavailableForRoles?.includes(DPS_TRADER));
      columnsToCommit.competition.visible = true;
    }
    commit(types.UPDATE_COLUMNS, columnsToCommit);
  },
  updateSearch({ commit }, search = '') {
    commit(types.UPDATE_SEARCH, search);
  },
  updateSelectedRowSpacingOption({ commit }, rowSpacing) {
    commit(types.UPDATE_SELECTED_ROW_SPACING_OPTION, rowSpacing);
  },
  setTotalRowsCount({ commit }, totalRowsCount) {
    commit(types.UPDATE_TOTAL_ROWS_COUNT, totalRowsCount);
  },
  setPaginationPage({ commit, dispatch }, page) {
    dispatch('unsubscribeEventList');
    commit(types.UPDATE_EVENT_LIST_LOADING, true);
    commit(types.UPDATE_EVENT_LIST, []);
    const route = router.currentRoute.value;
    router.replace({
      ...route,
      query: {
        ...route.query,
        page,
      },
    });
  },
  validatePaginationPage({ getters, dispatch }) {
    if (getters.currentPage >= 1 && getters.currentPage <= getters.lastPage) return;
    dispatch('setPaginationPage', 1);
  },
  setPaginationRowsPerPage(context, rowsPerPage) {
    const route = router.currentRoute.value;
    router.replace({
      ...route,
      query: {
        ...route.query,
        rowsPerPage,
      },
    });
  },
  validatePaginationRowsPerPage({ getters, dispatch }) {
    if (includes([10, 20, 50, 100], getters.rowsPerPage)) return;
    dispatch('setPaginationRowsPerPage', 100);
  },
  updateTableField({ state, commit }, { key, values }) {
    const eventTableFields = cloneDeep(state.columns);
    set(eventTableFields, key, merge({}, get(eventTableFields, key), values));
    commit(types.UPDATE_COLUMNS, eventTableFields);
  },
  reorderEventListField({ getters, commit }, { from, to }) {
    const fields = filter(getters.columns, (_, index) => index !== from);
    const newFields = [
      ...slice(fields, 0, to),
      getters.columns[from],
      ...slice(fields, to),
    ];
    commit(types.UPDATE_COLUMNS, reduce(
      newFields,
      (eventListFields, value, index) => ({
        ...eventListFields,
        [value.key]: {
          ...value,
          order: index,
        },
      }),
      {},
    ));
  },

  connectWebSocket({ state, dispatch }) {
    if (state.socketConnection) return;
    socket.connect(dispatch);
  },
  subscribeEventList({ state, getters }) {
    if (!getters.eventList?.length) return;
    socket.subscribeEventList(eventHelper.groupEventIdsBySource(state.eventList));
  },
  unsubscribeEventList({ state, getters }) {
    if (!getters.eventList?.length) return;
    socket.unsubscribeEventList(eventHelper.groupEventIdsBySource(state.eventList));
  },

  resetEvents({ commit, dispatch }) {
    dispatch('unsubscribeEventList');
    commit(types.UPDATE_EVENT_LIST_LOADING, true);
    commit(types.UPDATE_EVENT_LIST, []);
    dispatch('setTotalRowsCount', 0);
    dispatch('setPaginationPage', 1);
  },
  clearEvents({ commit, dispatch }) {
    dispatch('unsubscribeEventList');
    commit(types.UPDATE_EVENT_LIST, []);
  },
  addFilter({ getters }, decodedFilter) {
    const route = router.currentRoute.value;
    const filterToAdd = { ...decodedFilter };
    if (filterToAdd.inputType === 'select') {
      filterToAdd.value = typeof filterToAdd.value === 'string' ? [filterToAdd.value] : filterToAdd.value;
    }
    const updatedFilters = [...getters.eventListAppliedFilters, filterToAdd];
    router.replace({
      ...route,
      query: {
        ...(route.query || {}),
        filter: map(updatedFilters, (rawFilter) => base64.encode(JSON.stringify(rawFilter))),
      },
    });
  },
  updateFilter({ getters }, {
    decodedFilter,
    updatedField,
    updatedValue,
    modifier,
    inputType,
  }) {
    const route = router.currentRoute.value;
    const resolvedField = updatedField || decodedFilter.field;
    const resolvedValue = updatedValue || decodedFilter.value;
    const filterIndex = findIndex(getters.eventListAppliedFilters, { field: decodedFilter.field });
    if (filterIndex === -1) return;

    const updatedFilters = cloneDeep(getters.eventListAppliedFilters);
    if (inputType === 'select') {
      const updatedFilter = updatedFilters[filterIndex];
      if (includes(updatedFilter.value, resolvedValue)) {
        updatedFilter.value = filter(updatedFilter.value, (filterValue) => filterValue !== resolvedValue);
      } else if (resolvedField === 'price') {
        updatedFilter.value = [resolvedValue];
      } else if (resolvedField === 'booking') {
        updatedFilter.value = [resolvedValue];
      } else if (resolvedField === 'suspended') {
        updatedFilter.value = [resolvedValue];
      } else if (resolvedField === 'mapped') {
        updatedFilter.value = [resolvedValue];
      } else if (resolvedField === 'eventType') {
        updatedFilter.value = [resolvedValue];
      } else if (resolvedField === 'approved') {
        updatedFilter.value = [resolvedValue];
      } else if (typeof resolvedValue === 'string') {
        updatedFilter.value.push(resolvedValue);
      } else {
        updatedFilter.value = resolvedValue;
      }
    } else {
      updatedFilters[filterIndex] = {
        field: resolvedField,
        modifier,
        value: resolvedValue,
      };
    }

    if (decodedFilter.field !== resolvedField) {
      const fieldOptions = reduce(
        getters.eventListFilters,
        (filters, rawFilter) => {
          const isApplied = !!find(getters.eventListAppliedFilters, { field: rawFilter.field });
          if (isApplied) return filters;
          return [...filters, rawFilter];
        },
        [],
      );
      const newModel = find(fieldOptions, { field: resolvedField });
      if (!newModel) return;
      updatedFilters[filterIndex] = {
        ...updatedFilters[filterIndex],
        modifier: getters.eventListFilterModifiers[newModel.inputType][0].value,
        value: newModel.defaultValue(),
      };
    }

    const newValue = updatedFilters[filterIndex].value;
    if (!newValue || !newValue.length || (resolvedField === 'startsAt' && !newValue[0].length && !newValue[1].length)) {
      updatedFilters.splice(filterIndex, filterIndex || 1);
    }

    router.replace({
      ...route,
      query: {
        ...(route.query || {}),
        filter: map(updatedFilters, (rawFilter) => base64.encode(JSON.stringify(rawFilter))),
      },
    });
  },
  removeFilter({ getters }, decodedFilter) {
    const route = router.currentRoute.value;
    const updatedFilters = filter(getters.eventListAppliedFilters, (appliedFilter) => decodedFilter.field !== appliedFilter.field);
    router.replace({
      ...route,
      query: {
        ...(route.query || {}),
        filter: map(updatedFilters, (rawFilter) => base64.encode(JSON.stringify(rawFilter))),
      },
    });
  },
  updateSort(_, sort) {
    const route = router.currentRoute.value;
    if (!sort) return;

    router.replace({
      ...route,
      query: {
        ...(route.query || {}),
        sort: encodeQueryParam(sort),
      },
    });
  },

  updateSelectedEvents({ commit }, events = []) {
    commit(types.UPDATE_SELECTED_EVENTS, events);
  },
  updateSelectedMarkets({ commit }, events = []) {
    commit(types.UPDATE_SELECTED_MARKETS, events);
  },
  setIsEventsSidebarActive({ commit }, isActive) {
    commit(types.IS_EVENTS_SIDEBAR_ACTIVE, isActive);
  },
  setIsEventsFiltersActive({ commit }, isActive) {
    commit(types.IS_EVENTS_FILTERS_ACTIVE, isActive);
  },
  loadEvent({ commit, getters, dispatch }, id) {
    if (getters.event || getters.eventMarkets) dispatch('unloadEvent', id);

    commit(types.UPDATE_EVENT_LOADING, true);
    commit(types.UPDATE_EVENT_MARKETS_LOADING, true);

    api.findEventById(id)
      .then((event) => {
        commit(types.UPDATE_EVENT, event);
        socket.subscribeToEvents({
          ...eventHelper.mapEventIdAndSource(event),
          type: socketEventTypes.EVENT,
        });
        api.getMarketDisplayConfigurations({ sportId: event?.sport.sportId })
          .then(({ marketDisplayConfigurations }) => {
            const marketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
              ...markets,
              [`${market.marketCode}_${market.selections}`]: market,
            }), {});
            commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, marketsDisplayConfiguration);
          })
          .catch((err) => {
            console.error(err);
            commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, {});
          });
        api.findEventMarketsById({ id })
          .then((eventMarkets) => {
            commit(types.UPDATE_EVENT_MARKETS, eventMarkets);
          })
          .catch((err) => {
            console.error(err);
            commit(types.UPDATE_EVENT_MARKETS, null);
          })
          .finally(() => {
            commit(types.UPDATE_EVENT_MARKETS_LOADING, false);
          });
        dispatch('loadAllMarketParameters');
        // Getting team squad data for football, basketball
        if (event.sport.sportId === '841e188b-0dcf-4f5f-974f-aa52c8cec95b' || event.sport.sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f' || event.sport.sportId === '4e8eca10-6afa-44ed-af77-42414ec45cb3') {
          api.getTeamSquad({
            teamId: event.competitors[0]?.competitorId,
            sportId: event.sportId,
          })
            .then((team) => {
              commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'home', players: map(team.squad, (squad) => squad.player) });
            })
            .catch((err) => {
              console.error(err);
              commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'home', players: [] });
            });
          api.getTeamSquad({
            teamId: event.competitors[1]?.competitorId,
            sportId: event.sportId,
          })
            .then((team) => {
              commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'away', players: map(team.squad, (squad) => squad.player) });
            })
            .catch((err) => {
              console.error(err);
              commit(types.UPDATE_EVENT_TEAM_PLAYERS, { team: 'away', players: [] });
            });
        }
      })
      .catch((err) => {
        console.error(err);
        commit(types.UPDATE_EVENT, null);
        commit(types.UPDATE_EVENT_MARKETS, null);
      })
      .finally(() => {
        commit(types.UPDATE_EVENT_LOADING, false);
        commit(types.UPDATE_EVENT_MARKETS_LOADING, false);
      });
  },
  reloadEvent({ getters, dispatch }) {
    const { id = '' } = getters.event ?? {};

    if (!id) return;

    dispatch('loadEvent', id);
  },
  setEventSuspendStatus({ state, commit }, { eventId, isSuspended }) {
    if (state.event?.eventId !== eventId) return;
    const updatedEvent = cloneDeep(state.event);
    if (isSuspended) {
      set(updatedEvent, 'operatorEventSuspensionsByEventId.nodes', [{}]);
    } else {
      set(updatedEvent, 'operatorEventSuspensionsByEventId.nodes', []);
    }
    commit(types.UPDATE_EVENT, updatedEvent);
  },
  updateEvent({ state, getters, dispatch }, payload) {
    if (getters.tradingEvent && state.tradingEvent.eventId === payload.eventId) {
      dispatch('updateEventDetails', {
        type: 'SET_TRADING_EVENT',
        field: 'tradingEvent',
        payload,
      });
    }

    if (getters.eventLoaded && state.event.eventId === payload.eventId) {
      dispatch('updateEventDetails', {
        type: 'UPDATE_EVENT',
        field: 'event',
        payload,
      });
    }

    if (getters.multiviewListEventIds.length) {
      dispatch('updateMultiviewListEventDetails', payload);
    }

    if (getters.multiviewDrawerEvent && getters.multiviewDrawerEvent.eventId === payload.eventId) {
      dispatch('updateMultiviewEventDrawerEventDetails', payload);
    }

    if (getters.playerSetupData && state.playerSetupData?.eventId === payload.eventId) {
      dispatch('updateParamsManagerEventDetails', payload);
    }
  },
  updateEventDetails({ state, commit, getters }, { type, field, payload }) {
    let matchState;
    let eventDetails;
    let sourceEventDetails;
    let eventDetailsField;
    let updatedDetailsNodes = [];

    if (state[field].matchState !== 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'FIRST_PERIOD')
        || payload.state.period === 'FIRST_QUARTER' || payload.state.period === 'IN_FIRST_HALF') {
        matchState = 'LIVE';
      } else {
        matchState = state[field].matchState;
      }
    } else if (state[field].matchState === 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'POST_GAME')
        || payload.state.period === 'EVENT_COMPLETED') {
        matchState = 'FINISHED';
      } else {
        matchState = state[field].matchState;
      }
    } else {
      matchState = state[field].matchState;
    }

    const selectedTradingEventFeed = getters.tradingEventSelectedFeed;

    switch (state[field].sportId) {
    case SOCCER_ID:
      eventDetailsField = 'soccerEventDetails';
      sourceEventDetails = eventHelper.findEventDetails(state[field], selectedTradingEventFeed);
      eventDetails = Soccer.updateEvent(sourceEventDetails, payload);
      updatedDetailsNodes = concat([], [eventDetails],
        filter(state[field]?.[eventDetailsField]?.nodes, (details) => details.source !== eventDetails.source));
      break;
    case BASKETBALL_ID:
      eventDetailsField = 'basketballEventDetails';
      sourceEventDetails = eventHelper.findEventDetails(state[field], selectedTradingEventFeed);
      eventDetails = Basketball.updateEvent(sourceEventDetails, payload);
      updatedDetailsNodes = concat([], [eventDetails],
        filter(state[field]?.[eventDetailsField]?.nodes, (details) => details.source !== eventDetails.source));
      break;
    case FOOTBALL_ID:
      eventDetailsField = 'footballEventDetails';
      sourceEventDetails = eventHelper.findEventDetails(state[field], selectedTradingEventFeed);
      eventDetails = Football.updateEvent(sourceEventDetails, payload);
      updatedDetailsNodes = concat([], [eventDetails],
        filter(state[field]?.[eventDetailsField]?.nodes, (details) => details.source !== eventDetails.source));
      break;
    case ULTIMATE_ID:
      eventDetailsField = 'ultimateEventDetails';
      sourceEventDetails = eventHelper.findEventDetails(state[field], selectedTradingEventFeed);
      eventDetails = Ultimate.updateEvent(sourceEventDetails, payload);
      updatedDetailsNodes = concat([], [eventDetails],
        filter(state[field]?.[eventDetailsField]?.nodes, (details) => details.source !== eventDetails.source));
      break;
    case HOCKEY_ID:
      eventDetailsField = 'hockeyEventDetails';
      sourceEventDetails = eventHelper.findEventDetails(state[field], selectedTradingEventFeed);
      eventDetails = Hockey.updateEvent(sourceEventDetails, payload);
      updatedDetailsNodes = concat([], [eventDetails],
        filter(state[field]?.[eventDetailsField]?.nodes, (details) => details.source !== eventDetails.source));
      break;
    case BASEBALL_ID:
      eventDetailsField = 'baseballEventDetails';
      sourceEventDetails = eventHelper.findEventDetails(state[field], selectedTradingEventFeed);
      eventDetails = Baseball.updateEvent(sourceEventDetails, payload);
      updatedDetailsNodes = concat([], [eventDetails],
        filter(state[field]?.[eventDetailsField]?.nodes, (details) => details.source !== eventDetails.source));
      break;
    default:
      return;
    }

    commit(type, {
      ...state[field],
      matchState,
      [eventDetailsField]: {
        nodes: updatedDetailsNodes,
      },
    });
  },
  updateParamsManagerEventDetails({ state, commit }, payload) {
    let matchState;
    let updatedPlayerSetupData;
    try {
      if (state.playerSetupData?.matchState !== 'LIVE') {
        if ((payload.state.currentClock && payload.state.currentClock?.period === 'FIRST_PERIOD')
          || payload.state.period === 'FIRST_QUARTER' || payload.state.period === 'IN_FIRST_HALF') {
          matchState = 'LIVE';
        } else {
          matchState = state.playerSetupData?.matchState;
        }
      } else if (state.playerSetupData?.matchState === 'LIVE') {
        if ((payload.state.currentClock && payload.state.currentClock?.period === 'POST_GAME')
          || payload.state.period === 'EVENT_COMPLETED') {
          matchState = 'FINISHED';
        } else {
          matchState = state.playerSetupData?.matchState;
        }
      } else {
        matchState = state.playerSetupData?.matchState;
      }

      switch (state.playerSetupData?.sportId) {
      case SOCCER_ID:
        updatedPlayerSetupData = Soccer.updateEvent(state.playerSetupData, payload);
        break;
      case BASKETBALL_ID:
        updatedPlayerSetupData = Basketball.updateEvent(state.playerSetupData, payload);
        break;
      case FOOTBALL_ID:
        updatedPlayerSetupData = Football.updateEvent(state.playerSetupData, payload);
        break;
      case ULTIMATE_ID:
        updatedPlayerSetupData = Ultimate.updateEvent(state.playerSetupData, payload);
        break;
      case HOCKEY_ID:
        updatedPlayerSetupData = Hockey.updateEvent(state.playerSetupData, payload);
        break;
      case BASEBALL_ID:
        updatedPlayerSetupData = Baseball.updateEvent(state.playerSetupData, payload);
        break;
      default:
        return;
      }
      commit(types.SET_PLAYER_SETUP_DATA, {
        ...updatedPlayerSetupData,
        matchState,
      });
    } catch (e) {
      console.error(e);
    }
  },
  batchUpdateEventMarkets({ state, getters, commit }, incomingMarkets) {
    if (!isEmpty(state.manualResultingEventMarkets)) {
      const updatedManualResultingMarkets = mergeManualResultingMarkets(cloneDeep(state.manualResultingEventMarkets), incomingMarkets);
      commit(types.UPDATE_MANUAL_RESULTING_EVENT_MARKETS, updatedManualResultingMarkets);
      return;
    }
    if (state.tradingEvent) {
      const allMarkets = cloneDeep(state.tradingEventMarkets);
      each(incomingMarkets, (market) => {
        if (state.tradingEvent.eventId !== market.eventId) return;
        const updatedTradingUiMarket = mergeTradingUiMarket(allMarkets, market);
        if (updatedTradingUiMarket.update) commit(types.UPDATE_SINGLE_TRADING_EVENT_MARKET, updatedTradingUiMarket.market);
      });
      const availableGameMarkets = map(getters.paramsManagerMarkets, (market) => market.marketCode);
      const gameMarketsToUpdate = filter(incomingMarkets, (market) => includes(availableGameMarkets, market.marketType.marketCode));
      if (gameMarketsToUpdate.length) {
        const updatedParamsSetupGameMarkets = map(cloneDeep(getters.paramsManagerMarkets), (market) => {
          const foundMarket = find(incomingMarkets, (incomingMarket) => incomingMarket.marketId === market.marketId);
          if (!foundMarket) return market;
          return {
            ...market,
            suspended: foundMarket?.offeringStatus === 'SUSPENDED',
          };
        });
        commit(types.SET_PARAMS_MANAGER_MARKETS, updatedParamsSetupGameMarkets);
      }
      return;
    }
    if (!getters.eventLoaded || !getters.eventMarketsLoaded) return;
    const updatedEventMarkets = mergeMarkets(state.eventMarkets, incomingMarkets);
    commit(types.UPDATE_EVENT_MARKETS, updatedEventMarkets);
  },
  unloadEvent({
    state,
    getters,
    commit,
    dispatch,
  }, eventId) {
    if (getters.event) {
      socket.unsubscribeFromEvents({
        ...eventHelper.mapEventIdAndSource(state.event),
        type: socketEventTypes.EVENT,
      });
      commit(types.UPDATE_EVENT, null);
    }

    if (getters.tradingEvent) {
      socket.unsubscribeFromEvents({
        ...eventHelper.mapEventIdAndSource(state.tradingEvent),
        type: socketEventTypes.TRADING,
      });
      socket.unsubscribeFromPricingProbabilities(state.tradingEvent.eventId);
    }

    if (getters.eventMarkets) {
      commit(types.UPDATE_EVENT_MARKETS, null);
    }
    if (includes(getters.suspendedEvents, eventId)) {
      dispatch('suspendEvent', eventId);
    }
  },

  processEventInfo({ getters, dispatch }, data) {
    if (getters.event) dispatch('updateEventInfo', data);
    if (getters.eventList?.length) dispatch('updateEventListInfo', data);
    if (getters.tradingEvent) dispatch('updateTradingEventInfo', data);
    if (getters.multiviewListEventIds?.length) dispatch('updateMultiviewEventListInfo', data);
    if (!isEmpty(getters.oddsCheckerEvents)) dispatch('updateOddsCheckerEvents', data);
    if (getters['betTicker/liabilityEvents']?.length) dispatch('betTicker/updateLiabilityEventInfo', data);
  },
  processEventFeedChange({ getters, dispatch }, data) {
    const { operatorId = '', eventId = '' } = data || {};

    if (getSuperAdminData().isSuperAdmin) {
      const urlParams = new URLSearchParams(window.location.search);
      const selectedOperatorId = urlParams.get('client');
      if (operatorId !== selectedOperatorId) return;
    } else if (getters.operator) {
      if (operatorId !== getters.operator) return;
    }

    if (find(getters.eventList, { eventId })) dispatch('loadEvents');
    if (eventId === getters.event?.id) dispatch('reloadEvent');
    if (getters.multiviewListEventsById?.[eventId]) dispatch('reloadMultiview');
    if (eventId === getters.tradingEvent?.eventId) dispatch('reloadTradingEvent');
    if (eventId === getters.multiviewDrawerEvent?.eventId) dispatch('reloadMultiviewDrawer');
    if (eventId === getters.playerSetupData?.eventId) dispatch('reloadPlayerSetupDetails');
  },
  updateEventInfo({ state, commit }, data) {
    const {
      eventId,
      eventName,
      startDateTime: startsAt,
      status,
      matchStatus: matchState,
      competitors,
      isUSAView: isUsaView,
    } = data ?? {};
    const { event } = state;

    if (eventId !== event.eventId) return;

    const updatedEvent = {
      ...event,
      eventName,
      startsAt,
      status,
      matchState,
      competitors,
      isUsaView,
    };
    commit(types.UPDATE_EVENT, updatedEvent);
  },
  updateTradingEventInfo({ state, commit }, data) {
    const {
      eventId,
      eventName,
      startDateTime: startsAt,
      status,
      matchStatus: matchState,
      competitors,
      isUSAView: isUsaView,
    } = data ?? {};
    const tradingEvent = cloneDeep(state.tradingEvent);

    if (eventId !== tradingEvent.eventId) return;

    const updatedEvent = {
      ...tradingEvent,
      eventName,
      startsAt,
      status,
      matchState,
      competitors,
      isUsaView,
    };
    commit(types.SET_TRADING_EVENT, updatedEvent);
  },
  updateEventLimits({ state, getters, commit }, data) {
    const {
      eventId,
      limitConfiguration,
    } = data ?? {};
    if (getters.multiviewListEventIds.length && getters.multiviewListEventsById[eventId]) {
      const event = cloneDeep(getters.multiviewListEventsById[eventId]);
      commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, {
        ...getters.multiviewListEventsById,
        [eventId]: {
          ...event,
          limitConfiguration: {
            nodes: [{ limitConfiguration }],
          },
        },
      });
    }
    if (!state.tradingEvent) return;
    const tradingEvent = cloneDeep(state.tradingEvent);

    if (eventId !== tradingEvent?.eventId) return;

    const updatedEvent = {
      ...tradingEvent,
      limitConfiguration: {
        nodes: [{ limitConfiguration }],
      },
    };
    commit(types.SET_TRADING_EVENT, updatedEvent);
  },
  updateEventListInfo({
    state,
    getters,
    commit,
    dispatch,
  }, data) {
    const {
      eventId,
      eventName,
      startDateTime: startsAt,
      status,
      matchStatus: matchState,
      competitors,
      isUSAView: isUsaView,
    } = data ?? {};

    const eventIndex = findIndex(state.eventList, { eventId });
    if (eventIndex === -1) return;

    if (
      matchState !== state.eventList[eventIndex].matchState
      && find(getters.eventListAppliedFilters, { field: 'matchState' })
    ) {
      dispatch('loadEvents');
      return;
    }

    const updatedEvents = cloneDeep(state.eventList);
    updatedEvents[eventIndex] = {
      ...updatedEvents[eventIndex],
      eventName,
      startsAt,
      status,
      matchState,
      competitors,
      isUsaView,
    };
    commit(types.UPDATE_EVENT_LIST, updatedEvents);
  },
  processEventReadiness({ getters, dispatch }, data) {
    if (getters.eventList?.length) dispatch('setEventListEventReadiness', data);
    if (getters.playerSetupData) dispatch('setPlayerSetupEventReadiness', data);
  },
  setEventListEventReadiness({ state, commit }, data) {
    const eventIndex = findIndex(state.eventList, {
      eventId: data?.eventId || '',
    });

    if (eventIndex === -1) return;

    const updatedEvents = cloneDeep(state.eventList);
    updatedEvents[eventIndex].isApproved = data.isApproved;
    commit(types.UPDATE_EVENT_LIST, updatedEvents);
  },
  setPlayerSetupEventReadiness({ getters, commit, dispatch }, { eventId, isApproved = false }) {
    if (!getters.playerSetupData?.eventId || getters.playerSetupData.eventId !== eventId) return;

    const eventData = cloneDeep(getters.playerSetupData);
    eventData.isPricesApproved = isApproved;
    commit(types.SET_PLAYER_SETUP_DATA, eventData);

    if (isApproved) {
      dispatch('addNotification', {
        message: 'Prices successfully approved!',
        type: 'success',
        duration: 5000,
      });
    }
  },

  templateMarketSelectedUpdate({ getters, commit }, payload) {
    commit(types.UPDATE_SELECTED_TEMPLATE_MARKET, merge({}, getters.marketTemplate, payload));
  },
  selectCompetitionTemplate({ dispatch }, payload) {
    api.updateSportTemplateCompetition(payload)
      .then(() => {
        dispatch('competitionTemplateChange', true);
      })
      .catch((err) => { console.log(err); });
  },
  competitionTemplateChange({ commit }, value) {
    commit(types.UPDATE_COMPETITION_TEMPLATE_CHANGE, value);
  },
  addNewSportTemplate({ dispatch }, { payload, sportId }) {
    api.createSportTemplate(payload, sportId)
      .then(() => {
        dispatch('getSportTemplates', sportId);
      })
      .catch((err) => { console.log(err); });
  },
  cloneSportTemplate({ dispatch }, payload) {
    return api.cloneSportTemplate(payload)
      .then((response) => {
        dispatch('getSportTemplates', payload.template.sportId);
        return response;
      })
      .catch((err) => { console.log(err); });
  },
  deleteSportTemplate({ dispatch }, { templateId, sportId }) {
    api.deleteSportTemplate(templateId)
      .then(() => {
        dispatch('getSportTemplates', sportId);
      })
      .catch((err) => { console.log(err); });
  },
  setDefaultSportTemplate({ dispatch }, { templateId, sportId }) {
    api.setSportTemplateAsDefault(templateId)
      .then(() => {
        dispatch('getSportTemplates', sportId);
      })
      .catch((err) => { console.log(err); });
  },
  async login({ dispatch }, userData) {
    try {
      // I. Authenticate current user
      const response = await api.authenticateUser(userData);
      if (response.message === 'newPasswordRequired') {
        dispatch('changePasswordAttributes', response.data);
        throw new Error('Auth failed. New password required!');
      }
      dispatch('setUserToken', response.data.token);
      dispatch('setUsername', userData.username);
      dispatch('updateUserRoles', response.data.userRoles);
      dispatch('updateOperator', response.data.operator);
      dispatch('connectWebSocket');
      dispatch('initializeSelectedOddsFormat');
    } catch (error) {
      console.error(error);
      dispatch('removeUserToken');
      dispatch('removeUsername');
      dispatch('removeUserRoles');
      dispatch('removeOperator');
      dispatch('loginErrorMessage', error);
    }
  },
  logout({ commit, dispatch }) {
    commit(types.SET_SIDEBAR_SPORTS, []);
    api.logout();
    dispatch('removeUserToken');
    dispatch('removeUserRoles');
    dispatch('removeOperator');
    socket.disconnect();
  },
  setUserToken({ commit }, jwtToken) {
    commit(types.UPDATE_JWT_TOKEN, jwtToken);
    localStorage.setItem('userToken', jwtToken);
  },
  removeUserToken({ commit }) {
    commit(types.UPDATE_JWT_TOKEN, null);
    localStorage.removeItem('userToken');
  },
  setUsername({ commit }, username) {
    commit(types.UPDATE_USERNAME, username);
    localStorage.setItem('username', username);
  },
  removeUsername({ commit }) {
    commit(types.UPDATE_USERNAME, null);
    localStorage.removeItem('userToken');
  },
  updateUserRoles({ commit }, roles) {
    const userRoles = roles || [];
    commit(types.UPDATE_USER_ROLES, userRoles);
    localStorage.setItem('huddleRoles', userRoles);
  },
  removeUserRoles({ commit }) {
    commit(types.UPDATE_USER_ROLES, []);
    localStorage.removeItem('huddleRoles');
  },
  updateOperator({ commit }, operator) {
    commit(types.UPDATE_OPERATOR, operator);
    localStorage.setItem('operator', operator);
  },
  removeOperator({ commit }) {
    commit(types.UPDATE_OPERATOR, '');
    localStorage.removeItem('operator');
  },
  loginErrorMessage({ commit }, errorMessage) {
    commit(types.UPDATE_LOGIN_ERROR_MESSAGE, errorMessage);
  },
  toggleSocketConnection({ commit }, connection) {
    commit(types.UPDATE_SOCKET_CONNECTION, connection);
  },
  getEventSuggestedMappings({ dispatch }, { data, url }) {
    return new Promise((resolve, reject) => {
      api.getEventSuggestedMappings({ data, url })
        .then((response) => {
          dispatch('saveEventSuggestedMappings', response);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          dispatch('saveEventSuggestedMappings', []);
          reject();
        });
    });
  },
  saveEventSuggestedMappings({ commit }, payload) {
    commit(types.SAVE_EVENT_SUGGESTED_MAPPINGS, payload);
  },
  getCompetitorSuggestedMappings({ dispatch }, { data, url }) {
    return new Promise((resolve, reject) => {
      api.getCompetitorSuggestedMappings({ data, url })
        .then((response) => {
          dispatch('saveCompetitorSuggestedMappings', response);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          dispatch('saveCompetitorSuggestedMappings', []);
          reject();
        });
    });
  },
  saveCompetitorSuggestedMappings({ commit }, payload) {
    commit(types.SAVE_COMPETITOR_SUGGESTED_MAPPINGS, payload);
  },
  getRegionSuggestedMappings({ dispatch }, { data, url }) {
    return new Promise((resolve, reject) => {
      api.getRegionSuggestedMappings({ data, url })
        .then((response) => {
          dispatch('saveRegionSuggestedMappings', response);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          dispatch('saveRegionSuggestedMappings', []);
          reject();
        });
    });
  },
  saveRegionSuggestedMappings({ commit }, payload) {
    commit(types.SAVE_REGION_SUGGESTED_MAPPINGS, payload);
  },
  getCompetitionSuggestedMappings({ dispatch }, { data, url }) {
    return new Promise((resolve, reject) => {
      api.getCompetitionSuggestedMappings({ data, url })
        .then((response) => {
          dispatch('saveCompetitionSuggestedMappings', response);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          dispatch('saveCompetitionSuggestedMappings', []);
          reject();
        });
    });
  },
  saveCompetitionSuggestedMappings({ commit }, payload) {
    commit(types.SAVE_COMPETITION_SUGGESTED_MAPPINGS, payload);
  },
  getPlayerSuggestedMappings({ dispatch }, { data, url }) {
    return new Promise((resolve, reject) => {
      api.getPlayerSuggestedMappings({ data, url })
        .then((response) => {
          dispatch('savePlayerSuggestedMappings', response);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          dispatch('savePlayerSuggestedMappings', []);
          reject();
        });
    });
  },
  savePlayerSuggestedMappings({ commit }, payload) {
    commit(types.SAVE_PLAYER_SUGGESTED_MAPPINGS, payload);
  },
  approveEventMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.approveEventMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  approveCompetitorMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.approveCompetitorMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  createCompetitorMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.createCompetitorMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  approveRegionMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.approveRegionMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  createRegionMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.createRegionMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  approveCompetitionMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.approveCompetitionMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  createCompetitionMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.createCompetitionMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  approvePlayerMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.approvePlayerMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  createPlayerMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.createPlayerMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  approveEventCompetitorMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.approveEventCompetitorMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  createEventCompetitorMapping(_, payload) {
    return new Promise((resolve, reject) => {
      api.createEventCompetitorMapping(payload)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  reloadMappingData({ getters, dispatch }, mappingType) {
    switch (mappingType) {
    case mappingTypes.EVENTS_MAPPING:
      if (getters.mappingSelectedFeed === 'huddle') {
        return dispatch('loadMappingEvents');
      }
      return dispatch('loadUnmappedEvents');
    case mappingTypes.COMPETITORS_MAPPING:
      if (getters.mappingSelectedFeed === 'huddle') {
        return dispatch('loadMappingCompetitors');
      }
      return dispatch('loadExtMappingCompetitors');
    case mappingTypes.REGIONS_MAPPING:
      if (getters.mappingSelectedFeed === 'huddle') {
        return dispatch('loadMappingRegions');
      }
      return dispatch('loadExtMappingRegions');
    case mappingTypes.COMPETITIONS_MAPPING:
      if (getters.mappingSelectedFeed === 'huddle') {
        return dispatch('loadMappingCompetitions');
      }
      return dispatch('loadExtMappingCompetitions');
    case mappingTypes.PLAYERS_MAPPING:
      if (getters.mappingSelectedFeed === 'huddle') {
        return dispatch('loadMappingPlayers');
      }
      return dispatch('loadExtMappingPlayers');
    default:
      return Promise.resolve();
    }
  },
  loadMappingData({ dispatch }, mappingType) {
    dispatch('mappingLoading', true);
    return dispatch('reloadMappingData', mappingType)
      .finally(() => {
        dispatch('mappingLoading', false);
      });
  },
  loadMappingEvents({ getters, commit, dispatch }) {
    const { startDate, endDate } = parseMappingDateOption(getters.mappingSelectedDate);
    const options = {
      orderBy: 'STARTS_AT_ASC',
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        sportId: {
          in: [getters.mappingSelectedSportId],
        },
        startsAt: {
          greaterThanOrEqualTo: format(startDate, 'yyyy-LL-dd\'T\'HH:mm:ss'),
          lessThanOrEqualTo: format(endDate, 'yyyy-LL-dd\'T\'HH:mm:ss'),
        },
        eventType: {
          equalTo: 'ORIGINAL',
        },
      },
    };

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery && searchOption === 'id') {
      options.filter = {
        ...options.filter,
        eventId: {
          equalTo: searchQuery,
        },
      };
    }
    if (searchQuery && searchOption !== 'id') {
      options.condition = {
        eventNameSimilar: searchQuery,
      };
    }

    if (getters.mappingSelectedDisplay.length === 1) {
      assign(options.filter, {
        eventIsMappedsByEventId: {
          every: {
            isMapped: {
              equalTo: includes(getters.mappingSelectedDisplay, 'mapped'),
            },
          },
        },
      });
    }

    return api.useEventsMappingQuery(options)
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.allEvents?.nodes);
        dispatch('setTotalRowsCount', response.data?.allEvents?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadUnmappedEvents({ getters, commit, dispatch }) {
    const { startDate, endDate } = parseMappingDateOption(getters.mappingSelectedDate);
    const options = {
      orderBy: 'STARTS_AT_UTC_ASC',
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        feed: {
          equalTo: getters.mappingSelectedFeed,
        },
        sportsLabel: {
          in: [getters.mappingSelectedSportLabel],
        },
        startsAtUtc: {
          greaterThanOrEqualTo: startDate.getTime(),
          lessThanOrEqualTo: endDate.getTime(),
        },
      },
    };

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'extId' : 'extRef';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    if (getters.mappingSelectedDisplay.length === 1) {
      assign(options.filter, {
        mappingType: includes(getters.mappingSelectedDisplay, 'mapped') ? { equalTo: 'EXACT_MATCH' } : { notEqualTo: 'EXACT_MATCH' },
      });
    }

    return api.useExtEventsMappingQuery(options)
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.allEventMappings?.nodes);
        dispatch('setTotalRowsCount', response.data?.allEventMappings?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadMappingCompetitors({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
    };

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'teamId' : 'name';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    return api.useTeamsQuery({
      sportId: getters.mappingSelectedSportId,
      queryOptions: options,
    })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.teams?.nodes);
        dispatch('setTotalRowsCount', response.data?.teams?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadExtMappingCompetitors({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        feed: {
          equalTo: getters.mappingSelectedFeed,
        },
      },
    };

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'extId' : 'extRef';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    if (getters.mappingSelectedDisplay.length === 1) {
      assign(options.filter, {
        mappingType: includes(getters.mappingSelectedDisplay, 'mapped') ? { equalTo: 'EXACT_MATCH' } : { notEqualTo: 'EXACT_MATCH' },
      });
    }

    return api.useExtTeamsQuery({
      sportId: getters.mappingSelectedSportId,
      queryOptions: options,
    })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.[`${getResponseObjectNameBySportId(getters.mappingSelectedSportId, 'Team')}`]?.nodes);
        dispatch('setTotalRowsCount', response.data?.[`${getResponseObjectNameBySportId(getters.mappingSelectedSportId, 'Team')}`]?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadMappingRegions({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
    };
    // TODO -> isMapped filter

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'regionId' : 'regionName';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    return api.useRegionsQuery({ queryOptions: options })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.regions?.nodes);
        dispatch('setTotalRowsCount', response.data?.regions?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadExtMappingRegions({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        feed: {
          equalTo: getters.mappingSelectedFeed,
        },
      },
    };

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'extId' : 'extRef';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    if (getters.mappingSelectedDisplay.length === 1) {
      assign(options.filter, {
        mappingType: includes(getters.mappingSelectedDisplay, 'mapped') ? { equalTo: 'EXACT_MATCH' } : { notEqualTo: 'EXACT_MATCH' },
      });
    }

    return api.useExtRegionsQuery({ queryOptions: options })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.allRegionMappings?.nodes);
        dispatch('setTotalRowsCount', response.data?.allRegionMappings?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadMappingCompetitions({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        sportId: {
          in: [getters.mappingSelectedSportId],
        },
      },
    };
    // TODO -> isMapped filter

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'competitionId' : 'competitionName';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    return api.useCompetitionsQuery({ queryOptions: options })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.competitions?.nodes);
        dispatch('setTotalRowsCount', response.data?.competitions?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadExtMappingCompetitions({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        feed: {
          equalTo: getters.mappingSelectedFeed,
        },
        sportMapping: {
          mappedEntity: {
            sportLabel: {
              equalTo: getters.mappingSelectedSportLabel,
            },
          },
        },
      },
    };

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'extId' : 'extRef';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    if (getters.mappingSelectedDisplay.length === 1) {
      assign(options.filter, {
        mappingType: includes(getters.mappingSelectedDisplay, 'mapped') ? { equalTo: 'EXACT_MATCH' } : { notEqualTo: 'EXACT_MATCH' },
      });
    }

    return api.useExtCompetitionsQuery({ queryOptions: options })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.allCompetitionMappings?.nodes);
        dispatch('setTotalRowsCount', response.data?.allCompetitionMappings?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadMappingPlayers({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
    };
    // TODO -> isMapped filter

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      if (searchOption === 'name') {
        options.condition = { nameSimilar: searchQuery };
      } else {
        let fieldName = 'playerId';

        if (searchOption === 'index') {
          fieldName = 'playerIndex';
        }
        const searchQueryValue = searchOption === 'index' ? Number(searchQuery) : searchQuery;
        const fieldCriteria = 'equalTo';
        options.filter = {
          ...options.filter,
          [fieldName]: {
            [fieldCriteria]: searchQueryValue,
          },
        };
      }
    }

    return api.usePlayersQuery({
      sportId: getters.mappingSelectedSportId,
      queryOptions: options,
    })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.players?.nodes);
        dispatch('setTotalRowsCount', response.data?.players?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  loadExtMappingPlayers({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        feed: {
          equalTo: getters.mappingSelectedFeed,
        },
      },
    };

    const searchQuery = getters.mappingSearchQuery || '';
    const searchOption = getters.mappingSelectedSearchOption;
    if (searchQuery) {
      const fieldName = searchOption === 'id' ? 'extId' : 'extRef';
      const fieldCriteria = searchOption === 'id' ? 'equalTo' : 'includesInsensitive';
      options.filter = {
        ...options.filter,
        [fieldName]: {
          [fieldCriteria]: searchQuery,
        },
      };
    }

    if (getters.mappingSelectedDisplay.length === 1) {
      assign(options.filter, {
        mappingType: includes(getters.mappingSelectedDisplay, 'mapped') ? { equalTo: 'EXACT_MATCH' } : { notEqualTo: 'EXACT_MATCH' },
      });
    }

    return api.useExtPlayersQuery({
      sportId: getters.mappingSelectedSportId,
      queryOptions: options,
    })
      .then((response) => {
        commit(types.SET_MAPPING_ENTRIES, response.data?.[`${getResponseObjectNameBySportId(getters.mappingSelectedSportId, 'Player')}`]?.nodes);
        dispatch('setTotalRowsCount', response.data?.[`${getResponseObjectNameBySportId(getters.mappingSelectedSportId, 'Player')}`]?.totalCount);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_MAPPING_ENTRIES, []);
        dispatch('setTotalRowsCount', 0);
      })
      .finally(() => {
        dispatch('validatePaginationPage');
      });
  },
  mappingLoading({ commit }, payload) {
    commit(types.SET_MAPPING_LOADING, payload);
  },
  clearMappingTable({ dispatch, commit }) {
    commit(types.SET_MAPPING_ENTRIES, []);
    commit(types.SAVE_MAPPING_EVENTS, []);
    commit(types.SAVE_MAPPING_COMPETITORS, []);
    dispatch('setTotalRowsCount', 0);
  },
  clearMappingTableEntries({ commit }) {
    commit(types.SET_MAPPING_ENTRIES, []);
  },
  changePasswordAttributes({ commit }, payload) {
    commit(types.UPDATE_CHANGE_PASSWORD_ATTRIBUTES, payload);
  },
  changePassword(_, payload) {
    return new Promise((resolve, reject) => {
      api.changePassword(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  confirmNewPassword(_, payload) {
    return new Promise((resolve, reject) => {
      api.confirmNewPassword(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendCodeToEmail(_, username) {
    return new Promise((resolve, reject) => {
      api.requestCode(username)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllFeeds({ commit }) {
    api.useAllFeedsQuery()
      .then((response) => {
        const allFeeds = response.data?.allFeeds?.nodes;
        const allAvailableFeeds = filter(allFeeds, (feed) => feed.available);
        commit(types.SAVE_ALL_FEEDS, allAvailableFeeds);
        socket.subscribeToFeedHealth();
      })
      .catch((err) => {
        console.error(err);
        commit(types.SAVE_ALL_FEEDS, []);
      });
  },
  updateAllFeeds({ commit, state, dispatch }, socketData) {
    const allFeeds = cloneDeep(state.allFeeds);
    const socketFeeds = socketData.feeds;
    let feedChanged = false;
    each(allFeeds, (feed) => {
      const updateFeed = find(socketFeeds, { feed: feed.feed });
      if (!updateFeed) return;
      if (feed.status !== updateFeed.status) {
        feedChanged = true;
        if (updateFeed.status === 'UP') {
          dispatch('addNotification', {
            message: `${updateFeed.feed} is up!`,
            type: 'success',
            duration: 5000,
          });
        }
        if (updateFeed.status === 'DOWN') {
          dispatch('addNotification', {
            message: `${updateFeed.feed} is down!`,
            type: 'error',
            duration: 5000,
          });
        }
        assign(feed, { status: updateFeed.status });
      }
      if (!feedChanged) return;
      commit(types.SAVE_ALL_FEEDS, allFeeds);
    });
  },
  addNotification({ state }, payloadNotification) {
    const randomUuid = randomUUID();
    assign(payloadNotification, { id: randomUuid });
    state.notifications.push(payloadNotification);
    const notificationTimeout = setTimeout(() => {
      state.notifications = filter(
        state.notifications, (notification) => notification.id !== randomUuid,
      );
      clearTimeout(notificationTimeout);
    }, payloadNotification.duration || 8000);
  },
  removeNotification({ state }, notificationId) {
    state.notifications = filter(
      state.notifications, (notification) => notification.id !== notificationId,
    );
  },
  saveMarketConfiguration(_, configuration) {
    return new Promise((resolve, reject) => {
      api.saveMarketConfiguration(configuration)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  saveBulkMarketConfiguration(_, configuration) {
    switch (configuration.type) {
    case 'risk':
      return new Promise((resolve, reject) => {
        api.updateMarketLimitConfigs(configuration)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
    case 'resulting':
      return new Promise((resolve, reject) => {
        api.updateMarketResultingConfigs(configuration)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
    case 'hold':
      return new Promise((resolve, reject) => {
        api.updateMarketMarginConfigs(configuration)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
    case 'line':
      return new Promise((resolve, reject) => {
        api.updateMarketTemplateConfigs(configuration)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
    default:
      return null;
    }
  },
  async toggleEventSuspend({
    state, commit, dispatch, getters,
  }, { eventId, isSuspended }) {
    const oldEvent = state.event ? cloneDeep(state.event) : null;
    const oldEventList = cloneDeep(state.eventList);
    try {
      commit(types.SET_SUSPENDING_EVENT_IDS, [...state.suspensionInProgressEventIds, ...[eventId]]);
      dispatch('setEventSuspendStatus', { eventId, isSuspended });
      dispatch('setEventsEventSuspendedStatus', { eventId, isSuspended });
      const action = isSuspended ? api.suspendAllMarkets : api.unsuspendAllMarkets;
      await action(eventId);

      // UPDATING SELECTED EVENTS
      const selectedEvents = cloneDeep(getters.selectedEvents);
      const selectedEventsToToggle = filter(selectedEvents, (e) => e.eventId === eventId);
      each(selectedEventsToToggle, (event) => {
        assign(event, { isSuspended });
      });
      commit(types.UPDATE_SELECTED_EVENTS, selectedEvents);
    } catch (error) {
      console.error(error);
      commit(types.UPDATE_EVENT, oldEvent);
      commit(types.UPDATE_EVENT_LIST, oldEventList);
    } finally {
      commit(types.SET_SUSPENDING_EVENT_IDS, filter(state.suspensionInProgressEventIds, (stateEventId) => stateEventId !== eventId));
    }
  },
  suspendMarketType({ commit }, payload) {
    commit(types.SET_SUSPEND_MARKET_TYPE_ACTION_LOADING, payload.marketType.marketCode);
    api.suspendMarketType(payload)
      .then()
      .catch((err) => { console.log(err); })
      .finally(() => {
        commit(types.SET_SUSPEND_MARKET_TYPE_ACTION_LOADING, false);
      });
  },
  unsuspendMarketType({ commit }, payload) {
    commit(types.SET_SUSPEND_MARKET_TYPE_ACTION_LOADING, payload.marketType.marketCode);
    api.unsuspendMarketType(payload)
      .then()
      .catch((err) => { console.log(err); })
      .finally(() => {
        commit(types.SET_SUSPEND_MARKET_TYPE_ACTION_LOADING, false);
      });
  },
  toggleBatchSuspend({ commit, getters, state }, { eventsIds, suspend }) {
    // commit(types.SET_SUSPENDING_EVENT_IDS, true);
    commit(types.SET_SUSPENDING_EVENT_IDS, [...state.suspensionInProgressEventIds, ...eventsIds]);
    const action = suspend ? api.batchSuspendAll : api.batchUnsuspendAll;
    action(eventsIds)
      .then(() => {
        // UPDATING EVENT LIST
        const events = cloneDeep(getters.eventList);
        const eventsToToggle = filter(events, (e) => includes(eventsIds, e.eventId));
        each(eventsToToggle, (event) => {
          assign(event, { isSuspended: suspend });
        });
        commit(types.UPDATE_EVENT_LIST, events);

        // UPDATING SELECTED EVENTS
        const selectedEvents = cloneDeep(getters.selectedEvents);
        const selectedEventsToToggle = filter(selectedEvents, (e) => includes(eventsIds, e.eventId));
        each(selectedEventsToToggle, (event) => {
          assign(event, { isSuspended: suspend });
        });
        commit(types.UPDATE_SELECTED_EVENTS, selectedEvents);

        // UPDATING SINGLE EVENT
        if (!getters.event) return;
        let eventSingle = cloneDeep(getters.event);
        const singleEventUpdated = !!find(eventsToToggle, (e) => e.eventId === eventSingle?.id);
        if (singleEventUpdated) {
          eventSingle = {
            ...eventSingle,
            isSuspended: suspend,
            alreadyMapped: true,
          };
          commit(types.UPDATE_EVENT, eventSingle);
        }
      })
      .catch((err) => { console.log(err); })
      .finally(() => {
        // commit(types.SET_SUSPENDING_EVENT_IDS, false);
        commit(types.SET_SUSPENDING_EVENT_IDS, filter(state.suspensionInProgressEventIds, (stateEventId) => !includes(eventsIds, stateEventId)));
      });
  },
  setEventListMappingEvent({ commit }, payload) {
    commit(types.SET_EVENT_LIST_MAPPING_EVENT, payload);
  },
  setEventIsMapped({ commit }, eventId) {
    commit(types.SET_EVENT_IS_MAPPED, eventId);
  },
  updateEventList({ commit }, eventList) {
    commit(types.UPDATE_EVENT_LIST, eventList);
  },
  unmapMapping(_, payload) {
    if (!payload.mappingType) return false;
    return new Promise((resolve, reject) => {
      api.unmapMapping(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  },

  setSidebarExpanded({ commit }, isExpanded) {
    commit(types.SET_SIDEBAR_EXPANDED, isExpanded);
  },
  setSidebarSelection({ commit }, selection) {
    commit(types.SET_SIDEBAR_SELECTION, selection);
  },
  async loadSidebar({ commit, getters, dispatch }) {
    try {
      commit(types.SET_SIDEBAR_LOADING, true);
      let sports = getters.sidebarSports;
      let regions = getters.sidebarRegions;
      let competitions = getters.sidebarCompetitions;

      const dateRangeFilter = find(getters.eventListAppliedFilters, { field: 'startsAt' });
      const dateRange = dateRangeFilter?.value ?? ['', ''];
      const options = {
        subscribed: router.currentRoute.value.name === 'competitions_subscription',
        sportId: getters.sidebarSportId,
        regionId: getters.sidebarRegionId,
        competitionId: getters.sidebarCompetitionId,
        search: getters.search,
        startDate: dateRange?.[0] ? formatAndZoneTime(dateRange[0], startOfDay) : null,
        endDate: dateRange?.[1] ? formatAndZoneTime(dateRange[1], endOfDay) : null,
        subscriptionFilter: getters.selectedCompetitionSubscriptionFilter,
        mappingFilter: getters.selectedCompetitionMappingFilter,
      };
      if (!options.subscribed && !options.startDate) {
        options.startDate = computeStartsAt();
      }
      if (!sports.length) {
        sports = await api.findAllSports();
      }
      if (options.sportId || router.currentRoute.value.name === 'competitions_subscription') {
        const allSports = map(filter(sports, (sport) => sport.sportId), (sport) => sport.sportId);
        const competitionsWithRegions = await api.getCompetitions(options, allSports, {
          first: getters.rowsPerPage,
          offset: (getters.currentPage - 1) * getters.rowsPerPage,
        });
        const [partitionedRegions, partitionedCompetitions] = partitionCompetitions({
          competitions: router.currentRoute.value.name === 'competitions_subscription' ? competitionsWithRegions.nodes : competitionsWithRegions,
          sportId: options.sportId,
        });
        regions = partitionedRegions;
        competitions = partitionedCompetitions;
        if (router.currentRoute.value.name === 'competitions_subscription') {
          dispatch('setTotalRowsCount', competitionsWithRegions?.totalCount || 0);
          dispatch('validatePaginationPage');
        }
      }

      commit(types.SET_SIDEBAR_SPORTS, sports);
      commit(types.SET_SIDEBAR_REGIONS, regions);
      commit(types.SET_SIDEBAR_COMPETITIONS, competitions);
    } catch (error) {
      console.error(error);
      commit(types.SET_SIDEBAR_SPORTS, []);
      commit(types.SET_SIDEBAR_REGIONS, []);
      commit(types.SET_SIDEBAR_COMPETITIONS, []);
    } finally {
      commit(types.SET_SIDEBAR_LOADING, false);
      // Load all market groups
      const queryOptions = {};
      if (getters.sidebarSportId) {
        queryOptions.filter = {
          sportId: {
            in: getters.sidebarSportId,
          },
        };
      }
      api.getAllMarketGroups(queryOptions)
        .then((responseData) => {
          commit(types.SET_MARKET_GROUPS, responseData?.marketGroups?.nodes || []);
        })
        .catch(() => {
          commit(types.SET_MARKET_GROUPS, []);
        });
    }
  },

  async loadEvents({ getters, commit, dispatch }) {
    if (router.currentRoute.value.name === 'competitions_subscription') return;
    try {
      dispatch('unsubscribeEventList');
      const selectedSportId = getters.sidebarSportId;
      const selectedRegionId = getters.sidebarRegionId;
      const selectedCompetitionId = getters.sidebarCompetitionId;
      const appliedSort = getters.eventListAppliedSort;

      let startDate = new Date();
      const currentDate = new Date();
      const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
      if (timezoneHoursOffset >= 0) {
        startDate = addHours(startDate, timezoneHoursOffset - 5);
      } else {
        startDate = subHours(startDate, (Math.abs(timezoneHoursOffset) + 5));
      }
      const options = {
        first: getters.rowsPerPage,
        offset: (getters.currentPage - 1) * getters.rowsPerPage,
        condition: {},
        filter: {
          sport: { limbo: { equalTo: false } },
        },
      };

      if (!find(getters.eventListAppliedFilters, { field: 'startsAt' })) {
        options.filter.startsAt = {
          greaterThanOrEqualTo: format(startDate, 'yyyy-LL-dd\'T\'HH:mm:ss'),
        };
      }

      if (selectedSportId) {
        options.condition.sportId = selectedSportId;
      }

      if (selectedRegionId) {
        options.condition.regionId = selectedRegionId === '-' ? null : selectedRegionId;
      }

      if (selectedCompetitionId) {
        options.condition.competitionId = selectedCompetitionId;
        delete options.condition.sportId;
        delete options.condition.regionId;
      }

      if (getters.search) {
        options.condition.eventNameSimilar = getters.search;
      }

      if (router.currentRoute.value.name === 'events_book_events') {
        options.filter.eventIsCoveredsByEventId = {
          some: {
            available: {
              equalTo: true,
            },
          },
        };
      }

      if (getters.eventListAppliedFilters.length) {
        options.filter = {
          ...reduce(
            getters.eventListAppliedFilters,
            (eventsFilter, eventListFilter) => {
              const eventListFilterValue = eventListFilter.value;

              /*
                * 'startsAt' filter overrides
                */
              if (eventListFilter.field === 'startsAt') {
                return {
                  ...eventsFilter,
                  [eventListFilter.field]: {
                    greaterThan: formatAndZoneTime(eventListFilterValue[0], startOfDay),
                    lessThan: formatAndZoneTime(eventListFilterValue[1], endOfDay),
                  },
                };
              }

              /*
                * 'price' filter overrides
                */
              if (eventListFilter.field === 'price') {
                const filterCopy = { ...eventsFilter };

                if (includes(eventListFilter.value, 'internal')) {
                  set(filterCopy, 'eventHasInternalPricesByEventId.some.hasInternalPrices.equalTo', true);
                } else if (includes(eventListFilter.value, 'none')) {
                  set(filterCopy, 'eventHasInternalPricesByEventId.every.hasInternalPrices.equalTo', false);
                }

                return filterCopy;
              }

              /*
                * 'approved' filter overrides
                */
              if (eventListFilter.field === 'approved') {
                const filterCopy = { ...eventsFilter };

                if (includes(eventListFilter.value, 'yes')) {
                  set(filterCopy, 'eventReadinessByEventIdExists', true);
                } else if (includes(eventListFilter.value, 'no')) {
                  set(filterCopy, 'eventReadinessByEventIdExists', false);
                }

                return filterCopy;
              }

              /*
                * 'mapped' filter overrides
                */
              if (eventListFilter.field === 'mapped') {
                const filterCopy = { ...eventsFilter };

                // If both "Mapped" and "Not mapped" are selected we look at this as none is selected
                // which will return all mapped values
                // if (includes(eventListFilter.value, 'yes') && includes(eventListFilter.value, 'no')) {
                //   return filterCopy;
                // }
                if (includes(eventListFilter.value, 'yes')) {
                  set(filterCopy, 'eventIsMappedsByEventId.some.isMapped.equalTo', true);
                }
                if (includes(eventListFilter.value, 'no')) {
                  set(filterCopy, 'eventIsMappedsByEventId.some.isMapped.equalTo', false);
                }

                return filterCopy;
              }

              // event status filter
              if (eventListFilter.field === 'matchState') {
                if (includes(eventListFilter.value, 'N_A')) {
                  return {
                    ...eventsFilter,
                    or: [
                      {
                        matchState: {
                          isNull: true,
                        },
                      },
                      {
                        matchState: {
                          in: filter(eventListFilter.value, (filterValue) => filterValue !== 'N_A'),
                        },
                      },
                    ],
                  };
                }
              }

              if (eventListFilter.field === 'booking') {
                const filterCopy = { ...eventsFilter };
                if (includes(eventListFilter.value, 'yes')) {
                  set(filterCopy, 'bookedEventsByEventIdExist', true);
                }
                if (includes(eventListFilter.value, 'no')) {
                  set(filterCopy, 'bookedEventsByEventIdExist', false);
                }

                return filterCopy;
              }

              if (eventListFilter.field === 'suspended') {
                const filterCopy = { ...eventsFilter };
                if (includes(eventListFilter.value, 'yes')) {
                  set(filterCopy, 'operatorEventSuspensionsByEventIdExist', true);
                }
                if (includes(eventListFilter.value, 'no')) {
                  set(filterCopy, 'operatorEventSuspensionsByEventIdExist', false);
                }

                return filterCopy;
              }
              return {
                ...eventsFilter,
                [eventListFilter.field]: {
                  [eventListFilter.modifier]: eventListFilterValue,
                },
              };
            },
            {},
          ),
          ...options.filter,
        };
      }

      if (appliedSort) {
        const sortFieldParts = filter(split(appliedSort.field, /([A-Z]?[^A-Z]*)/g), (value) => value);
        const sortField = toUpper(join(sortFieldParts, '_'));
        const orderField = `${sortField}_${appliedSort.order}`;
        options.orderBy = [orderField];
      }

      commit(types.UPDATE_EVENT_LIST_LOADING, true);
      commit(types.SET_PREVIOUS_EVENT_LIST_OPTIONS, options);
      commit(types.UPDATE_EVENT_LIST, []);

      const { nodes: events = [], totalCount = 0 } = await api.getEvents(options);
      commit(types.UPDATE_EVENT_LIST, map(events, (event) => ({
        ...event,
        hasExternalPrices: event.eventHasExternalPricesByEventId?.nodes?.[0]?.hasExternalPrices,
        hasInternalPrices: event.eventHasInternalPricesByEventId?.nodes?.[0]?.hasInternalPrices,
        hasDpsPrices: event.eventHasDpsPricesByEventId?.nodes?.[0]?.hasDpsPrices,
        isMapped: event.eventIsMappedsByEventId?.nodes?.[0]?.isMapped,
        booked: !!event.bookedEvents?.nodes?.length,
        sameGameParlaysBooked: !!event.bookedSameGameParlays?.nodes?.length,
        playerPropsBooked: !!event.bookedPlayerProps?.nodes?.length,
        microMarketsBooked: !!event.bookedMicroMarkets?.nodes?.length,
        isSuspended: !!event.operatorEventSuspensionsByEventId.nodes.length,
        isHighlighted: !!event.eventHighlightsByEventId?.nodes?.length,
        isApproved: !!event.eventReadiness?.isApproved,
      })));
      dispatch('setTotalRowsCount', totalCount);
      dispatch('validatePaginationPage');
      dispatch('subscribeEventList');
    } catch (error) {
      console.error(error);
    } finally {
      commit(types.UPDATE_EVENT_LIST_LOADING, false);
    }
  },
  setEventsEventSuspendedStatus({ state, commit }, { eventId, isSuspended }) {
    const updatedEvents = map(state.eventList, (event) => {
      if (event.eventId !== eventId) return event;
      return { ...event, isSuspended };
    });
    commit(types.UPDATE_EVENT_LIST, updatedEvents);
  },
  toggleEventHighlight({ getters, commit }, { eventId, isHighlighted }) {
    const events = cloneDeep(getters.eventList);

    let eventSingle = cloneDeep(getters.event);
    if (eventSingle) {
      eventSingle = {
        ...eventSingle,
        isHighlighted,
        alreadyMapped: true,
      };
    }

    const eventToHighlight = find(events, (e) => e.eventId === eventId);
    if (eventToHighlight) {
      eventToHighlight.isHighlighted = isHighlighted;
    }

    api.toggleEventHighlight({ eventId, isHighlighted })
      .then(() => {
        commit(types.UPDATE_EVENT_LIST, events);
        commit(types.UPDATE_EVENT, eventSingle);
      })
      .catch((err) => { console.log(err); });
  },

  openBookingModal({ commit }, { type, ids }) {
    commit(types.SET_BOOKING_MODAL_TYPE, type);
    commit(types.SET_BOOKING_MODAL_IDS, ids);
    commit(types.SET_BOOKING_MODAL_OPEN, true);
  },
  closeBookingModal({ commit }) {
    commit(types.SET_BOOKING_MODAL_OPEN, false);
    commit(types.SET_BOOKING_MODAL_TYPE, '');
    commit(types.SET_BOOKING_MODAL_IDS, []);
  },
  initializeMapping({ commit, dispatch }) {
    commit(types.SET_MAPPING_INITIALIZED, false);
    return Promise.all([
      dispatch('loadMappingSports'),
      dispatch('loadMappingFeeds'),
    ]).finally(() => {
      commit(types.SET_MAPPING_INITIALIZED, true);
    });
  },
  loadMappingSports({ commit, dispatch }) {
    dispatch('mappingLoading', true);
    return api.fetchEligibleSports()
      .then((response) => {
        commit(types.SET_MAPPING_SPORTS, response.data.sports.nodes);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_MAPPING_SPORTS, []);
      })
      .finally(() => {
        dispatch('mappingLoading', false);
      });
  },
  loadMappingFeeds({ commit, dispatch }) {
    dispatch('mappingLoading', true);
    return api.fetchFeeds()
      .then((response) => ({
        primaryFeeds: map(response?.data?.allPrimaryFeeds?.nodes ?? [], ({ feed }) => feed),
        secondaryFeeds: map(response?.data?.allSecondaryFeeds?.nodes ?? [], ({ feed }) => feed),
      }))
      .then(({ primaryFeeds, secondaryFeeds }) => {
        commit(types.SET_MAPPING_PRIMARY_FEEDS, primaryFeeds);
        commit(types.SET_MAPPING_SECONDARY_FEEDS, secondaryFeeds);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_MAPPING_PRIMARY_FEEDS, []);
        commit(types.SET_MAPPING_SECONDARY_FEEDS, []);
      })
      .finally(() => {
        dispatch('mappingLoading', false);
      });
  },
  selectMappingSport({ commit }, { sportId, sportLabel }) {
    commit(types.SET_MAPPING_SELECTED_SPORT, {
      id: sportId,
      label: sportLabel,
    });
  },
  selectMappingFeed({ commit }, feed) {
    commit(types.SET_MAPPING_SELECTED_FEED, feed);
  },
  toggleMappingDisplayState({ getters, commit }, displayState) {
    let newMappingDisplayState;

    if (includes(getters.mappingSelectedDisplay, displayState)) {
      newMappingDisplayState = filter(getters.mappingSelectedDisplay, (value) => value !== displayState);
    } else {
      newMappingDisplayState = [...getters.mappingSelectedDisplay, displayState];
    }

    commit(types.SET_MAPPING_DISPLAY_STATE, newMappingDisplayState);
  },
  setMappingDisplayState({ commit }, displayState) {
    commit(types.SET_MAPPING_DISPLAY_STATE, displayState);
  },
  selectMappingDate({ commit }, option) {
    commit(types.SET_MAPPING_SELECTED_DATE, option);
  },
  setMappingSearchOption({ commit }, searchOption) {
    commit(types.SET_MAPPING_SELECTED_SEARCH_OPTION, searchOption);
  },
  setMappingSearchQuery({ commit }, searchQuery) {
    commit(types.SET_MAPPING_SEARCH_QUERY, searchQuery);
  },
  resetMappingData({ commit }) {
    commit(types.SET_MAPPING_INITIALIZED, false);
    commit(types.SET_MAPPING_LOADING, false);
    commit(types.SET_MAPPING_ENTRIES, []);
    commit(types.SET_MAPPING_SELECTED_SPORT, '');
    commit(types.SET_MAPPING_SEARCH_QUERY, '');
    commit(types.SET_MAPPING_SELECTED_FEED, 'huddle');
    commit(types.SET_MAPPING_SELECTED_DATE, 7);
    commit(types.SET_MAPPING_DISPLAY_STATE, ['mapped', 'not-mapped']);
  },
  openSubscriptionModal({ commit }, { type, id }) {
    commit(types.SET_SUBSCRIPTION_MODAL_OPEN, true);
    commit(types.SET_SUBSCRIPTION_MODAL_TYPE, type);
    commit(types.SET_SUBSCRIPTION_MODAL_ID, id);
  },
  closeSubscriptionModal({ commit }) {
    commit(types.SET_SUBSCRIPTION_MODAL_OPEN, false);
    commit(types.SET_SUBSCRIPTION_MODAL_TYPE, '');
    commit(types.SET_SUBSCRIPTION_MODAL_ID, '');
  },
  fastForwardReplay({ dispatch }, { eventId }) {
    api.fastForwardReplay({ eventId })
      .then(() => {
        dispatch('addNotification', {
          message: 'Replay event was successfully fast-forwarded.',
          type: 'success',
          duration: 5000,
        });
      })
      .catch(() => {
        console.log('error');
        dispatch('addNotification', {
          message: 'Unable to fast-forward the replay event.',
          type: 'success',
          duration: 5000,
        });
      });
  },
  resultMarkets(_, { eventId, payload }) {
    return new Promise((resolve, reject) => {
      api.manuallyResultMarkets(eventId, payload)
        .then(() => {
          resolve();
        })
        .catch(() => { reject(); });
    });
  },
  loadManualResultingEvents({ getters, commit }, { timeValue, selectedSportId, searchValue }) {
    if (getters.manualResultingEvents?.length) {
      socket.unsubscribeFromEventResultingStatus(map(getters.manualResultingEvents, (e) => e.eventId));

      map(getters.manualResultingEvents, (event) => socket.unsubscribeFromEvents({
        ...eventHelper.mapEventIdAndSource(event),
        type: socketEventTypes.RESULTING,
      }));
    }

    let endDayOffset = timeValue;
    const currentTime = new Date();

    let startDate = startOfDay(addDays(currentTime, timeValue));
    if (timeValue === -7) endDayOffset = -1;
    let endDate = timeValue === 0 ? currentTime : endOfDay(addDays(currentTime, endDayOffset));
    const timezoneHoursOffset = startDate.getTimezoneOffset() / 60;
    if (timezoneHoursOffset >= 0) {
      startDate = addHours(startDate, (timezoneHoursOffset));
      endDate = addHours(endDate, timezoneHoursOffset);
    } else {
      startDate = subHours(startDate, (Math.abs(timezoneHoursOffset)));
      endDate = subHours(endDate, Math.abs(timezoneHoursOffset));
    }

    const options = {
      condition: {},
      filter: {
        sport: { limbo: { equalTo: false } },
        startsAt: {
          greaterThanOrEqualTo: format(startDate, 'yyyy-LL-dd\'T\'HH:mm:ss'),
          lessThanOrEqualTo: format(endDate, 'yyyy-LL-dd\'T\'HH:mm:ss'),
        },
      },
    };

    if (searchValue) options.condition.eventNameSimilar = searchValue;

    if (selectedSportId) {
      options.condition.sportId = selectedSportId;
    }

    api.useManualResultingEventsQuery(options)
      .then((res) => {
        const eventIds = map(res, (event) => event.eventId);
        socket.subscribeToEventResultingStatus(eventIds);
        commit(types.SET_MANUAL_RESULTING_EVENTS, res);
        map(res, (event) => socket.subscribeToEvents({
          ...eventHelper.mapEventIdAndSource(event),
          type: socketEventTypes.RESULTING,
        }));
      })
      .catch(() => {
        commit(types.SET_MANUAL_RESULTING_EVENTS, []);
      });
  },
  clearManualResultingEvents({ getters, commit }) {
    if (getters.manualResultingEvents?.length) {
      socket.unsubscribeFromEventResultingStatus(map(getters.manualResultingEvents, (e) => e.eventId));
    }
    commit(types.SET_MANUAL_RESULTING_EVENTS, []);
  },
  updateEventMarketsResultingStatus({ getters, commit }, payload) {
    const client = router.currentRoute.value.query.client || getters.operator;
    if (getters.manualResultingEvents?.length) {
      const updatedEvents = map(getters.manualResultingEvents, (event) => {
        if (event.eventId !== payload.eventId) return event;
        const updatedEvent = cloneDeep(event);

        const statusToUpdate = find(updatedEvent.eventMarketsResultingStatusesByEventId?.nodes, ({ operatorId }) => operatorId === client);
        if (statusToUpdate) {
          statusToUpdate.notResultedMarketCount = payload.resultingStatusCount.NOT_RESULTED;
        }

        return updatedEvent;
      });
      commit(types.SET_MANUAL_RESULTING_EVENTS, updatedEvents);
    }
  },
  setManualResultingEvent({ commit }, event) {
    commit(types.SET_MANUAL_RESULTING_EVENT, event);
    commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, {});
    commit(types.UPDATE_MANUAL_RESULTING_EVENT_MARKETS, {});
  },
  async loadManualResultingEvent({ getters, commit }, eventId) {
    if (!eventId) {
      commit(types.SET_MANUAL_RESULTING_EVENT, null);
      commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, {});
      commit(types.UPDATE_MANUAL_RESULTING_EVENT_MARKETS, {});
      commit(types.SET_MANUAL_RESULTING_EVENT_LOADING, false);
      commit(types.UPDATE_MANUAL_RESULTING_MARKETS_LOADING, false);
      return;
    }

    try {
      const { eventId: currentEventId = '' } = getters.manualResultingEvent || '';
      if (!currentEventId || currentEventId !== eventId) {
        commit(types.SET_MANUAL_RESULTING_EVENT_LOADING, true);
        const payload = await api.findEventById(eventId);
        commit(types.SET_MANUAL_RESULTING_EVENT, payload);
        commit(types.SET_MANUAL_RESULTING_EVENT_LOADING, false);
      }

      commit(types.UPDATE_MANUAL_RESULTING_MARKETS_LOADING, true);

      const { sport: { sportId = '' } = {} } = getters.manualResultingEvent || {};
      const { marketDisplayConfigurations } = await api.getMarketDisplayConfigurations({ sportId });
      const marketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
        ...markets,
        [`${market.marketCode}_${market.selections}`]: market,
      }), {});
      commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, marketsDisplayConfiguration);

      const eventMarkets = await api.useManualResultingEventMarketsQuery(eventId);
      const eventMarketsObject = eventMarkets.reduce((markets, market) => ({ ...markets, [market.marketId]: market }), {});
      commit(types.UPDATE_MANUAL_RESULTING_EVENT_MARKETS, eventMarketsObject);
    } catch (error) {
      console.error(error);
      commit(types.SET_MANUAL_RESULTING_EVENT, null);
      commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, {});
      commit(types.UPDATE_MANUAL_RESULTING_EVENT_MARKETS, {});
    } finally {
      commit(types.SET_MANUAL_RESULTING_EVENT_LOADING, false);
      commit(types.UPDATE_MANUAL_RESULTING_MARKETS_LOADING, false);
    }
  },
  reloadManualResultingMarkets({ getters, dispatch }) {
    const { eventId = '' } = getters.manualResultingEvent || {};
    dispatch('loadManualResultingEvent', eventId);
  },
  updateManualResultingEventMarkets({ commit }, markets) {
    commit(types.UPDATE_MANUAL_RESULTING_EVENT_MARKETS, markets);
  },
  subscribeToManualResultingMarkets(_, eventId) {
    socket.subscribeToManualResultingMarkets(eventId);
  },
  unsubscribeToManualResultingMarkets(_, eventId) {
    socket.unsubscribeToManualResultingMarkets(eventId);
  },

  // Player setup
  setIsPlayerSetupSubmitEnabled({ commit }, isEnabled) {
    commit(types.SET_IS_PLAYER_SETUP_SUBMIT_ENABLED, isEnabled);
  },
  setIsPlayerSetupSimulateEnabled({ commit }, isEnabled) {
    commit(types.SET_IS_PLAYER_SETUP_SIMULATE_ENABLED, isEnabled);
  },
  setIsPlayerSetupSimulateBySocketChangeEnabled({ commit }, isEnabled) {
    commit(types.SET_IS_PLAYER_SETUP_SIMULATE_BY_SOCKET_CHANGE_ENABLED, isEnabled);
  },
  setIsPlayerSetupSimulateBtnFrozen({ commit }, isFrozen) {
    commit(types.SET_IS_PLAYER_SETUP_PAGE_SIMULATE_BTN_FROZEN, isFrozen);
  },
  setIsTradingUISimulateBtnFrozen({ commit }, isFrozen) {
    commit(types.SET_IS_TRADING_UI_PAGE_SIMULATE_BTN_FROZEN, isFrozen);
  },
  setPlayerForMarketsPopupList({ commit }, players) {
    commit(types.SET_PLAYERS_FOR_MARKETS_POPUP_LIST, players);
  },
  setPlayerSetupHighlightedPlayer({ commit }, player) {
    commit(types.SET_PLAYER_SETUP_HIGHLIGHTED_PLAYER, player);
  },
  setIsPlayerSetupInferActive({ commit }, isActive) {
    commit(types.SET_PLAYER_SETUP_INFER_ACTIVE, isActive);
  },
  setPlayerParamsSelectedMode({ commit }, selectedMode) {
    commit(types.SET_PLAYER_PARAMS_SELECTED_MODE, selectedMode);
  },
  setGameParamsSelectedMode({ commit }, selectedMode) {
    commit(types.SET_GAME_PARAMS_SELECTED_MODE, selectedMode);
  },
  togglePlayerForMarketsPopupList({ dispatch, getters }, playerId) {
    let players = cloneDeep(getters.playersForMarketsPopupList);
    const foundPlayer = find(players, (player) => player === playerId);

    if (foundPlayer) {
      players = filter(players, (p) => p !== playerId);
    } else {
      players.push(playerId);
    }
    dispatch('setPlayerForMarketsPopupList', players);
  },
  toggleAllByTeamPlayersForMarketsPopupList({ getters, dispatch }, {
    teamLabel, isToggled, activeTab,
  }) {
    const selectedPlayers = cloneDeep(getters.playersForMarketsPopupList);
    const { sportId } = getters.playerSetupData;
    const playersToToggle = map(
      filter(cloneDeep(getters.playerSetupTableData[teamLabel]), (player) => {
        if (sportId === BASEBALL_ID) {
          return (activeTab === 'pitchers' && player.isPitcher) || (activeTab === 'hitters' && player.isHitter);
        }
        if (sportId === FOOTBALL_ID) {
          return (activeTab === 'quarterbacks' && player.isQuarterback) || (activeTab === 'non-quarterbacks' && player.isOffensiveNonQB) || (activeTab === 'kickers' && player.isKicker);
        }
        return true;
      }), (player) => player.playerId,
    );
    let updatedList = [];

    if (isToggled) {
      updatedList = filter(selectedPlayers, (player) => !includes(playersToToggle, player));
    } else {
      updatedList = [
        ...selectedPlayers,
        ...filter(playersToToggle, (player) => !includes(selectedPlayers, player)),
      ];
    }

    dispatch('setPlayerForMarketsPopupList', updatedList);
  },
  setPlayerSetupActionsState({ commit, dispatch, getters }) {
    const {
      playerSetupData, playerSetupTableData, playerSetupGameParams,
      playerSetupOriginalTableData,
    } = getters;
    const isBaseball = playerSetupData?.sportId === BASEBALL_ID;

    let playerParamsChanged = false;
    let playerParamsValid = false;
    if (playerSetupTableData) {
      const { isPlayerParamsChanged, isPlayerParamsValid } = checkIsPlayerSetupSubmitEnabled(
        {
          newData: playerSetupTableData,
          originalData: playerSetupOriginalTableData,
          sportId: playerSetupData.sportId,
        },
      );
      playerParamsChanged = isPlayerParamsChanged;
      playerParamsValid = isPlayerParamsValid;
    }
    const gameParamsDataValid = isBaseball ? !isBaseballGameParamsError(playerSetupGameParams) : !find(playerSetupGameParams, (param) => param.isError);
    const gameParamsChanged = (isBaseball ? isBaseballGameParamsChanged(playerSetupGameParams) : find(playerSetupGameParams, (param) => param.value !== param.originalValue));
    const dataChanged = (playerParamsChanged || gameParamsChanged) && playerParamsValid && gameParamsDataValid;
    dispatch('setIsPlayerSetupSubmitEnabled', dataChanged);
    dispatch('setIsPlayerSetupSimulateEnabled', dataChanged);
    commit(types.SET_IS_UNSAVED_CHANGES_ACTIVE, dataChanged);
  },
  setPlayerSetupTableData({ commit, dispatch }, data) {
    commit(types.SET_PLAYER_SETUP_TABLE_DATA, data);
    dispatch('setPlayerSetupActionsState');
  },
  triggerPricingPreview({ dispatch, getters }, { updatedRow, teamLabel }) {
    const { playerSetupData, playerSetupTableData } = getters;
    const requiredFields = requiredFieldsBySport(playerSetupData.sportId);
    const updatedRowValid = checkIfRequiredFieldsEnteredByRow(requiredFields, updatedRow);
    let hasValidRushPercentage = true;

    // Check rush percentage validity
    if (playerSetupData.sportId === FOOTBALL_ID) {
      hasValidRushPercentage = !!playerSetupTableData[`${teamLabel}RushPercentage`];
    }
    if (updatedRowValid && hasValidRushPercentage) {
      dispatch('getPriceOnDemand', true);
    }
  },
  setIsPlayerSetupScoreboardActive({ commit }, isActive) {
    commit(types.SET_IS_PLAYER_SETUP_SCOREBOARD_ACTIVE, isActive);
  },
  setIsPlayerSetupEventSearchActive({ commit }, isActive) {
    commit(types.SET_PLAYER_SETUP_EVENT_SEARCH_ACTIVE, isActive);
  },
  async loadPlayerSetupDetails({ getters, commit, dispatch }, id) {
    if (getters.playerSetupData || getters.playerSetupTableData) dispatch('unloadPlayerSetupDetails');
    let playerSetupData = {};
    commit(types.SET_IS_PLAYER_SETUP_DATA_LOADING, true);

    // Getting event data
    let event;
    try {
      event = await api.findEventByIdForPlayerSetup(id);
      if (event.sportId === BASEBALL_ID || event.sportId === BASKETBALL_ID) {
        socket.subscribeToPricingProbabilities(id);
      }
    } catch {
      commit(types.SET_IS_PLAYER_SETUP_DATA_LOADING, false);
      dispatch('addNotification', {
        message: 'Error while loading event data',
        type: 'error',
      });
      commit(types.SET_PLAYER_SETUP_ORIGINAL_TABLE_DATA, []);
      commit(types.SET_PLAYER_SETUP_TABLE_DATA, null);
      commit(types.SET_PLAYER_SETUP_DATA, null);
      return;
    }
    try {
      const sportNameById = getSportNameBySportId(event.sportId).toLowerCase();
      const allMarkets = await api.getGameAvailableMarkets({ sport: sportNameById });
      const initialOfferingStatusInfo = event?.marketsOfferingStatus?.nodes;
      commit(types.SET_PARAMS_MANAGER_MARKETS,
        getParamsManagerMarketsBySport(allMarkets.marketTypes, event.sportId, initialOfferingStatusInfo, event.isUsaView));
    } catch (e) {
      console.log(e);
      dispatch('addNotification', {
        message: 'Error while loading markets data',
        type: 'error',
      });
    }
    const sportNameById = getSportNameBySportId(event?.sportId).toLowerCase();
    socket.subscribeToEvents({
      ...eventHelper.mapEventIdAndSource(event),
      type: socketEventTypes.PLAYER_SETUP,
    });

    playerSetupData = mapPlayerSetupData(event);
    commit(types.SET_PLAYER_SETUP_DATA, playerSetupData);

    // Getting player params data
    let playerSetupDetails;
    try {
      playerSetupDetails = await api.getPlayerSetupDetails({ sport: sportNameById, eventId: id, mode: getters.playerParamsSelectedMode });
      commit(types.SET_IS_PLAYER_SETUP_PROJECTIONS_AVAILABLE, !!playerSetupDetails?.projectionsAvailable);
      commit(types.SET_PLAYER_SETUP_UNPUBLISHED_CHANGES, !!playerSetupDetails?.unpublishedChanges);
    } catch {
      commit(types.SET_IS_PLAYER_SETUP_DATA_LOADING, false);
      commit(types.SET_IS_PLAYER_SETUP_PROJECTIONS_AVAILABLE, false);
      commit(types.SET_PLAYER_SETUP_UNPUBLISHED_CHANGES, false);
      dispatch('addNotification', {
        message: 'Error while loading player params data',
        type: 'error',
      });
      commit(types.SET_PLAYER_SETUP_ORIGINAL_TABLE_DATA, []);
      commit(types.SET_PLAYER_SETUP_TABLE_DATA, null);
      return;
    }

    // Getting available markets
    dispatch('loadAvailableMarkets');

    // Getting team squad data
    const teamASquad = await api.getTeamSquad({
      teamId: playerSetupData.teamA.teamId,
      sportId: playerSetupData.sportId,
    });
    const teamBSquad = await api.getTeamSquad({
      teamId: playerSetupData.teamB.teamId,
      sportId: playerSetupData.sportId,
    });
    const playerSetupTableData = mapPlayerSetupTableDataResponse(
      playerSetupData,
      playerSetupDetails,
      {
        teamASquad: map(teamASquad.squad, (squad) => squad.player),
        teamBSquad: map(teamBSquad.squad, (squad) => squad.player),
      },
    );
    commit(types.SET_PLAYER_SETUP_ORIGINAL_TABLE_DATA, playerSetupTableData);
    commit(types.SET_PLAYER_SETUP_TABLE_DATA, playerSetupTableData);
    commit(types.SET_PLAYER_SETUP_MAPPED_PLAYERS_FOR_PROJECTIONS,
      [...(teamASquad?.playerMappedForProjections?.nodes || []), ...(teamBSquad?.playerMappedForProjections?.nodes || [])]);
    commit(types.SET_PARAMS_MANAGER_SQUAD_DATA, mapSquadData(teamASquad, teamBSquad));
    socket.subscribeToPlayerProps(id);

    // Currently we only show game params for Hockey, Basketball, Football and Baseball
    if (includes([HOCKEY_ID, BASKETBALL_ID, FOOTBALL_ID, BASEBALL_ID], playerSetupData.sportId)) {
      commit(types.SET_IS_PLAYER_SETUP_DATA_LOADING, true);
      await dispatch('getGameParams', {
        sportId: playerSetupData.sportId,
        eventId: playerSetupData.eventId,
        mode: getters.gameParamsSelectedMode,
      });

      if (playerSetupData.sportId === BASEBALL_ID) {
        const allBaseballParks = await api.loadAllBaseballParks();
        commit(types.SET_ALL_BASEBALL_PARKS, allBaseballParks.data?.allBaseballParks?.parks);
      }
      // Game params subscription
      socket.subscribeToGameParams(id);
      commit(types.SET_IS_PLAYER_SETUP_DATA_LOADING, false);
      dispatch('getGameParamsPriceOnDemand');
      // Calling in case that game params are default or projected
      dispatch('setPlayerSetupActionsState');

      const selectedMarkets = await api.getGameSelectedMarkets({
        sport: sportNameById,
        eventId: id,
      });
      commit(types.SET_GAME_PARAMS_SELECTED_MARKETS, selectedMarkets);
    }
    commit(types.SET_IS_PLAYER_SETUP_DATA_LOADING, false);
    // Loading prices
    if (playerSetupTableData?.teamA?.length || playerSetupTableData?.teamB?.length) {
      dispatch('getPriceOnDemand');
    }
  },
  reloadPlayerSetupDetails({ getters, dispatch }) {
    if (!getters.playerSetupData?.eventId) return;
    dispatch('loadPlayerSetupDetails', getters.playerSetupData.eventId);
  },
  async setGameParamsSelectedMarket({ getters, commit, dispatch }, selectedMarkets) {
    commit(types.SET_GAME_PARAMS_SELECTED_MARKETS_PROCESSING, true);
    const originalSelectedMarkets = selectedMarkets;
    try {
      commit(types.SET_GAME_PARAMS_SELECTED_MARKETS, selectedMarkets);
      await api.setGameSelectedMarkets({
        sport: getSportNameBySportId(getters.playerSetupData?.sportId || '').toLowerCase(),
        eventId: getters.playerSetupData?.eventId || '',
        fullTemplate: getters.gameParamsSelectedMarkets?.fullTemplate || false,
        marketTemplateIds: getters.gameParamsSelectedMarkets?.marketTemplateIds || [],
      });
      dispatch('addNotification', {
        message: 'Successfully updated selected markets!',
        type: 'success',
        duration: 5000,
      });
    } catch (e) {
      commit(types.SET_GAME_PARAMS_SELECTED_MARKETS, {
        ...originalSelectedMarkets,
        fullTemplate: true,
      });
      const errorMessages = e.messages || [];
      if (!errorMessages.length) {
        errorMessages.push('Unable to update selected markets. Please try again later!');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    } finally {
      commit(types.SET_GAME_PARAMS_SELECTED_MARKETS_PROCESSING, false);
    }
  },
  clearGameParamsSelectedMarkets({ commit }) {
    commit(types.SET_GAME_PARAMS_SELECTED_MARKETS, null);
    commit(types.SET_GAME_PARAMS_SELECTED_MARKETS_PROCESSING, false);
  },
  async updatePlayerProps({ getters, commit }, data) {
    if (getters.isPlayerSetupDataLoading) return;
    const { playerSetupTableData, playerSetupData } = getters;
    const updatedData = updatePlayerPropsData({
      ...playerSetupTableData,
      teamAId: playerSetupData?.teamA.teamId,
      teamBId: playerSetupData?.teamB.teamId,
    }, data, getters.paramsManagerSquadData);
    commit(types.SET_PLAYER_SETUP_TABLE_DATA, updatedData);
  },
  async simulateParamsSetup({ getters, dispatch }) {
    const {
      playerSetupTableData, playerSetupData,
      playerSetupOriginalTableData, playerSetupGameParams,
    } = getters;
    if (playerSetupTableData) {
      const { isPlayerParamsChanged, isPlayerParamsValid } = checkIsPlayerSetupSubmitEnabled(
        {
          newData: playerSetupTableData,
          originalData: playerSetupOriginalTableData,
          sportId: playerSetupData.sportId,
        },
      );
      if ((isPlayerParamsChanged && isPlayerParamsValid) || getters.isPlayerSetupSimulateBySocketChangeEnabled) {
        dispatch('getPriceOnDemand');
      }
    }
    const isBaseball = playerSetupData?.sportId === BASEBALL_ID;
    const gameParamsDataValid = isBaseball ? !isBaseballGameParamsError(playerSetupGameParams) : !find(playerSetupGameParams, (param) => param.isError);
    const gameParamsChanged = (isBaseball ? isBaseballGameParamsChanged(playerSetupGameParams) : find(playerSetupGameParams, (param) => param.value !== param.originalValue));

    // We add this check so that we can simulate game params for baseball withouth changing any of the game params before we submit the players
    const allPlayersUnsubmitted = !find(playerSetupTableData?.teamA, (player) => player.isLocked) && !find(playerSetupTableData?.teamB, (player) => player.isLocked);

    if ((gameParamsChanged && gameParamsDataValid)
      || getters.isPlayerSetupSimulateBySocketChangeEnabled
      || (allPlayersUnsubmitted && isBaseball)) {
      dispatch('getGameParamsPriceOnDemand');
    }
  },
  async getPriceOnDemand({ getters, dispatch, commit }, isPricingPreview = false) {
    const { playerSetupTableData, playerSetupData } = getters;
    const payload = mapPriceOnDemandPayload(playerSetupData, playerSetupTableData, isPricingPreview);
    const sportNameById = getSportNameBySportId(playerSetupData.sportId).toLowerCase();
    commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, true);
    try {
      const response = await api.getPriceOnDemand({
        eventId: playerSetupData.eventId,
        sport: sportNameById,
        data: payload,
      });
      if (!response) return;

      // For the case where the request from previous event is loaded after we open new one
      if (router.currentRoute?.value?.params?.eventId !== playerSetupData.eventId) return;

      const marketsByPlayerIndex = mapMarketsByPlayerIndex(response.data.playerMarkets);
      const playerSetupTableDataWithMarketPrices = mergePlayerDataWithMarketPrices(
        playerSetupTableData,
        marketsByPlayerIndex,
      );
      commit(types.SET_PLAYER_SETUP_TABLE_DATA, playerSetupTableDataWithMarketPrices);
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
      commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
      dispatch('setIsPlayerSetupSimulateEnabled', false);
      dispatch('setIsPlayerSetupSimulateBySocketChangeEnabled', false);
      socket.subscribeToPricingOnDemand(playerSetupData.eventId);
      dispatch('addNotification', {
        message: 'Prices have been successfully updated!',
        type: 'success',
        duration: 2000,
      });
    } catch (e) {
      let errorMessages = e.messages || [];
      if (e?.statusCode === 503) {
        const numberOfAttempts = getters.numberOfSimulateAttempts;
        if (numberOfAttempts < 5) {
          commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, numberOfAttempts + 1);
          dispatch('getPriceOnDemand');
          return;
        }
        errorMessages = ['Unable to get prices, try again in 10 seconds'];
        commit(types.SET_IS_PLAYER_SETUP_PAGE_SIMULATE_BTN_FROZEN, true);
      }
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
      commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
      dispatch('setIsPlayerSetupSimulateEnabled', true);

      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading prices');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  async getGameParamsPriceOnDemand({ getters, dispatch, commit }) {
    const {
      playerSetupData, playerSetupGameParams, paramsManagerMarkets, playerSetupTableData,
    } = getters;
    const sportNameById = getSportNameBySportId(playerSetupData?.sportId).toLowerCase();
    let params = {};
    // We are unable to fetch game params prices if there is no added players
    // This rule only applies for Baseball
    if (playerSetupData?.sportId === BASEBALL_ID
      && (
        !playerSetupTableData?.teamA?.length
        && !playerSetupTableData?.teamB?.length
      )) {
      return;
    }
    if (playerSetupData?.sportId === BASEBALL_ID) {
      params = {
        ...mapPlayerSetupBaseballGameParams(playerSetupGameParams),
      };
    } else {
      params = mapPlayerSetupGameParamsSubmitPayload(playerSetupGameParams);
    }
    let payload = {
      gameParams: {
        eventId: playerSetupData?.eventId,
        sport: sportNameById,
        ...params,
      },
      marketTypes: map(paramsManagerMarkets, (market) => ({
        marketCode: market.marketCode,
        sport: getSportLabelBySportId(playerSetupData.sportId),
        params: market.params || market.marketType?.params,
      })),
    };
    // Adding additional param for basketball which is determined based on the competition type
    // Sport for basketball is determined based on the competition type
    if (playerSetupData.sportId === BASKETBALL_ID) {
      payload.gameParams.modelType = getBasketballGlobalParamsModelType(playerSetupData.competitionType);
      payload.gameParams.sport = getBasketballGlobalParamsSportTypeByCompetition(playerSetupData.competitionType);
    }
    // For Baseball we need to send player params together with game params
    // In order to get prices for game markets
    if (playerSetupData?.sportId === BASEBALL_ID) {
      const playerParams = mapPriceOnDemandPayload(playerSetupData, playerSetupTableData, false);
      payload = {
        gameOnDemandPricingParams: {
          ...payload,
        },
        playersOnDemandPricingParams: {
          ...playerParams,
        },
      };
    }
    commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, true);
    try {
      const response = await api.getGameParamsPriceOnDemand({
        eventId: playerSetupData.eventId,
        sport: sportNameById,
        data: payload,
        withPlayers: playerSetupData.sportId === BASEBALL_ID,
      });
      if (!response) return;
      // For the case where the request from previous event is loaded after we open new one
      if (router.currentRoute?.value?.params?.eventId !== playerSetupData.eventId) return;
      commit(types.SET_PARAMS_MANAGER_MARKETS, map(paramsManagerMarkets, (market) => {
        const foundMarket = find(response?.data?.markets, (updatedMarket) => (
          updatedMarket?.marketType?.marketCode === market.marketCode
          && updatedMarket?.marketType?.params?.TEAM === market?.params?.TEAM
          && updatedMarket?.marketType?.params?.PERIOD === market?.params?.PERIOD
          && updatedMarket?.marketType?.params?.HALF === market?.params?.HALF
          && updatedMarket?.marketType?.params?.RESULT_AFTER_X === market?.params?.RESULT_AFTER_X
        ));
        if (!foundMarket) return market;
        return {
          ...market,
          ...foundMarket,
        };
      }));
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
      commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
      dispatch('setIsPlayerSetupSimulateEnabled', false);
      dispatch('addNotification', {
        message: 'Prices have been successfully updated!',
        type: 'success',
        duration: 2000,
      });
    } catch (e) {
      let errorMessages = e.messages || [];
      if (e?.statusCode === 503) {
        const numberOfAttempts = getters.numberOfSimulateAttempts;
        if (numberOfAttempts < 5) {
          commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, numberOfAttempts + 1);
          dispatch('getGameParamsPriceOnDemand');
          return;
        }
        errorMessages = ['Unable to get prices, try again in 10 seconds'];
        commit(types.SET_IS_PLAYER_SETUP_PAGE_SIMULATE_BTN_FROZEN, true);
      }
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
      commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
      dispatch('setIsPlayerSetupSimulateEnabled', true);

      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading prices');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  unloadPlayerSetupDetails({ getters, commit, dispatch }) {
    commit(types.SET_PLAYER_SETUP_TABLE_DATA, null);
    commit(types.SET_IS_PLAYER_SETUP_SUBMIT_ENABLED, false);
    commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
    commit(types.SET_IS_PLAYER_SETUP_PAGE_SIMULATE_BTN_FROZEN, false);
    commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
    commit(types.SET_IS_UNSAVED_CHANGES_ACTIVE, false);
    commit(types.SET_PLAYER_SETUP_BEFORE_INFER_STATE, null);
    commit(types.SET_PLAYER_SETUP_INFER_ACTIVE, false);
    dispatch('setIsPlayerSetupSimulateEnabled', false);
    dispatch('setIsPlayerSetupSimulateBySocketChangeEnabled', false);
    dispatch('setPlayerForMarketsPopupList', []);
    dispatch('setIsPlayerSetupScoreboardActive', false);
    commit(types.SET_PLAYER_SETUP_GAME_PARAMS, null);
    commit(types.SET_IS_PLAYER_SETUP_PROJECTIONS_AVAILABLE, false);
    commit(types.SET_PARAMS_MANAGER_GAME_PROJECTIONS_AVAILABLE, false);
    if (!getters.playerSetupData) return;
    const { eventId, sportId } = getters.playerSetupData;
    if (eventId) {
      socket.unsubscribeFromEvents({
        ...eventHelper.mapEventIdAndSource(getters.playerSetupData),
        type: socketEventTypes.PLAYER_SETUP,
      });
      socket.unsubscribeFromPlayerProps(eventId);
      socket.unsubscribeFromPricingOnDemand(eventId);
      socket.unsubscribeFromGameParams(eventId);

      if (sportId === BASEBALL_ID || sportId === BASKETBALL_ID) {
        socket.unsubscribeFromPricingProbabilities(eventId);
      }
    }
    commit(types.SET_PLAYER_SETUP_DATA, null);
  },
  updatePlayerSetupDetails({ state, getters, commit }, payload) {
    if (!getters.playerSetupDataLoaded || state.playerSetupData.eventId !== payload.eventId) return;

    let matchState;

    if (state.playerSetupData.matchState !== 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'FIRST_PERIOD')
        || payload.state.period === 'FIRST_QUARTER' || payload.state.period === 'IN_FIRST_HALF') {
        matchState = 'LIVE';
      } else {
        matchState = state.playerSetupData.matchState;
      }
    } else if (state.playerSetupData.matchState === 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'POST_GAME')
        || payload.state.period === 'EVENT_COMPLETED') {
        matchState = 'FINISHED';
      } else {
        matchState = state.playerSetupData.matchState;
      }
    } else {
      matchState = state.playerSetupData.matchState;
    }

    const updatedPlayerSetupData = mapUpdatedPlayerSetupData(state.playerSetupData, payload);
    commit(types.SET_PLAYER_SETUP_DATA, {
      ...updatedPlayerSetupData,
      matchState,
    });
  },
  async loadSearchEventListForPlayerSetup({ commit }, searchValue = '') {
    let startDate = new Date();
    const currentDate = new Date();
    const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
    if (timezoneHoursOffset >= 0) {
      startDate = addHours(startDate, timezoneHoursOffset - 5);
    } else {
      startDate = subHours(startDate, (Math.abs(timezoneHoursOffset) + 5));
    }
    const options = {
      // TODO -> Check if we should implement infinite scroll
      first: 50,
      offset: 0,
      condition: {},
      filter: {
        startsAt: {
          greaterThanOrEqualTo: format(startDate, 'yyyy-LL-dd\'T\'HH:mm:ss'),
        },
      },
    };
    if (searchValue) options.condition.eventNameSimilar = searchValue;
    commit(types.SET_PLAYER_SETUP_EVENT_LIST_LOADING, true);
    const { nodes: events = [] } = await api.getEventsForPlayerSetupSearch(options);

    commit(types.SET_PLAYER_SETUP_EVENT_LIST_LOADING, false);
    commit(types.SET_PLAYER_SETUP_EVENT_LIST, events);
  },
  async loadTeamSquad({ commit, getters }, { teamId, sportId }) {
    commit(types.SET_TEAM_SQUAD_LIST_LOADING, true);
    const teamSquad = await api.getTeamSquad({ teamId, sportId });
    commit(types.SET_TEAM_SQUAD_LIST_LOADING, false);
    const squad = map(
      filter(teamSquad.squad, (data) => !!data.player.playerId),
      ({ player }) => ({
        playerId: player.playerId,
        playerIndex: player.playerIndex,
        playerName: player.personalInfo.name,
        position: player.position,
      }),
    );
    const playerSetupData = cloneDeep(getters.playerSetupData);
    const teamToAddSquadTo = playerSetupData.teamA.teamId === teamId ? playerSetupData.teamA : playerSetupData.teamB;
    teamToAddSquadTo.squad = squad;
    commit(types.SET_PLAYER_SETUP_DATA, playerSetupData);
  },
  async submitPlayerSetup({ getters, dispatch, commit }) {
    const {
      playerSetupTableData, playerSetupData,
      playerSetupOriginalTableData, playerSetupGameParams,
    } = getters;

    let isSubmitError = false;
    const isBaseball = playerSetupData?.sportId === BASEBALL_ID;
    if (playerSetupTableData) {
      const payload = mapPlayerSetupPayloadData(playerSetupData, playerSetupTableData, 'MANUAL');
      const sportNameById = getSportNameBySportId(playerSetupData.sportId).toLowerCase();
      const { isPlayerParamsChanged, isPlayerParamsValid } = checkIsPlayerSetupSubmitEnabled(
        {
          newData: playerSetupTableData,
          originalData: playerSetupOriginalTableData,
          sportId: playerSetupData.sportId,
        },
      );
      if (isPlayerParamsChanged && isPlayerParamsValid) {
        try {
          await api.setPlayerParams({
            eventId: playerSetupData.eventId,
            sport: sportNameById,
            data: payload,
          });
          commit(types.SET_PLAYER_SETUP_BEFORE_INFER_STATE, null);
          commit(types.SET_PARAMS_MANAGER_MARKETS_BEFORE_INFER_STATE, null);
          dispatch('addNotification', {
            message: 'Player params successfully edited',
            type: 'success',
          });
        } catch {
          isSubmitError = true;
          dispatch('addNotification', {
            message: 'Submitting has not been processed because of an error',
            type: 'error',
          });
        }
      }
    }

    const gameParamsDataValid = isBaseball ? !isBaseballGameParamsError(playerSetupGameParams) : !find(playerSetupGameParams, (param) => param.isError);
    const gameParamsChanged = (isBaseball ? isBaseballGameParamsChanged(playerSetupGameParams) : find(playerSetupGameParams, (param) => param.value !== param.originalValue));
    if (gameParamsChanged && gameParamsDataValid) {
      const sportNameById = getSportNameBySportId(playerSetupData.sportId).toLowerCase();
      const gameParamsPayload = isBaseball
        ? {
          ...mapPlayerSetupBaseballGameParams(playerSetupGameParams),
        } : {
          ...mapPlayerSetupGameParamsSubmitPayload(playerSetupGameParams, playerSetupData?.sportId),
        };
      const payload = {
        gameParams: {
          sport: sportNameById,
          ...gameParamsPayload,
        },
        selectedParamSource: getters.gameParamsSelectedMode || 'MANUAL',
      };
      // Adding additional param for basketball which is determined based on the competition type
      // Sport for basketball is determined based on the competition type
      if (playerSetupData.sportId === BASKETBALL_ID) {
        payload.gameParams.modelType = getBasketballGlobalParamsModelType(playerSetupData.competitionType);
        payload.gameParams.sport = getBasketballGlobalParamsSportTypeByCompetition(playerSetupData.competitionType);
      }
      try {
        await api.submitGameParams({
          sport: sportNameById,
          eventId: playerSetupData.eventId,
          data: payload,
        });
        dispatch('addNotification', {
          message: 'Game params successfully edited',
          type: 'success',
        });
      } catch {
        isSubmitError = true;
        dispatch('addNotification', {
          message: 'Unable to update game params',
          type: 'error',
        });
      }
    }

    if (!isSubmitError) {
      dispatch('loadPlayerSetupDetails', playerSetupData.eventId);
      dispatch('setIsPlayerSetupSubmitEnabled', false);
      commit(types.SET_IS_UNSAVED_CHANGES_ACTIVE, false);
    }
  },
  async loadAvailableMarkets({ commit, getters }) {
    const { playerSetupData } = getters;
    const sportNameById = getSportNameBySportId(playerSetupData?.sportId).toLowerCase();

    commit(types.SET_IS_MARKETS_LOADING, true);
    let availableMarkets = await api.getAvailableMarkets({ sport: sportNameById });
    const marketPlayerTypes = availableMarkets?.marketPlayerTypes;
    availableMarkets = map(availableMarkets?.marketCodes, (market) => ({
      marketCode: market,
      marketName: marketMapper.playersMarketName(market),
    }));
    commit(types.SET_IS_MARKETS_LOADING, false);
    commit(types.SET_AVAILABLE_MARKETS, marketMapper.sortPlayerMarkets(availableMarkets, playerSetupData?.sportId));
    commit(types.SET_AVAILABLE_MARKETS_PLAYER_TYPES, marketPlayerTypes);
  },
  async suspendPlayerMarkets({ getters, dispatch }, payload) {
    const { playerSetupData, multiviewDrawerEvent, tradingEvent } = getters;
    const { sportId, eventId } = playerSetupData || multiviewDrawerEvent || tradingEvent || {};
    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    try {
      await api.suspendPlayerMarkets({
        eventId,
        sport: sportNameById,
        data: {
          ...payload,
          marketCodes: map(payload.marketCodes, (market) => market.marketCode),
        },
      });
    } catch (err) {
      dispatch('addNotification', {
        message: 'Unable to suspend markets',
        type: 'error',
      });
    }
  },
  async toggleAllPlayersSuspend({ getters, commit }, { teamLabel, action }) {
    const { playerSetupData, multiviewDrawerEvent, tradingEvent } = getters;
    const { sportId, eventId } = playerSetupData || multiviewDrawerEvent || tradingEvent || {};
    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    const { playerSetupTableData } = getters;
    const playersToSuspend = filter(playerSetupTableData[teamLabel], (player) => {
      let isPlayerThatFitsCondition = player.marketCodeDetails.length;
      if (action === 'suspend') {
        isPlayerThatFitsCondition = isPlayerThatFitsCondition && !player.isSuspended;
      } else if (action === 'unsuspend') {
        isPlayerThatFitsCondition = isPlayerThatFitsCondition && player.isSuspended;
      }
      return isPlayerThatFitsCondition;
    });
    const apiAction = action === 'suspend' ? api.suspendPlayerMarkets : api.unsuspendPlayerMarkets;
    commit(types.SET_ALL_PLAYERS_SUSPEND_TOGGLE_ACTIVE, true);
    return Promise.all(map(playersToSuspend, (player) => {
      const payload = {
        playerId: player.playerId,
        marketCodes: map(player.marketCodeDetails, (market) => market.marketCode),
        teamLabel,
      };
      return apiAction({
        eventId,
        sport: sportNameById,
        data: payload,
      });
    })).finally(() => {
      commit(types.SET_ALL_PLAYERS_SUSPEND_TOGGLE_ACTIVE, false);
    });
  },
  async unsuspendPlayerMarkets({ dispatch, getters }, payload) {
    const { playerSetupData, multiviewDrawerEvent, tradingEvent } = getters;
    const { sportId, eventId } = playerSetupData || multiviewDrawerEvent || tradingEvent || {};

    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    try {
      await api.unsuspendPlayerMarkets({
        eventId,
        sport: sportNameById,
        data: {
          ...payload,
          marketCodes: map(payload.marketCodes, (market) => market.marketCode),
        },
      });
    } catch (err) {
      dispatch('addNotification', {
        message: 'Unable to unsuspend markets',
        type: 'error',
      });
    }
  },
  removePlayer({ commit, getters, dispatch }, { playerData, teamLabel, activeTab }) {
    const { playerSetupTableData, playerSetupData } = getters;

    const updatedData = cloneDeep(playerSetupTableData);
    const playerUpdated = cloneDeep(playerData);
    let deletePlayer = true;
    if (playerSetupData.sportId === BASEBALL_ID) {
      if (activeTab === 'pitchers' && playerData.isHitter) {
        playerUpdated.isPitcher = false;
        deletePlayer = false;
      } else if (activeTab === 'hitters' && playerData.isPitcher) {
        playerUpdated.isHitter = false;
        deletePlayer = false;
      }
    }
    if (deletePlayer) {
      updatedData[`${teamLabel}`] = filter(updatedData?.[`${teamLabel}`], (player) => player.playerId !== playerData.playerId);
    } else {
      const playerIndex = findIndex(updatedData[`${teamLabel}`], (player) => player.playerId === playerData.playerId);
      updatedData[`${teamLabel}`][playerIndex] = playerUpdated;
    }
    commit(types.SET_PLAYER_SETUP_TABLE_DATA, updatedData);
    const { isPlayerParamsChanged, isPlayerParamsValid } = checkIsPlayerSetupSubmitEnabled(
      {
        newData: updatedData,
        originalData: getters.playerSetupOriginalTableData,
        sportId: getters.playerSetupData.sportId,
      },
    );

    const dataChanged = isPlayerParamsChanged && isPlayerParamsValid;

    const isUnsavedChangesActive = !isEqual(updatedData, getters.playerSetupOriginalTableData);
    dispatch('setIsPlayerSetupSubmitEnabled', dataChanged);
    dispatch('setIsPlayerSetupSimulateEnabled', dataChanged);
    commit(types.SET_IS_UNSAVED_CHANGES_ACTIVE, isUnsavedChangesActive);
    // Check popup list selected players counter
    if (!find(updatedData?.[`${teamLabel}`], (player) => player.playerId === playerData.playerId)) {
      dispatch('setPlayerForMarketsPopupList',
        filter(getters.playersForMarketsPopupList, (playerId) => playerId !== playerData.playerId));
    }
  },
  batchUpdatePlayerMarkets({ getters, commit }, incomingMarkets) {
    const availablePlayerMarkets = map(getters.availableMarkets, (market) => market.marketCode);
    const playerMarketsToUpdate = filter(incomingMarkets, (market) => includes(availablePlayerMarkets, market?.marketType.marketCode));
    if (playerMarketsToUpdate.length) {
      const marketsByPlayerIndex = mapMarketsByPlayerIndex(playerMarketsToUpdate);
      const playerSetupTableDataWithMarketPrices = mergePlayerDataWithMarketPrices(
        getters.playerSetupTableData,
        marketsByPlayerIndex,
      );
      commit(types.SET_PLAYER_SETUP_TABLE_DATA, playerSetupTableDataWithMarketPrices);
    }
  },
  async fetchParamsManagerProjections({ getters, dispatch, commit }, { type }) {
    const { playerSetupData, playerSetupTableData } = getters;
    const sportNameById = getSportNameBySportId(playerSetupData.sportId).toLowerCase();
    const projectionAction = type === 'player' ? api.fetchPlayerProjections : api.fetchGameProjections;
    try {
      const { data } = await projectionAction({
        eventId: playerSetupData.eventId,
        sport: sportNameById,
      });
      if (type === 'game') {
        const gameParams = mapPlayerSetupGameParams(data?.gameParams || {}, playerSetupData.sportId, data.paramsAreDefaultOrProjected);
        commit(types.SET_PLAYER_SETUP_GAME_PARAMS, gameParams);
      } else if (type === 'player') {
        if (!playerSetupTableData.teamA.length && !playerSetupTableData.teamB.length) return;
        const playerSetupTableDataUpdated = cloneDeep(playerSetupTableData);
        playerSetupTableDataUpdated.teamA = map(playerSetupTableDataUpdated.teamA, (player) => {
          const updatedPlayer = omit(data.projectedPlayerParams[player.playerId]?.playerParams, ['sport']) || {};
          return {
            ...player,
            ...updatedPlayer,
          };
        });
        playerSetupTableDataUpdated.teamB = map(playerSetupTableDataUpdated.teamB, (player) => {
          const updatedPlayer = omit(data.projectedPlayerParams[player.playerId]?.playerParams, ['sport']) || {};
          return {
            ...player,
            ...updatedPlayer,
          };
        });
        commit(types.SET_PLAYER_SETUP_TABLE_DATA, playerSetupTableDataUpdated);
        commit(types.SET_PLAYER_SETUP_UNPUBLISHED_CHANGES, true);
        commit(types.SET_IS_PLAYER_SETUP_PROJECTIONS_AVAILABLE, true);
      }
      dispatch('setPlayerSetupActionsState');
      dispatch('addNotification', {
        message: type === 'player' ? 'Player projections success' : 'Game projections success',
        type: 'success',
      });
    } catch (e) {
      const errorMessages = e.messages || [];
      if (!errorMessages.length) {
        errorMessages.push('Error fetching player projections');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
      throw Error();
    }
  },
  async setLimitedOffer({ dispatch }, {
    eventId,
    sportId,
    isLimitedOffer,
  }) {
    return new Promise((resolve, reject) => {
      const sportNameById = getSportNameBySportId(sportId).toLowerCase();
      api.setLimitedOffer({
        eventId,
        sport: sportNameById,
        isLimitedOffer,
      }).then(() => {
        dispatch('addNotification', {
          message: 'Limited offer has been successfully updated',
          type: 'success',
        });
        resolve();
      }).catch((e) => {
        const errorMessages = e.messages || [];
        if (!errorMessages.length) {
          errorMessages.push('Unable to set limited offer');
        }
        forEach(errorMessages, (message) => {
          dispatch('addNotification', {
            message,
            type: 'error',
          });
        });
        reject();
      });
    });
  },
  updateParamsManagerSocketSubscription({ getters }, isInferActive) {
    const { playerSetupData } = getters;
    // Case where we go from infer inactive to infer active
    // In this case we should unsubscribe from player props and pricing socket updates
    if (!getters.playerSetupInferActive && isInferActive) {
      socket.unsubscribeFromPlayerProps(playerSetupData.eventId);
      socket.unsubscribeFromPricingOnDemand(playerSetupData.eventId);
      socket.unsubscribeFromGameParams(playerSetupData.eventId);
    } else if (getters.playerSetupInferActive && !isInferActive) {
      // Case where we revert the changes and want to reconect to socket updates
      socket.subscribeToPlayerProps(playerSetupData.eventId);
      socket.subscribeToPricingOnDemand(playerSetupData.eventId);
      socket.subscribeToGameParams(playerSetupData.eventId);
    }
  },
  async setInferActiveState({ getters, commit }, {
    playerParamsMarketsUpdatedData, gameParamsMarketsUpdatedData,
  }) {
    const {
      playerSetupBeforeInferState, paramsManagerMarketsBeforeInferState,
      playerSetupTableData, paramsManagerMarkets,
    } = getters;
    let playerMarketsChanged;
    let gameMarketsChanged;

    // Check player markets changed
    if (playerParamsMarketsUpdatedData) {
      playerMarketsChanged = !isEqual(playerParamsMarketsUpdatedData, playerSetupBeforeInferState);
    } else {
      playerMarketsChanged = !isEqual(playerSetupTableData, playerSetupBeforeInferState) && !!playerSetupBeforeInferState;
    }

    // Check game markets changed
    if (gameParamsMarketsUpdatedData) {
      gameMarketsChanged = !isEqual(gameParamsMarketsUpdatedData, paramsManagerMarketsBeforeInferState);
    } else {
      gameMarketsChanged = !isEqual(paramsManagerMarkets, paramsManagerMarketsBeforeInferState) && !!paramsManagerMarketsBeforeInferState;
    }

    // Check if there is an error in data
    const playerDataError = !!find(playerSetupTableData, (player) => find(player.marketCodeDetails, (market) => market.isError));
    const gameDataError = !!find(paramsManagerMarkets, (market) => market.isError);

    const isError = playerDataError || gameDataError;
    const isInferActive = !isError && (playerMarketsChanged || gameMarketsChanged);

    await commit(types.SET_PLAYER_SETUP_INFER_ACTIVE, isInferActive);
    commit(types.SET_IS_UNSAVED_CHANGES_ACTIVE, isInferActive);
  },
  async updatePlayerMarket({ commit, getters, dispatch }, updatedMarketData) {
    if (getters.isPlayerSetupDataLoading) return;
    const { teamLabel, playerId, updatedMarket } = updatedMarketData;
    const { playerSetupTableData, playerSetupInferActive, playerSetupBeforeInferState } = getters;
    const updatedData = cloneDeep(playerSetupTableData);
    const updatedPlayer = find(updatedData[teamLabel], { playerId });

    const updatedMarketIndex = indexOf(map(updatedPlayer.marketCodeDetails, (market) => market.marketCode), updatedMarket.marketCode);
    updatedPlayer.marketCodeDetails[updatedMarketIndex] = updatedMarket;

    if (isNil(playerSetupBeforeInferState)) {
      // This will be triggered when the first market is changed
      // So we need to save this state of markets in order to compare it to the changed ones
      commit(types.SET_PLAYER_SETUP_BEFORE_INFER_STATE, cloneDeep(playerSetupTableData));
    }
    // Check if infer is active
    await dispatch('setInferActiveState', {
      playerParamsMarketsUpdatedData: updatedData,
      gameParamsMarketsUpdatedData: null,
    });

    // Update socket subscription
    dispatch('updateParamsManagerSocketSubscription', playerSetupInferActive);

    // Update state
    commit(types.SET_PLAYER_SETUP_TABLE_DATA, updatedData);
  },
  async updateGameMarket({ commit, getters, dispatch }, updatedMarketData) {
    if (getters.isPlayerSetupDataLoading) return;
    const { updatedMarket } = updatedMarketData;
    const { paramsManagerMarkets, playerSetupInferActive, paramsManagerMarketsBeforeInferState } = getters;
    const updatedData = cloneDeep(paramsManagerMarkets);
    const updatedMarketIndex = indexOf(map(updatedData, (market) => market.marketId), updatedMarket.marketId);

    updatedData[updatedMarketIndex] = updatedMarket;

    if (isNil(paramsManagerMarketsBeforeInferState)) {
      // This will be triggered when the first market is changed
      // So we need to save this state of markets in order to compare it to the changed ones
      commit(types.SET_PARAMS_MANAGER_MARKETS_BEFORE_INFER_STATE, cloneDeep(paramsManagerMarkets));
    }

    // Set infer active state
    await dispatch('setInferActiveState', {
      playerParamsMarketsUpdatedData: null,
      gameParamsMarketsUpdatedData: updatedData,
    });

    // Update socket subscription
    dispatch('updateParamsManagerSocketSubscription', playerSetupInferActive);

    // Update state
    commit(types.SET_PARAMS_MANAGER_MARKETS, updatedData);
  },
  async inferParamsManagerMarkets({ getters, dispatch }) {
    const {
      playerSetupBeforeInferState, paramsManagerMarketsBeforeInferState,
      playerSetupTableData, paramsManagerMarkets,
    } = getters;

    const playerMarketsChanged = !isEqual(playerSetupTableData, playerSetupBeforeInferState) && !!playerSetupBeforeInferState;
    const playerDataError = !!find(playerSetupTableData, (player) => find(player.marketCodeDetails, (market) => market.isError));

    if (playerMarketsChanged && !playerDataError) await dispatch('updatePlayerPropsMarkets');

    const gameMarketsChanged = !isEqual(paramsManagerMarkets, paramsManagerMarketsBeforeInferState) && !!paramsManagerMarketsBeforeInferState;
    const gameDataError = !!find(paramsManagerMarkets, (market) => market.isError);

    if (gameMarketsChanged && !gameDataError) {
      // Doing this logic because updatePlayerPropsMarkets is async and we need to wait for it to finish.
      // And since it a recursive function we need to check if it's still active
      if (getters.isPlayerSetupPageFrozen) {
        const updateGamePropsTimeout = () => {
          if (!getters.isPlayerSetupPageFrozen) {
            dispatch('updateGamePropsMarkets');
            return;
          }
          setTimeout(updateGamePropsTimeout, 1000);
        };
        updateGamePropsTimeout();
      } else {
        dispatch('updateGamePropsMarkets');
      }
    }
  },
  async updatePlayerPropsMarkets({ getters, dispatch, commit }) {
    const { playerSetupTableData, playerSetupData, playerSetupBeforeInferState } = getters;
    const sportNameById = getSportNameBySportId(playerSetupData.sportId).toLowerCase();

    const changedData = extractUpdatedPlayerSetupInferData(playerSetupBeforeInferState, playerSetupTableData);
    const playerData = mapPlayerSetupPayloadData(playerSetupData, changedData, getters.playerParamsSelectedMode);
    const marketData = mapPlayerSetupInferMarketData(playerSetupData, changedData);
    const payload = {
      paramFindingMarkets: marketData,
      ...playerData,
    };
    let paramFindId;
    // Fetching player params find id
    try {
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, true);
      commit(types.SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE, 'Simulating parameters - please wait...');

      paramFindId = await api.fetchPlayerParamFindId({
        eventId: playerSetupData.eventId,
        sport: sportNameById,
        data: payload,
      });
      if (!paramFindId) throw Error();
      // Fetching player props on demand
      await dispatch('getPlayerPropsPropsOnDemand', {
        payload: {
          eventId: playerSetupData.eventId,
          sport: sportNameById,
          paramFindId,
        },
        requestNumberCounter: 1,
      });
    } catch (e) {
      const errorMessages = e.messages || [];
      // Handling error for player params find id
      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading paramFindId');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
      commit(types.SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE, '');
    }
  },
  async updateGamePropsMarkets({ getters, dispatch, commit }) {
    const {
      paramsManagerMarkets, playerSetupData,
      playerSetupGameParams,
    } = getters;
    const sportNameById = getSportNameBySportId(playerSetupData.sportId).toLowerCase();
    const gameParams = mapPlayerSetupGameParamsSubmitPayload(playerSetupGameParams);
    const payload = {
      paramFindingMarkets: paramsManagerMarkets,
      gameParams: {
        ...gameParams,
        eventId: playerSetupData.eventId,
        sport: sportNameById,
      },
    };
    let paramFindId;
    // Fetching player params find id
    try {
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, true);
      commit(types.SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE, 'Simulating parameters - please wait...');

      paramFindId = await api.fetchGameParamFindId({
        eventId: playerSetupData.eventId,
        sport: sportNameById,
        data: payload,
      });
      if (!paramFindId) throw Error();
      // Fetching player props on demand
      await dispatch('getGameParamsOnDemand', {
        payload: {
          eventId: playerSetupData.eventId,
          sport: sportNameById,
          paramFindId,
        },
        requestNumberCounter: 1,
      });
    } catch (e) {
      const errorMessages = e.messages || [];
      // Handling error for player params find id
      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading paramFindId');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
      commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
      commit(types.SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE, '');
    }
  },
  async getPlayerPropsPropsOnDemand({ dispatch, commit, getters }, { payload, requestNumberCounter }) {
    const { playerSetupData, playerSetupTableData } = getters;
    try {
      const playerPropsOnDemand = await api.fetchPlayerParamsOnDemand(payload);
      if (requestNumberCounter < 100 && playerPropsOnDemand.status === 202) {
        setTimeout(() => {
          dispatch('getPlayerPropsPropsOnDemand',
            {
              payload,
              requestNumberCounter: requestNumberCounter + 1,
            });
        }, 1000);
      } else {
        commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
        commit(types.SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE, '');

        if (playerPropsOnDemand.status === 202) {
          // After x number requests we still weren't able to get the response
          // so we cancel the request and inform the user
          dispatch('addNotification', {
            message: 'Cannot infer parameters from prices',
            type: 'error',
          });
          commit(types.SET_PLAYER_SETUP_INFER_ACTIVE, true);
        } else if (playerPropsOnDemand.status === 200) {
          // Request is sucessfull and we should update the data
          dispatch('addNotification', {
            message: 'Infer parameters from prices successfull',
            type: 'success',
          });
          commit(types.SET_PLAYER_SETUP_INFER_ACTIVE, false);

          // Socket reconnect
          socket.subscribeToPlayerProps(playerSetupData.eventId);
          socket.subscribeToPricingOnDemand(playerSetupData.eventId);
          socket.subscribeToGameParams(playerSetupData.eventId);

          const mergedData = mergePlayerParamsOnDemand(playerSetupData, playerSetupTableData, playerPropsOnDemand.data);
          dispatch('setPlayerSetupTableData', mergedData);
          dispatch('setIsPlayerSetupSimulateEnabled', false);
          dispatch('setIsPlayerSetupSubmitEnabled', true);
        }
      }
    } catch (e) {
      const errorMessages = e.messages || [];
      // Handling error for player params find id
      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading paramFindId');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  async getGameParamsOnDemand({ dispatch, commit, getters }, { payload, requestNumberCounter }) {
    const { playerSetupData } = getters;
    try {
      const gameParamsOnDemand = await api.fetchGameParamsOnDemand(payload);
      if (requestNumberCounter < 100 && gameParamsOnDemand.status === 202) {
        setTimeout(() => {
          dispatch('getGameParamsOnDemand',
            {
              payload,
              requestNumberCounter: requestNumberCounter + 1,
            });
        }, 1000);
      } else {
        commit(types.SET_IS_PLAYER_SETUP_PAGE_FROZEN, false);
        commit(types.SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE, '');

        if (gameParamsOnDemand.status === 202) {
          // After x number requests we still weren't able to get the response
          // so we cancel the request and inform the user
          dispatch('addNotification', {
            message: 'Cannot infer parameters from prices',
            type: 'error',
          });
          commit(types.SET_PLAYER_SETUP_INFER_ACTIVE, true);
        } else if (gameParamsOnDemand.status === 200) {
          // Request is sucessfull and we should update the data
          dispatch('addNotification', {
            message: 'Infer parameters from prices successfull',
            type: 'success',
          });
          commit(types.SET_PLAYER_SETUP_INFER_ACTIVE, false);

          // Socket reconnect
          socket.subscribeToPlayerProps(playerSetupData.eventId);
          socket.subscribeToPricingOnDemand(playerSetupData.eventId);
          socket.subscribeToGameParams(playerSetupData.eventId);

          const gameParams = mapPlayerSetupGameParams(gameParamsOnDemand.data?.gameParams || {}, playerSetupData?.sportId, true);
          commit(types.SET_PLAYER_SETUP_GAME_PARAMS, gameParams);
          dispatch('setIsPlayerSetupSimulateEnabled', false);
          dispatch('setIsPlayerSetupSubmitEnabled', true);
        }
      }
    } catch (e) {
      const errorMessages = e.messages || [];
      // Handling error for player params find id
      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading paramFindId');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  updatePlayerSetupGameParams({ getters, commit, dispatch }, updatedParam) {
    const { playerSetupGameParams } = getters;
    const updatedParams = cloneDeep(playerSetupGameParams);
    const paramIndex = findIndex(updatedParams, { key: updatedParam.key });
    updatedParams[paramIndex] = updatedParam;
    commit(types.SET_PLAYER_SETUP_GAME_PARAMS, updatedParams);
    dispatch('setPlayerSetupActionsState');
  },
  updatePlayerSetupBaseballGameParams({ getters, commit, dispatch }, updatedParam) {
    const { playerSetupGameParams } = getters;
    const updatedParams = cloneDeep(playerSetupGameParams);
    set(updatedParams, updatedParam.mappableKey, updatedParam);
    commit(types.SET_PLAYER_SETUP_GAME_PARAMS, updatedParams);
    dispatch('setPlayerSetupActionsState');
  },
  paramsSetupGameParamsCalcToManualSwitch({ getters, commit, dispatch }) {
    const { playerSetupGameParams, gameParamsLastSubmittedSelectedMode } = getters;
    if (gameParamsLastSubmittedSelectedMode === 'MANUAL') {
      dispatch('loadPlayerSetupDetails', getters.playerSetupData.eventId);
      return;
    }
    dispatch('setIsPlayerSetupSubmitEnabled', true);
    dispatch('setIsPlayerSetupSimulateEnabled', true);
    let updateGameParams = cloneDeep(playerSetupGameParams);
    updateGameParams = map(updateGameParams, (param) => ({
      ...param,
      originalValue: null,
    }));
    commit(types.SET_PLAYER_SETUP_GAME_PARAMS, updateGameParams);
  },
  async paramsSetupGameParamsManualToCalcSwitch({ getters, commit, dispatch }) {
    const { playerSetupData, gameParamsLastSubmittedSelectedMode } = getters;
    if (gameParamsLastSubmittedSelectedMode === 'CALCULATED') {
      dispatch('loadPlayerSetupDetails', playerSetupData.eventId);
      return;
    }
    try {
      const manualGameParams = await api.getGameParams({
        sport: getSportNameBySportId(playerSetupData?.sportId).toLowerCase(),
        eventId: playerSetupData?.eventId,
        mode: 'calculated',
      });
      const gameParams = mapPlayerSetupGameParams(manualGameParams?.gameParams || {}, playerSetupData?.sportId, true);
      dispatch('setIsPlayerSetupSubmitEnabled', true);
      commit(types.SET_PLAYER_SETUP_GAME_PARAMS, gameParams);

      dispatch('addNotification', {
        message: 'Game params are projected and not active.',
        type: 'success',
      });
    } catch {
      dispatch('setGameParamsSelectedMode', 'CALCULATED');
      dispatch('addNotification', {
        message: 'Unable to switch to calculated game params',
        type: 'error',
      });
      commit(types.SET_GAME_PARAMS_SELECTED_MODE, 'MANUAL');
    }
  },
  async getGameParams({ dispatch, commit, getters }, { sportId, eventId }) {
    const { gameParamsSelectedMode } = getters;
    try {
      const manualGameParams = await api.getGameParams({
        sport: getSportNameBySportId(sportId).toLowerCase(),
        eventId,
        mode: gameParamsSelectedMode || 'active',
      });
      const paramsDefaultOrProjected = manualGameParams?.paramsAreDefaultOrProjected && !gameParamsSelectedMode;
      const gameParams = mapPlayerSetupGameParams(manualGameParams?.gameParams || {}, sportId, paramsDefaultOrProjected);

      commit(types.SET_GAME_PARAMS_SELECTED_MODE, manualGameParams?.selectedParamSource);
      commit(types.SET_GAME_PARAMS_LAST_SUBMITTED_SELECTED_MODE, manualGameParams?.selectedParamSource);
      commit(types.SET_PLAYER_SETUP_GAME_PARAMS, gameParams);
      commit(types.SET_PARAMS_MANAGER_GAME_PROJECTIONS_AVAILABLE, manualGameParams?.projectionsAvailable);
    } catch (e) {
      commit(types.SET_PARAMS_MANAGER_GAME_PROJECTIONS_AVAILABLE, false);
      const errorMessages = e.messages || [];
      if (!errorMessages.length) {
        errorMessages.push('Error while loading game params.');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  socketUpdatePlayerSetupGameParams({ getters, commit, dispatch }, updatedGameParams) {
    /* const { playerSetupGameParams, playerSetupData } = getters; */
    const { playerSetupGameParams } = getters;
    let gameParams;
    if (updatedGameParams?.gameParams?.pitcherAdjustments) { // temporary check because of bug with getters playerSetupData empty so we dont have sportId
      gameParams = mapPlayerSetupGameParams(updatedGameParams?.gameParams || {}, BASEBALL_ID, false);
    } else {
      gameParams = mapPlayerSetupGameParamsSocketUpdate(playerSetupGameParams, updatedGameParams?.gameParams || {});
    }
    dispatch('setGameParamsSelectedMode', updatedGameParams?.paramSource);
    commit(types.SET_PLAYER_SETUP_GAME_PARAMS, gameParams);
  },
  async saveTeamLineupPreset({ getters, dispatch }, { playersData, teamData, lineupName }) {
    const { playerSetupData } = getters;
    const { sportId } = playerSetupData;

    const playerParams = mapPlayerSetupBaseballTeamLineupData(playersData[teamData.teamLabel]);
    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    const lineupData = {
      teamId: teamData.teamId,
      lineup: {
        name: lineupName,
        ...playerParams,
      },
    };
    try {
      await api.saveTeamLineupPreset({
        sport: sportNameById,
        data: lineupData,
      });
      dispatch('addNotification', {
        message: `'${lineupName}' preset is successfully saved`,
        type: 'success',
      });
    } catch {
      dispatch('addNotification', {
        message: 'Error while saving lineup',
        type: 'error',
      });
    }
  },
  async getTeamLineupPreset({ getters, dispatch, commit }, { teamId }) {
    const { playerSetupData } = getters;
    const { sportId } = playerSetupData;

    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    await api.getTeamLineupPreset({
      sport: sportNameById,
      teamId,
    }).then((response) => {
      const savedLineups = response?.lineups;
      commit(types.SET_PARAMS_MANAGER_LINEUP_PRESETS_BY_TEAM, {
        ...getters.lineupPresetsByTeam,
        ...{
          [teamId]: savedLineups,
        },
      });
    }).catch(() => {
      dispatch('addNotification', {
        message: 'Error while loading team lineup',
        type: 'error',
      });
    });
  },
  async deleteTeamLineupPreset({ getters, dispatch }, { lineupId }) {
    const { playerSetupData } = getters;
    const { sportId } = playerSetupData;
    const sportNameById = getSportNameBySportId(sportId).toLowerCase();

    try {
      await api.deleteTeamLineupPreset({
        sport: sportNameById,
        lineupId,
      });
      dispatch('addNotification', {
        message: 'Lineup preset successfully deleted',
        type: 'success',
      });
    } catch {
      dispatch('addNotification', {
        message: 'Error while deleting lineup preset',
        type: 'error',
      });
    }
  },
  async updateTeamLineupPreset({ getters, dispatch }, {
    playersData, teamData, lineupName, lineupId,
  }) {
    const { playerSetupData } = getters;
    const { sportId } = playerSetupData;

    const playerParams = mapPlayerSetupBaseballTeamLineupData(playersData[teamData.teamLabel]);
    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    const lineupData = {
      teamId: teamData.teamId,
      lineup: {
        name: lineupName,
        ...playerParams,
      },
    };
    try {
      await api.updateTeamLineupPreset({
        sport: sportNameById,
        data: lineupData,
        lineupId,
      });
      dispatch('addNotification', {
        message: `'${lineupName}' preset is successfully updated`,
        type: 'success',
      });
    } catch {
      dispatch('addNotification', {
        message: 'Error while updating lineup',
        type: 'error',
      });
    }
  },
  async setSelectedLineupPresetByTeam({ getters, commit }, { teamId, selectedLineupPreset }) {
    const { selectedLineupPresetByTeam } = getters;
    commit(types.SET_PARAMS_MANAGER_SELECTED_LINEUP_PRESET_BY_TEAM, {
      ...selectedLineupPresetByTeam,
      ...{
        [teamId]: selectedLineupPreset,
      },
    });
  },
  async toggleParamsSetupEventSuspend({ commit, getters, dispatch }, { eventId, isSuspended }) {
    const oldEvent = cloneDeep(getters.playerSetupData);
    try {
      commit(types.SET_PARAMS_MANAGER_SUSPEND_LOADING, true);
      dispatch('setParamsManagerEventSuspendStatus', {
        eventId,
        isSuspended,
      });
      const action = isSuspended ? api.suspendAllMarkets : api.unsuspendAllMarkets;
      await action(eventId);
    } catch (error) {
      console.error(error);
      commit(types.SET_PLAYER_SETUP_DATA, oldEvent);
    } finally {
      commit(types.SET_PARAMS_MANAGER_SUSPEND_LOADING, false);
    }
  },
  setParamsManagerEventSuspendStatus({ getters, commit }, { eventId, isSuspended }) {
    if (!getters.playerSetupData || eventId !== getters.playerSetupData.eventId) return;
    commit(types.SET_PLAYER_SETUP_DATA, {
      ...getters.playerSetupData,
      isSuspended,
    });
  },
  updateParamsManagerPricingProbablities({ getters, commit }, data) {
    const playerSetupData = cloneDeep(getters.playerSetupData);
    const teamAProbabilites = playerSetupData.teamA.probabilities || {};
    const teamBProbabilites = playerSetupData.teamB.probabilities || {};

    const { probabilities } = data;

    if (playerSetupData.sportId === BASEBALL_ID) {
      const teamANewProbabilites = playerSetupData.isUSAView ? probabilities.awayProbabilities : probabilities.homeProbabilities;
      const teamBNewProbabilites = playerSetupData.isUSAView ? probabilities.homeProbabilities : probabilities.awayProbabilities;
      teamAProbabilites.battersProbabilities = teamANewProbabilites.battersProbabilities;
      teamAProbabilites.pitchersProbabilities = teamANewProbabilites.pitchersProbabilities;

      teamBProbabilites.battersProbabilities = teamBNewProbabilites.battersProbabilities;
      teamBProbabilites.pitchersProbabilities = teamBNewProbabilites.pitchersProbabilities;
    } else if (playerSetupData.sportId === BASKETBALL_ID) {
      playerSetupData.probabilities = probabilities;
    }

    commit(types.SET_PLAYER_SETUP_DATA, playerSetupData);
  },
  // Trading UI
  async loadTradingEvent({ getters, commit, dispatch }, id) {
    if (!id) return;
    if (getters.tradingEvent || getters.eventMarkets) dispatch('unloadEvent', id);
    let event;

    // Getting event
    try {
      commit(types.SET_TRADING_EVENT_LOADING, true);
      event = await api.findTradingEventById(id);
      commit(types.SET_TRADING_EVENT, {
        ...event,
        isSuspended: !!event?.operatorEventSuspensionsByEventId?.nodes?.length,
      });
      const { marketDisplayConfigurations } = await api.getMarketDisplayConfigurations({ sportId: event?.sportId });
      const tradingEventMarkets = event.markets.nodes;
      const tradingEventMarketsObject = tradingEventMarkets.reduce((markets, market) => ({ ...markets, [market.marketId]: market }), {});
      const tradingEventMarketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
        ...markets,
        [`${market.marketCode}_${market.selections}`]: market,
      }), {});
      commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, tradingEventMarketsDisplayConfiguration);
      commit(types.SET_TRADING_EVENT_MARKETS, tradingEventMarketsObject);
      const eventSource = eventHelper.getEventDetailsSource(event);
      const eventStateFeeds = map(eventHelper.getAllEventDetails(event), (feedState) => feedState.source);
      commit(types.SET_TRADING_EVENT_FEEDS, eventStateFeeds);
      commit(types.SET_TRADING_EVENT_SELECTED_FEED, eventSource);
      socket.subscribeToEvents({
        ...eventHelper.mapEventIdAndSource(event, eventSource),
        type: socketEventTypes.TRADING,
      });
      socket.subscribeToPricingProbabilities(event.eventId);
    } catch {
      dispatch('addNotification', {
        message: 'Error while loading event data',
        type: 'error',
      });
      commit(types.SET_TRADING_EVENT, null);
      commit(types.SET_TRADING_EVENT_MARKETS, {});
      commit(types.SET_TRADING_EVENT_FEEDS, []);
      commit(types.SET_TRADING_EVENT_SELECTED_FEED, null);
    }
    // Getting market groups
    try {
      const queryOptions = {
        filter: {
          sportId: {
            in: event.sportId,
          },
        },
      };
      const marketGroupsResponse = await api.getAllMarketGroups(queryOptions);
      commit(types.SET_MARKET_GROUPS, marketGroupsResponse?.marketGroups?.nodes || []);
      commit(types.SET_MARKET_FAVORITES, marketGroupsResponse?.allMarketFavorites?.nodes || []);
    } catch {
      commit(types.SET_MARKET_GROUPS, []);
      commit(types.SET_MARKET_FAVORITES, []);
    }
    const allowedUserRoles = getAllowedRoles('playerSetup');
    const isAllowedRole = isAllowedUserRole(allowedUserRoles);
    if (isAllowedRole) {
      if (!includes([FOOTBALL_ID, BASKETBALL_ID, HOCKEY_ID, BASEBALL_ID], event?.sportId)) {
        return;
      }
      commit(types.SET_TRADING_GAME_PARAMS_LOADING, true);
      // Getting available markets by sport
      try {
        const sportNameById = getSportNameBySportId(event.sportId).toLowerCase();
        const allMarkets = await api.getGameAvailableMarkets({ sport: sportNameById });
        const initialOfferingStatusInfo = event?.marketsOfferingStatus?.nodes;
        commit(types.SET_PARAMS_MANAGER_MARKETS,
          getParamsManagerMarketsBySport(allMarkets.marketTypes, event.sportId, initialOfferingStatusInfo, event.isUsaView));
      } catch (e) {
        console.log('Error while loading markets data', e);
        dispatch('addNotification', {
          message: 'Error while loading markets data',
          type: 'error',
        });
      } finally {
        commit(types.SET_TRADING_EVENT_LOADING, false);
      }
      // Getting game params data
      try {
        await dispatch('getTradingUIGameParams', {
          sportId: event.sportId,
          eventId: event.eventId,
        });
        if (event.sportId === BASEBALL_ID) {
          const allBaseballParks = await api.loadAllBaseballParks();
          commit(types.SET_ALL_BASEBALL_PARKS, allBaseballParks.data?.allBaseballParks?.parks);
        }
        // Game params subscription
        socket.subscribeToGameParams(id);
        await dispatch('simulateTradingUIGameParams');
      } catch {
        dispatch('addNotification', {
          message: 'Error while loading game params data',
          type: 'error',
        });
      } finally {
        commit(types.SET_TRADING_GAME_PARAMS_LOADING, false);
      }
      // Getting player params data
      const sportNameById = getSportNameBySportId(event?.sportId).toLowerCase();
      commit(types.SET_TRADING_PLAYER_PROPS_LOADING, true);
      try {
        const playerPropsData = await api.getPlayerSetupDetails({ sport: sportNameById, eventId: id });
        // Getting team squad data
        const homeSquad = await api.getTeamSquad({
          teamId: find(event.competitors, { side: 'HOME' }).teamId,
          sportId: event.sportId,
        });
        const awaySquad = await api.getTeamSquad({
          teamId: find(event.competitors, { side: 'AWAY' }).teamId,
          sportId: event.sportId,
        });
        const data = mapPlayerProps(
          playerPropsData,
          { homeSquad, awaySquad },
          event,
          event.sportId,
        );
        commit(types.SET_ORIGINAL_TRADING_PLAYER_PROPS, data);
        commit(types.SET_LAST_SIMULATE_TRADING_PLAYER_PROPS, data);
        commit(types.SET_TRADING_PLAYER_PROPS, data);
        commit(types.SET_TRADING_SQUAD, mapSquadData(homeSquad, awaySquad));
        socket.subscribeToPlayerProps(id);
        dispatch('simulateMarketPrices');
      } catch {
        dispatch('addNotification', {
          message: 'Error while loading player params data',
          type: 'error',
        });
      } finally {
        commit(types.SET_TRADING_PLAYER_PROPS_LOADING, false);
      }
    } else {
      commit(types.SET_TRADING_EVENT_LOADING, false);
    }
  },
  async getTradingUIGameParams({ dispatch, commit, getters }, { sportId, eventId }) {
    try {
      const manualGameParams = await api.getGameParams({
        sport: getSportNameBySportId(sportId).toLowerCase(),
        eventId,
        mode: getters.gameParamsSelectedMode || 'active',
      });
      const gameParams = mapPlayerSetupGameParams(manualGameParams?.gameParams || {}, sportId);
      const tradingEvent = cloneDeep(getters.tradingEvent);
      commit(types.SET_TRADING_EVENT, {
        ...tradingEvent,
        params: {
          ...manualGameParams,
          gameParams,
        },
      });
    } catch (e) {
      const errorMessages = e.messages || [];
      if (!errorMessages.length) {
        errorMessages.push('Error while loading game params.');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  updateTradingUIGameParams({ getters, commit }, { updatedParam }) {
    const tradingEvent = cloneDeep(getters.tradingEvent);
    const updatedParams = tradingEvent.params.gameParams;
    tradingEvent.params.isSimulateEnabled = true;
    const paramIndex = findIndex(updatedParams, { key: updatedParam.key });
    updatedParams[paramIndex] = updatedParam;
    commit(types.SET_TRADING_EVENT, tradingEvent);
  },
  updateTradingUIBaseballGameParams({ getters, commit }, { updatedParam }) {
    const tradingEvent = cloneDeep(getters.tradingEvent);
    const updatedParams = tradingEvent.params.gameParams;
    tradingEvent.params.isSimulateEnabled = true;
    set(updatedParams, updatedParam.mappableKey, updatedParam);
    commit(types.SET_TRADING_EVENT, tradingEvent);
  },
  socketUpdateTradingUIGameParams({ getters, commit }, updatedGameParams) {
    const tradingEvent = cloneDeep(getters.tradingEvent);
    let gameParams;
    if (updatedGameParams?.eventId !== tradingEvent?.eventId) return;
    if (updatedGameParams?.gameParams?.pitcherAdjustments) { // temporary check because of bug with getters playerSetupData empty so we dont have sportId
      gameParams = mapPlayerSetupGameParams(updatedGameParams?.gameParams || {}, BASEBALL_ID, false);
    } else {
      gameParams = mapPlayerSetupGameParamsSocketUpdate(tradingEvent?.params?.gameParams, updatedGameParams?.gameParams || {});
    }
    commit(types.SET_TRADING_EVENT, {
      ...tradingEvent,
      params: {
        ...tradingEvent.params,
        gameParams,
        selectedParamSource: updatedGameParams?.paramSource,
        selectedMarkets: {
          ...tradingEvent.params.selectedMarkets,
          limitedOffer: updatedGameParams?.limitedOffer,
        },
      },
    });
  },
  reloadTradingEvent({ getters, dispatch }) {
    const { eventId = '' } = getters.tradingEvent ?? {};
    if (!eventId) return;
    dispatch('loadTradingEvent', eventId);
  },
  async updateTradingUIPlayerProps({ getters, commit }, data) {
    if (getters.tradingPlayerPropsLoading) return;
    const { tradingPlayerProps } = getters;
    const updatedData = updatePlayerPropsDataTradingUI(tradingPlayerProps, data, getters.tradingSquad);
    commit(types.SET_ORIGINAL_TRADING_PLAYER_PROPS, updatedData);
    commit(types.SET_LAST_SIMULATE_TRADING_PLAYER_PROPS, updatedData);
    commit(types.SET_TRADING_PLAYER_PROPS, updatedData);
  },
  setTradingEvent({ commit }, tradingEvent) {
    commit(types.SET_TRADING_EVENT, tradingEvent);
  },
  updateTradingPlayerProps({ commit, getters }, data) {
    commit(types.SET_TRADING_PLAYER_PROPS, data);

    // Check is simulate button enabled
    commit(types.SET_IS_SIMULATE_BTN_ENABLED,
      isTradingPlayerPropsStateChanged(
        getters.lastSimulateTradingPlayerProps,
        data,
      ) && checkIsTradingPropsModelValid(data, getters.tradingEvent.sportId));
    // Check is publish button enabled
    commit(types.SET_IS_PUBLISH_BTN_ENABLED,
      isTradingPlayerPropsStateChanged(
        getters.originalTradingPlayerProps,
        data,
      ) && checkIsTradingPropsModelValid(data, getters.tradingEvent.sportId));
  },
  setIsTradingEventSearchActive({ commit }, isActive) {
    commit(types.SET_TRADING_EVENT_SEARCH_ACTIVE, isActive);
  },
  async loadSearchEventListForTrading({ commit }, searchValue = '') {
    let startDate = new Date();
    const currentDate = new Date();
    const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
    if (timezoneHoursOffset >= 0) {
      startDate = addHours(startDate, timezoneHoursOffset - 5);
    } else {
      startDate = subHours(startDate, (Math.abs(timezoneHoursOffset) + 5));
    }
    const options = {
      first: 50,
      offset: 0,
      condition: {},
      filter: {
        startsAt: {
          greaterThanOrEqualTo: format(startDate, 'yyyy-LL-dd\'T\'HH:mm:ss'),
        },
      },
    };
    if (searchValue) options.condition.eventNameSimilar = searchValue;
    commit(types.SET_TRADING_EVENT_LIST_LOADING, true);
    const { nodes: events = [] } = await api.getEventsForPlayerSetupSearch(options);

    commit(types.SET_TRADING_EVENT_LIST_LOADING, false);
    commit(types.SET_TRADING_EVENT_LIST, events);
  },
  clearTradingUI({ commit, state, getters }) {
    if (state.tradingEvent?.eventId) {
      socket.unsubscribeFromEvents({
        ...eventHelper.mapEventIdAndSource(state.tradingEvent, getters.tradingEventSelectedFeed),
        type: socketEventTypes.TRADING,
      });
      socket.unsubscribeToTradingUiMarkets(state.tradingEvent.eventId);
      socket.unsubscribeFromPlayerProps(state.tradingEvent.eventId);
      socket.unsubscribeFromPricingOnDemand(state.tradingEvent.eventId);
      socket.unsubscribeFromPricingProbabilities(state.tradingEvent.eventId);
      socket.unsubscribeFromGameParams(state.tradingEvent.eventId);
    }

    commit(types.SET_TRADING_EVENT, null);
    commit(types.SET_TRADING_EVENT_MARKETS, {});
    commit(types.SET_TRADING_PLAYER_PROPS, []);
    commit(types.SET_TRADING_SQUAD, []);
    commit(types.SET_TRADING_EVENT_FEEDS, []);
    commit(types.SET_TRADING_EVENT_SELECTED_FEED, null);
    commit(types.SET_IS_SIMULATE_BTN_ENABLED, false);
    commit(types.SET_IS_PUBLISH_BTN_ENABLED, false);
    commit(types.SET_MARKET_FAVORITES, []);
  },
  displayMarket(_, payload) {
    api.displayMarket(payload)
      .then(() => {
      })
      .catch((err) => { console.log(err); });
  },
  undisplayMarket(_, payload) {
    api.undisplayMarket(payload)
      .then(() => {
      })
      .catch((err) => { console.log(err); });
  },
  subscribeToTradingUiMarkets(_, eventId) {
    socket.subscribeToTradingUiMarkets(eventId);
  },
  unsubscribeToTradingUiMarkets(_, eventId) {
    socket.unsubscribeToTradingUiMarkets(eventId);
  },
  async toggleTradingUIEventSuspend({ commit, getters, dispatch }, { eventId, isSuspended }) {
    const oldTradingEvent = cloneDeep(getters.tradingEvent);
    try {
      commit(types.SET_TRADING_EVENT_SUSPEND_LOADING, true);
      dispatch('setTradingUIEventSuspendStatus', {
        eventId,
        isSuspended,
      });
      const action = isSuspended ? api.suspendAllMarkets : api.unsuspendAllMarkets;
      await action(eventId);
    } catch (error) {
      console.error(error);
      commit(types.SET_TRADING_EVENT, oldTradingEvent);
    } finally {
      commit(types.SET_TRADING_EVENT_SUSPEND_LOADING, false);
    }
  },
  setTradingUIEventSuspendStatus({ getters, commit }, { eventId, isSuspended }) {
    if (!getters.tradingEvent || eventId !== getters.tradingEvent.eventId) return;
    commit(types.SET_TRADING_EVENT, {
      ...getters.tradingEvent,
      isSuspended,
    });
  },
  async simulateMarketPrices({
    commit, getters, dispatch,
  }) {
    const { tradingPlayerProps, tradingEvent } = getters;
    const sportNameById = getSportNameBySportId(tradingEvent?.sportId)?.toLowerCase();
    const payload = mapTradingPlayerPropsPricesSimulatePayload(tradingPlayerProps, tradingEvent);
    commit(types.SET_IS_SIMULATE_BTN_LOADING, true);
    commit(types.SET_IS_TRADING_UI_PAGE_FROZEN, true);
    try {
      const response = await api.getPriceOnDemand({
        eventId: tradingEvent.eventId,
        sport: sportNameById,
        data: payload,
      });
      const marketsByPlayerIndex = mapMarketsByPlayerIndex(response.data.playerMarkets);
      const playerParamsData = {
        teamA: tradingPlayerProps?.teamA?.playerProps,
        teamB: tradingPlayerProps?.teamB?.playerProps,
      };
      const playerParamsWithMarketPrices = mergePlayerDataWithMarketPrices(
        playerParamsData,
        marketsByPlayerIndex,
      );
      const newData = cloneDeep(tradingPlayerProps);
      newData.teamA.playerProps = playerParamsWithMarketPrices.teamA;
      newData.teamB.playerProps = playerParamsWithMarketPrices.teamB;
      commit(types.SET_TRADING_PLAYER_PROPS, newData);
      commit(types.SET_LAST_SIMULATE_TRADING_PLAYER_PROPS, newData);
      commit(types.SET_IS_SIMULATE_BTN_ENABLED, false);
      commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
      commit(types.SET_IS_TRADING_SIMULATE_BY_SOCKET_CHANGE_ENABLED, false);
      socket.subscribeToPricingOnDemand(tradingEvent.eventId);
      dispatch('addNotification', {
        message: 'Prices have been successfully updated!',
        type: 'success',
        duration: 2000,
      });
    } catch (e) {
      let errorMessage = 'Error while loading prices';
      if (e?.statusCode === 503) {
        const numberOfAttempts = getters.numberOfSimulateAttempts;
        if (numberOfAttempts < 5) {
          commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, numberOfAttempts + 1);
          dispatch('simulateMarketPrices');
          return;
        }
        errorMessage = 'Unable to get prices, try again in 10 seconds';
        commit(types.SET_IS_TRADING_UI_PAGE_SIMULATE_BTN_FROZEN, true);
      }
      commit(types.SET_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
      commit(types.SET_IS_SIMULATE_BTN_ENABLED, true);
      dispatch('addNotification', {
        message: errorMessage,
        type: 'error',
        duration: 2000,
      });
    }
    commit(types.SET_IS_SIMULATE_BTN_LOADING, false);
    commit(types.SET_IS_TRADING_UI_PAGE_FROZEN, false);
  },
  async simulateTradingUIGameParams({ getters, dispatch, commit }) {
    const updatedEvent = cloneDeep(getters.tradingEvent);
    const { eventId, sportId } = updatedEvent;
    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    const marketsResponse = await api.getGameAvailableMarkets({ sport: sportNameById });
    const markets = marketsResponse?.marketTypes;
    let params = {};
    if (updatedEvent?.sportId === BASEBALL_ID) {
      params = {
        ...mapPlayerSetupBaseballGameParams(updatedEvent.params.gameParams),
      };
    } else {
      params = mapPlayerSetupGameParamsSubmitPayload(updatedEvent.params.gameParams);
    }
    const payload = {
      gameParams: {
        eventId,
        sport: sportNameById,
        ...params,
      },
      marketTypes: map(markets, (market) => ({
        marketCode: market.marketCode,
        sport: getSportLabelBySportId(sportId),
        params: market.params || market.marketType?.params,
      })),
    };
    // Adding additional param for basketball which is determined based on the competition type
    // Sport for basketball is determined based on the competition type
    if (updatedEvent.sportId === BASKETBALL_ID) {
      payload.gameParams.modelType = getBasketballGlobalParamsModelType(parseEventModel(updatedEvent)?.competitionType);
      payload.gameParams.sport = getBasketballGlobalParamsSportTypeByCompetition(parseEventModel(updatedEvent)?.competitionType);
    }
    try {
      updatedEvent.params.isSimulatingPrices = true;
      commit(types.SET_TRADING_EVENT, cloneDeep(updatedEvent));
      const response = await api.getGameParamsPriceOnDemand({
        eventId,
        sport: sportNameById,
        data: payload,
      });
      if (!response) return;
      commit(types.SET_PARAMS_MANAGER_MARKETS, map(getters.paramsManagerMarkets, (market) => {
        const foundMarket = find(response?.data?.markets, (updatedMarket) => (
          updatedMarket?.marketType?.marketCode === market.marketCode
          && updatedMarket?.marketType?.params?.TEAM === market?.params?.TEAM
          && updatedMarket?.marketType?.params?.PERIOD === market?.params?.PERIOD
          && updatedMarket?.marketType?.params?.HALF === market?.params?.HALF
          && updatedMarket?.marketType?.params?.RESULT_AFTER_X === market?.params?.RESULT_AFTER_X
        ));
        if (!foundMarket) return market;
        return {
          ...market,
          ...foundMarket,
        };
      }));
      updatedEvent.params.isSimulatingPrices = false;
      updatedEvent.params.numberOfSimulateAttempts = 0;
      updatedEvent.params.isSimulateEnabled = false;
      commit(types.SET_TRADING_EVENT, cloneDeep(updatedEvent));
      dispatch('addNotification', {
        message: 'Prices have been successfully updated!',
        type: 'success',
        duration: 2000,
      });
    } catch (e) {
      let errorMessages = e.messages || [];
      if (e?.statusCode === 503) {
        const numberOfAttempts = updatedEvent.params.numberOfSimulateAttempts;
        if (numberOfAttempts < 5) {
          updatedEvent.params.numberOfSimulateAttempts = numberOfAttempts + 1;
          commit(types.SET_TRADING_EVENT, cloneDeep(updatedEvent));
          dispatch('simulateTradingUIGameParams');
          return;
        }
        errorMessages = ['Unable to get prices, try again in 10 seconds'];
      }
      updatedEvent.params.isSimulateEnabled = true;
      updatedEvent.params.numberOfSimulateAttempts = 0;
      updatedEvent.params.isSimulatingPrices = false;
      commit(types.SET_TRADING_EVENT, cloneDeep(updatedEvent));

      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading prices');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  async submitTradingParameters({ commit, getters, dispatch }) {
    const { tradingPlayerProps, tradingEvent, lastSimulateTradingPlayerProps } = getters;
    const sportNameById = getSportNameBySportId(tradingEvent?.sportId)?.toLowerCase();
    const payload = mapTradingPlayerPropsPublishPayload(tradingPlayerProps, tradingEvent, getters.playerParamsSelectedMode || 'MANUAL');
    commit(types.SET_IS_PUBLISH_BTN_LOADING, true);
    let isSubmitError = false;
    // If player props are changed
    if (getters.isPublishBtnEnabled) {
      try {
        await api.setPlayerParams({
          eventId: tradingEvent.eventId,
          sport: sportNameById,
          data: payload,
        });
        // Check if we should call pricing on demand
        if (!isEqual(tradingPlayerProps, lastSimulateTradingPlayerProps)) await dispatch('simulateMarketPrices');

        commit(types.SET_ORIGINAL_TRADING_PLAYER_PROPS, tradingPlayerProps);
        commit(types.SET_LAST_SIMULATE_TRADING_PLAYER_PROPS, tradingPlayerProps);
        commit(types.SET_TRADING_PLAYER_PROPS, tradingPlayerProps);
        dispatch('addNotification', {
          message: 'Player params successfully edited',
          type: 'success',
        });
      } catch {
        isSubmitError = true;
        dispatch('addNotification', {
          message: 'Submitting has not been processed because of an error',
          type: 'error',
        });
      }
    }
    const tradingGameParams = tradingEvent?.params?.gameParams;
    const isBaseball = tradingEvent?.sportId === BASEBALL_ID;
    const gameParamsDataValid = isBaseball ? !isBaseballGameParamsError(tradingGameParams) : !find(tradingGameParams, (param) => param.isError);
    const gameParamsChanged = (isBaseball ? isBaseballGameParamsChanged(tradingGameParams) : find(tradingGameParams, (param) => param.value !== param.originalValue));
    if (gameParamsChanged && gameParamsDataValid) {
      let gameParamsPayload = {
        selectedParamSource: tradingEvent?.params?.selectedParamSource,
        gameParams: {
          sport: sportNameById,
        },
      };
      if (tradingEvent?.sportId === BASEBALL_ID) {
        gameParamsPayload = {
          ...gameParamsPayload,
          gameParams: {
            ...gameParamsPayload.gameParams,
            ...mapPlayerSetupBaseballGameParams(tradingGameParams),
          },
        };
      } else {
        gameParamsPayload = {
          ...gameParamsPayload,
          gameParams: {
            ...gameParamsPayload.gameParams,
            ...mapPlayerSetupGameParamsSubmitPayload(tradingGameParams),
          },
        };
      }
      // Adding additional param for basketball which is determined based on the competition type
      // Sport for basketball is determined based on the competition type
      if (tradingEvent.sportId === BASKETBALL_ID) {
        gameParamsPayload.gameParams.modelType = getBasketballGlobalParamsModelType(parseEventModel(tradingEvent)?.competitionType);
        gameParamsPayload.gameParams.sport = getBasketballGlobalParamsSportTypeByCompetition(parseEventModel(tradingEvent)?.competitionType);
      }
      try {
        await api.submitGameParams({
          sport: sportNameById,
          eventId: tradingEvent.eventId,
          data: gameParamsPayload,
        });
        commit(types.SET_TRADING_EVENT, {
          ...tradingEvent,
          params: {
            ...tradingEvent?.params,
            isSimulateEnabled: false,
            gameParams: mapPlayerSetupGameParams(gameParamsPayload.gameParams || {}, tradingEvent.sportId),
          },
        });
        dispatch('addNotification', {
          message: 'Game params successfully edited',
          type: 'success',
        });
      } catch {
        isSubmitError = true;
        dispatch('addNotification', {
          message: 'Unable to update game params',
          type: 'error',
        });
      }
    }

    if (isSubmitError) {
      commit(types.SET_IS_SIMULATE_BTN_ENABLED, getters.isSimulateBtnEnabled);
      commit(types.SET_IS_PUBLISH_BTN_ENABLED, true);
    } else {
      commit(types.SET_IS_SIMULATE_BTN_ENABLED, false);
      commit(types.SET_IS_PUBLISH_BTN_ENABLED, false);
    }

    commit(types.SET_IS_PUBLISH_BTN_LOADING, false);
  },
  getAllOperators({ commit, dispatch }, subscribe) {
    api.getAllOperators().then((res) => {
      const operatorsList = map(res, (operator) => ({
        operatorId: operator.operatorId,
        productHealthStatuses: operator.productHealthStatuses?.nodes || [],
      }));
      commit(types.SET_OPERATORS_LIST, operatorsList);
      if (subscribe) dispatch('subscribeToProductHealth', map(operatorsList, (operator) => operator.operatorId));
    });
  },
  updateMarketTier(_, {
    payload,
    marketId,
    eventId,
  }) {
    api.updateMarketTier({
      eventId,
      marketId,
      payload,
    });
  },
  updateSelectedTradingMarkets({ state, commit }, market) {
    let selectedMarkets = cloneDeep(state.selectedTradingMarkets);
    if (find(selectedMarkets, (selectedMarket) => selectedMarket.marketId === market.marketId)) {
      selectedMarkets = filter(selectedMarkets, (selectedMarket) => selectedMarket.marketId !== market.marketId);
    } else {
      selectedMarkets.push(market);
    }
    commit(types.SET_SELECTED_TRADING_MARKETS, selectedMarkets);
  },
  updateAllSelectedTradingMarkets({ commit }, { action, markets }) {
    if (action === 'clear') {
      commit(types.SET_SELECTED_TRADING_MARKETS, []);
      return;
    }
    const allMarkets = cloneDeep(markets);
    const allSelectedMarkets = map(allMarkets, (market) => ({
      marketId: market.marketId,
      displayStatus: market.displayStatus,
      offeringStatus: market.offeringStatus,
    }));
    commit(types.SET_SELECTED_TRADING_MARKETS, allSelectedMarkets);
  },
  async batchUpdateMarkets({ commit }, { eventId, action, markets }) {
    let filteredMarkets = cloneDeep(markets);
    switch (action) {
    case 'suspend':
      filteredMarkets = filter(markets, (market) => market.offeringStatus !== 'SUSPENDED');
      break;
    case 'unsuspend':
      filteredMarkets = filter(markets, (market) => market.offeringStatus === 'SUSPENDED');
      break;
    case 'display':
      filteredMarkets = filter(markets, (market) => market.displayStatus !== 'ON');
      break;
    case 'undisplay':
      filteredMarkets = filter(markets, (market) => market.displayStatus === 'ON');
      break;
    default:
      break;
    }
    const marketIds = map(filteredMarkets, (market) => market.marketId);
    try {
      await api.batchUpdateMarkets({
        eventId,
        action,
        marketIds,
      });
      commit(types.SET_SELECTED_TRADING_MARKETS, []);
    } catch {
      console.log('Updating failed');
    }
  },
  updateMarketBias(_, {
    payload,
    marketId,
    eventId,
  }) {
    api.updateMarketBias({
      eventId,
      marketId,
      payload,
    });
  },
  async initializeSelectedOddsFormat({ dispatch }) {
    const { oddsFormatType = 'AMERICAN' } = await api.findUserConfiguration();
    dispatch('setSelectedOddsFormatById', oddsFormatType);
  },
  setSelectedOddsFormatById({ commit, getters }, oddsFormatId) {
    const oddsFormat = find(getters.oddFormats, { id: oddsFormatId }) || getters.oddFormats?.[0];
    if (!oddsFormat) throw new Error('No odds formats available!');
    commit(types.UPDATE_SELECTED_ODDS_FORMAT, oddsFormat);
  },
  async updateSelectedOddsFormat({ getters, commit, dispatch }, oddsFormat) {
    const oldOddsFormat = cloneDeep(getters.selectedOddFormat);
    try {
      commit(types.UPDATE_SELECTED_ODDS_FORMAT, oddsFormat);
      await api.setUserConfiguration({ oddsFormat: oddsFormat.id });
    } catch (error) {
      console.error(error);
      commit(types.UPDATE_SELECTED_ODDS_FORMAT, oldOddsFormat);
      dispatch('addNotification', {
        message: 'Could not update odds format. Please try again later!',
        type: 'error',
        duration: 5000,
      });
    }
  },
  refreshPrices(_, eventId) {
    return new Promise((resolve, reject) => {
      api.refreshPrices(eventId)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
  loadFeedsPerSport({ commit, getters }, sportId) {
    const options = { mappedEntity: { sportId: { equalTo: sportId } } };
    api.useFeedsPerSportQuery({
      queryOptions: {
        filter: options,
      },
    }).then((res) => {
      commit(types.SET_MAPPING_FEEDS_PER_SPORT, map(filter(res.data?.feeds?.nodes,
        (item) => item.feed !== 'nba.com' && item.feed !== 'rotowire'), (feed) => feed.feed));
    }).then(() => {
      let allFeeds = [
        ...getters.mappingPrimaryFeeds,
        ...getters.mappingSecondaryFeeds,
      ];
      allFeeds = sortBy(allFeeds, (feed) => feedsOrder[feed]);
      commit(types.SET_MAPPING_DISPLAYED_FEEDS, slice(allFeeds, 0, 3));
    });
  },
  replaceDisplayedFeedsOrder({ commit, getters }, feedsToReplace) {
    const displayedFeeds = cloneDeep(getters.mappingDisplayedFeeds);
    const { feed1, feed2 } = feedsToReplace;

    const indexOfFeed1 = indexOf(displayedFeeds, feed1);
    const indexOfFeed2 = indexOf(displayedFeeds, feed2);
    displayedFeeds[indexOfFeed1] = feed2;
    displayedFeeds[indexOfFeed2] = feed1;

    commit(types.SET_MAPPING_DISPLAYED_FEEDS, displayedFeeds);
  },
  removeDisplayedFeed({ commit, getters }, feedToRemove) {
    commit(types.SET_MAPPING_DISPLAYED_FEEDS,
      filter(getters.mappingDisplayedFeeds, (feed) => feed !== feedToRemove));
  },
  addDisplayedFeed({ commit, getters }, feedToAdd) {
    const displayedFeeds = cloneDeep(getters.mappingDisplayedFeeds);
    displayedFeeds.push(feedToAdd);
    commit(types.SET_MAPPING_DISPLAYED_FEEDS, displayedFeeds);
  },
  updateMarketSuspensionByMarketId(_, {
    eventId, marketId, operation, allLines,
  }) {
    return new Promise((resolve, reject) => {
      api.updateMarketSuspensionByMarketId({
        eventId, marketId, operation, allLines,
      })
        .then(() => {
          resolve();
        })
        .catch((errorMessage) => {
          reject(errorMessage);
        });
    });
  },
  suspendMarketTypeAllLines(_, {
    eventId, marketInfo, operation,
  }) {
    const action = operation === 'suspend' ? api.suspendMarketTypeAllLines : api.unsuspendMarketTypeAllLines;
    return new Promise((resolve, reject) => {
      action({
        eventId, data: marketInfo,
      })
        .then(() => {
          resolve();
        })
        .catch((errorMessage) => {
          reject(errorMessage);
        });
    });
  },
  batchUpdateMarketSuspensionByMarketId(_, { eventId, marketIds, operation }) {
    return new Promise((resolve, reject) => {
      api.batchUpdateMarketSuspensionByMarketId({ eventId, marketIds, operation })
        .then(() => {
          resolve();
        })
        .catch((errorMessage) => {
          reject(errorMessage);
        });
    });
  },
  updateTradingUIPricingProbablities({ getters, commit }, data) {
    const tradingEvent = cloneDeep(getters.tradingEvent);
    const pricingProbabilitiesToUpdate = find(tradingEvent.pricingProbabilitiesByEventId.nodes, (item) => item.eventId === data.eventId);
    if (pricingProbabilitiesToUpdate) {
      pricingProbabilitiesToUpdate.probabilities = data.probabilities;
    }
    commit(types.SET_TRADING_EVENT, tradingEvent);
  },
  // Manual edit
  updateManualEditSelectedSport({ commit }, selectedSportId) {
    commit(types.UPDATE_MANUAL_EDIT_SELECTED_SPORT, selectedSportId);
  },
  updateManualEditTableColumns({ commit }, columns) {
    commit(types.UPDATE_MANUAL_EDIT_TABLE_COLUMNS, columns);
  },
  updateManualEditSelectedRow({ commit }, row) {
    commit(types.UPDATE_MANUAL_EDIT_SELECTED_ROW, row);
  },
  clearManualEditModalData({ commit }) {
    commit(types.UPDATE_MANUAL_EDIT_SELECTED_ROW, '');
    commit(types.UPDATE_MANUAL_EDIT_IS_CREATE_NEW_RECORD_ACTIVE, false);
    const route = cloneDeep(router.currentRoute.value);
    delete route.query.teamId;
    router.replace({
      ...route,
      query: {
        ...route.query,
      },
    });
  },
  updateManualEditSearch({ commit, dispatch }, searchInfo) {
    dispatch('setPaginationPage', 1);
    commit(types.UPDATE_MANUAL_EDIT_SEARCH, searchInfo);
  },
  updateManualEditSelectedFeed({ commit, dispatch }, feed) {
    dispatch('setPaginationPage', 1);
    commit(types.UPDATE_MANUAL_EDIT_SELECTED_FEED, feed);
  },
  updateManualEditIsCreateNewRecordActive({ commit }, isActive) {
    commit(types.UPDATE_MANUAL_EDIT_IS_CREATE_NEW_RECORD_ACTIVE, isActive);
  },
  async loadManualEditTeams({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
      filter: {
        name: { includesInsensitive: getters.manualEditSearch?.value || '' },
      },
    };
    if (getters.manualEditSelectedFeed) {
      options.filter.source = { equalTo: getters.manualEditSelectedFeed };
    }

    commit(types.UPDATE_MANUAL_EDIT_TABLE_DATA_LOADING, true);
    const res = await api.useManualEditTeamsQuery({
      sportId: getters.manualEditSelectedSport,
      queryOptions: options,
    });

    commit(types.UPDATE_MANUAL_EDIT_TABLE_DATA_LOADING, false);
    // Filtering all the players that don't have playerId
    // Formating and filterin mappings data
    commit(types.UPDATE_MANUAL_EDIT_TABLE_DATA, map(
      res.nodes,
      (team) => ({
        ...team,
        mappings: filter(team.mappings?.nodes, (mapping) => mapping.mappingType === 'EXACT_MATCH'),
        squad: filter(team.squad, (item) => has(item.player, ['playerId'])),
      }),
    ));
    dispatch('setTotalRowsCount', res?.totalCount);
  },
  async loadManualEditSingleTeam({ getters }, { teamId, sportId }) {
    const options = {
      filter: {
        teamId: { equalTo: teamId },
      },
    };
    if (getters.manualEditSelectedFeed) {
      options.filter.source = { equalTo: getters.manualEditSelectedFeed };
    }

    const res = await api.useManualEditTeamsQuery({
      sportId,
      queryOptions: options,
    });

    // Filtering all the players that don't have playerId
    // Formating and filterin mappings data
    return map(
      res.nodes,
      (team) => ({
        ...team,
        mappings: filter(team.mappings?.nodes, (mapping) => mapping.mappingType === 'EXACT_MATCH'),
        squad: filter(team.squad, (item) => has(item.player, ['playerId'])),
      }),
    )[0];
  },
  async loadManualEditSearchPlayers({ getters, commit }, { searchValue, searchBy, squadPlayersIds }) {
    const options = {
      first: 50,
      offset: 0,
    };
    if (searchValue && searchBy) {
      if (searchBy === 'player-name') {
        options.condition = { playerNameSimilar: searchValue };
      } else {
        options.filter = { player: {} };
        if (searchBy === 'player-id') {
          options.filter.player.playerId = { equalTo: searchValue };
        } else if (searchBy === 'player-index') {
          options.filter.player.playerIndex = { equalTo: parseInt(searchValue, 10) };
        }
      }
    }

    if (squadPlayersIds.length) {
      if (!has(options, 'filter')) options.filter = {};
      options.filter.playerId = { notIn: squadPlayersIds };
    }

    commit(types.UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST_LOADING, true);
    const res = await api.useSearchPlayerBySportQuery({
      sportId: getters.manualEditSelectedSport,
      queryOptions: options,
    });

    commit(types.UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST_LOADING, false);
    commit(types.UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST, res.nodes);
  },
  updateTeamData({ getters, dispatch }, payload) {
    api.manuallyEditTeam({
      sport: getSportLabelBySportId(getters.manualEditSelectedSport),
      teamId: payload.teamId,
      data: {
        ...payload,
        stadiumName: payload.stadium,
      },
    }).then(() => {
      dispatch('clearManualEditModalData');
      dispatch('loadManualEditTeams');
      dispatch('addNotification', {
        message: 'Team successfully updated',
        type: 'success',
      });
    }).catch(() => {
      dispatch('addNotification', {
        message: 'Unable to update the team',
        type: 'error',
      });
    });
  },
  manuallyCreateTeam({ getters, dispatch }, payload) {
    api.manuallyCreateTeam({
      sport: getSportLabelBySportId(getters.manualEditSelectedSport),
      data: {
        ...payload,
        stadiumName: payload.stadium,
      },
    }).then(() => {
      dispatch('clearManualEditModalData');
      dispatch('loadManualEditTeams');
      dispatch('addNotification', {
        message: 'Team successfully created',
        type: 'success',
      });
    }).catch(() => {
      dispatch('addNotification', {
        message: 'Unable to create the team',
        type: 'error',
      });
    });
  },
  manuallyRemoveTeam({ getters, dispatch }, payload) {
    api.manuallyRemoveTeam({
      sport: getSportLabelBySportId(getters.manualEditSelectedSport),
      teamId: payload.teamId,
      data: payload,
    }).then(() => {
      dispatch('clearManualEditModalData');
      dispatch('loadManualEditTeams');
      dispatch('addNotification', {
        message: 'Team successfully updated',
        type: 'success',
      });
    }).catch(() => {
      dispatch('addNotification', {
        message: 'Unable to update the team',
        type: 'error',
      });
    });
  },
  async loadManualEditPlayers({ getters, commit, dispatch }) {
    const options = {
      first: getters.rowsPerPage,
      offset: (getters.currentPage - 1) * getters.rowsPerPage,
    };
    const { value, searchBy } = getters.manualEditSearch;
    if (value && searchBy) {
      if (searchBy === 'player-name') {
        set(options, 'condition', { playerNameSimilar: value });
      } else {
        set(options, 'filter', { player: {} });
        if (searchBy === 'player-id') {
          set(options, 'filter.player.playerId', { equalTo: value });
        } else if (searchBy === 'player-index') {
          set(options, 'filter.player.playerIndex', { equalTo: parseInt(value, 10) });
        }
      }
    }

    if (getters.manualEditSelectedFeed) {
      set(options, 'filter.player.source', { equalTo: getters.manualEditSelectedFeed });
    }

    commit(types.UPDATE_MANUAL_EDIT_TABLE_DATA_LOADING, true);
    const res = await api.useManualEditPlayersQuery({
      sportId: getters.manualEditSelectedSport,
      queryOptions: options,
    });

    const dataMapped = map(res.nodes, (item) => ({
      name: item.player.name,
      teamName: item.name,
      source: item.player.source,
      playerId: item.player.playerId,
      position: item.player.position,
      playerIndex: item.player.playerIndex,
      teamId: item.teamId,
    }));
    commit(types.UPDATE_MANUAL_EDIT_TABLE_DATA_LOADING, false);
    // Filtering all the players that don't have playerId
    commit(types.UPDATE_MANUAL_EDIT_TABLE_DATA, dataMapped);
    dispatch('setTotalRowsCount', res?.totalCount);
  },
  updatePlayerData({ getters, dispatch }, payload) {
    api.manuallyEditPlayer({
      sport: getSportLabelBySportId(getters.manualEditSelectedSport),
      playerId: payload.playerId,
      data: payload,
    }).then(() => {
      dispatch('clearManualEditModalData');
      dispatch('loadManualEditPlayers');
      dispatch('addNotification', {
        message: 'Player successfully updated',
        type: 'success',
      });
    }).catch(() => {
      dispatch('addNotification', {
        message: 'Unable to update a player',
        type: 'error',
      });
    });
  },
  manuallyCreatePlayer({ getters, dispatch }, payload) {
    api.manuallyCreatePlayer({
      sport: getSportLabelBySportId(getters.manualEditSelectedSport),
      data: payload,
    }).then(() => {
      dispatch('clearManualEditModalData');
      dispatch('loadManualEditPlayers');
      dispatch('addNotification', {
        message: 'Player successfully created',
        type: 'success',
      });
    }).catch(() => {
      dispatch('addNotification', {
        message: 'Unable to create a player',
        type: 'error',
      });
    });
  },
  manuallyRemovePlayer({ getters, dispatch }, payload) {
    api.manuallyRemovePlayer({
      sport: getSportLabelBySportId(getters.manualEditSelectedSport),
      playerId: payload.playerId,
    }).then(() => {
      dispatch('clearManualEditModalData');
      dispatch('loadManualEditPlayers');
      dispatch('addNotification', {
        message: 'Player successfully removed',
        type: 'success',
      });
    }).catch(() => {
      dispatch('addNotification', {
        message: 'Unable to remove a player',
        type: 'error',
      });
    });
  },

  // Multiview
  async subscribeMultiviewEventList({ getters }) {
    const events = lodashValues(getters.multiviewListEventsById);
    if (!events?.length) return;
    socket.subscribeMultiviewEventList(eventHelper.groupEventIdsBySource(events));
  },
  async unsubscribeMultiviewEventList({ getters }) {
    const events = lodashValues(getters.multiviewListEventsById);
    if (!events?.length) return;
    socket.unsubscribeMultiviewEventList(eventHelper.groupEventIdsBySource(events));
  },
  async loadMultiviewEventList({ commit, dispatch }, options) {
    try {
      const queryOptions = {
        filter: {
          eventId: { in: options?.eventIds || [] },
        },
        condition: {},
      };

      if (options?.sort) {
        set(queryOptions, 'orderBy', options.sort);
      }
      if (options.status?.length) {
        set(queryOptions, 'filter.matchState.in', options.status);
      }

      dispatch('unsubscribeMultiviewEventList');
      commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, {});
      commit(types.SET_MULTIVIEW_LIST_LOADING, true);
      commit(types.SET_MULTIVIEW_LIST_LATEST_OPTIONS, options);
      const response = await api.findMultiviewEvents(pickBy(queryOptions, identity));
      let multiViewMarkets = {};
      const allEventsById = reduce(
        response.data?.events?.nodes || [],
        (collection, event) => {
          const mappedMarkets = event.markets?.nodes?.reduce((markets, market) => ({ ...markets, [`${event.eventId}_${market.marketId}`]: market }), {});
          multiViewMarkets = { ...multiViewMarkets, ...mappedMarkets };
          return { ...collection, [event.eventId]: event };
        },
        {},
      );
      commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, allEventsById);
      commit(types.SET_MULTIVIEW_LIST_MARKETS, multiViewMarkets);
      dispatch('subscribeMultiviewEventList');

      // Loading game params
      dispatch('loadMultiviewEventListGameParams');
    } catch (error) {
      console.error(error);
      dispatch('clearMultiviewEventList');
    } finally {
      commit(types.SET_MULTIVIEW_LIST_LOADING, false);
    }
  },
  async loadMultiviewEventListGameParams({ getters, commit }) {
    const allowedUserRoles = getAllowedRoles('playerSetup');
    const isAllowedRole = isAllowedUserRole(allowedUserRoles);
    if (!isAllowedRole) return;
    const eventIdsBySportName = reduce(
      getters.multiviewListEventsById,
      (allEventsBySport, event) => {
        const sportName = toLower(getSportNameBySportId(event.sportId));
        if (!includes([HOCKEY_ID, BASKETBALL_ID, FOOTBALL_ID, BASEBALL_ID], event.sportId)) return allEventsBySport;
        return {
          ...allEventsBySport,
          [sportName]: [...(allEventsBySport[sportName] || []), event.eventId],
        };
      }, {},
    );
    const containsBaseballEvent = !!find(getters.multiviewListEventsById, ({ sportId }) => sportId === BASEBALL_ID);
    if (containsBaseballEvent) {
      const allBaseballParks = await api.loadAllBaseballParks();
      commit(types.SET_ALL_BASEBALL_PARKS, allBaseballParks.data?.allBaseballParks?.parks);
    }
    let gameParamsByEventId = {};
    commit(types.SET_MULTIVIEW_LIST_PARAMS_LOADING, true);
    Promise.all(
      map(eventIdsBySportName, (eventIds, sport) => api.getGameParamsList({ sport, eventIds, mode: 'active' })),
    )
      .then((responses) => {
        gameParamsByEventId = reduce(
          responses,
          (allGameParams, gamesBySport) => {
            const eventsBySport = reduce(
              gamesBySport,
              (allEvents, gameParams) => ({
                ...allEvents,
                [gameParams.eventId]: gameParams,
              }),
              {},
            );
            return {
              ...allGameParams,
              ...eventsBySport,
            };
          },
          {},
        );
        const multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
        if (isEmpty(multiviewListEventsById)) return;
        forEach(gameParamsByEventId, (params, eventId) => {
          multiviewListEventsById[eventId].params = ({
            ...params,
            gameParams: mapPlayerSetupGameParams(params.gameParams || {}, multiviewListEventsById[eventId]?.sportId),
          });
        });
        commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, multiviewListEventsById);
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        commit(types.SET_MULTIVIEW_LIST_PARAMS_LOADING, false);
      });
  },
  async loadMultiviewSingleEventGameParams({ getters, commit }, { eventId }) {
    const multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
    const updatedEvent = multiviewListEventsById[eventId];
    const manualGameParams = await api.getGameParams({
      sport: getSportNameBySportId(updatedEvent?.sportId).toLowerCase(),
      eventId,
      mode: getters.gameParamsSelectedMode || 'active',
    });
    const gameParams = mapPlayerSetupGameParams(manualGameParams?.gameParams || {}, updatedEvent?.sportId, manualGameParams?.paramsAreDefaultOfProjected);
    await commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, cloneDeep({
      ...updatedEvent,
      params: {
        ...manualGameParams,
        gameParams,
      },
    }));
  },
  async submitMultiviewGameParams({ getters, dispatch, commit }, { eventId }) {
    const multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
    const updatedEvent = multiviewListEventsById[eventId];
    const sportNameById = getSportNameBySportId(updatedEvent.sportId).toLowerCase();
    let payload = {
      selectedParamSource: updatedEvent.params.selectedParamSource,
      gameParams: {
        sport: sportNameById,
      },
    };
    if (updatedEvent?.sportId === BASEBALL_ID) {
      payload = {
        ...payload,
        gameParams: {
          ...payload.gameParams,
          ...mapPlayerSetupBaseballGameParams(updatedEvent.params.gameParams),
        },
      };
    } else {
      payload = {
        ...payload,
        gameParams: {
          ...payload.gameParams,
          ...mapPlayerSetupGameParamsSubmitPayload(updatedEvent.params.gameParams),
        },
      };
    }
    // Adding additional param for basketball which is determined based on the competition type
    // Sport for basketball is determined based on the competition type
    if (updatedEvent.sportId === BASKETBALL_ID) {
      payload.gameParams.modelType = getBasketballGlobalParamsModelType(parseEventModel(updatedEvent)?.competitionType);
      payload.gameParams.sport = getBasketballGlobalParamsSportTypeByCompetition(parseEventModel(updatedEvent)?.competitionType);
    }
    try {
      await api.submitGameParams({
        sport: sportNameById,
        eventId,
        data: payload,
      });
      updatedEvent.params = ({
        ...updatedEvent.params,
        gameParams: mapPlayerSetupGameParams(payload.gameParams || {}, updatedEvent?.sportId),
      });
      updatedEvent.simulatedMarkets = [];
      updatedEvent.showSimulatedMarkets = false;
      updatedEvent.params.isSimulateEnabled = false;
      commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, updatedEvent);
      dispatch('addNotification', {
        message: 'Game params successfully edited',
        type: 'success',
      });
    } catch {
      dispatch('addNotification', {
        message: 'Unable to update game params',
        type: 'error',
      });
    }
  },
  async simulateMultiviewGameParams({ getters, dispatch, commit }, { eventId, isListSimulate }) {
    let multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
    let updatedEvent = multiviewListEventsById[eventId];
    if (!updatedEvent) return;
    if (!updatedEvent?.params) {
      // In case of refresh when drawer is opened there is case where drawer is loaded
      // before the list and we can't see game params here
      // In that case we load game params only for opened event and refresh the list
      await dispatch('loadMultiviewSingleEventGameParams', { eventId });
      multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
      updatedEvent = multiviewListEventsById[eventId];
    }
    const sportNameById = getSportNameBySportId(updatedEvent.sportId).toLowerCase();
    // let markets = getters.getMultiviewListEventMarkets(eventId);
    const marketsResponse = await api.getGameAvailableMarkets({ sport: sportNameById });
    const markets = marketsResponse?.marketTypes;
    let gameParams;
    if (updatedEvent?.sportId === BASEBALL_ID) {
      gameParams = {
        ...mapPlayerSetupBaseballGameParams(updatedEvent.params.gameParams),
      };
    } else {
      gameParams = {
        ...mapPlayerSetupGameParamsSubmitPayload(updatedEvent.params.gameParams),
      };
    }
    const payload = {
      gameParams: {
        eventId,
        sport: sportNameById,
        ...gameParams,
      },
      marketTypes: map(markets, (market) => ({
        marketCode: market.marketCode,
        sport: getSportLabelBySportId(updatedEvent.sportId),
        params: market.params || market.marketType?.params,
      })),
    };
    // Adding additional param for basketball which is determined based on the competition type
    // Sport for basketball is determined based on the competition type
    if (updatedEvent.sportId === BASKETBALL_ID) {
      payload.gameParams.modelType = getBasketballGlobalParamsModelType(parseEventModel(updatedEvent)?.competitionType);
      payload.gameParams.sport = getBasketballGlobalParamsSportTypeByCompetition(parseEventModel(updatedEvent)?.competitionType);
    }
    try {
      updatedEvent.params.isSimulatingPrices = true;
      commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, cloneDeep(updatedEvent));
      const response = await api.getGameParamsPriceOnDemand({
        eventId,
        sport: sportNameById,
        data: payload,
      });
      if (!response) return;
      if (isListSimulate) {
        updatedEvent.simulatedMarkets = map(markets, (market) => {
          const foundMarket = find(response?.data?.markets, (updatedMarket) => (
            updatedMarket?.marketType?.marketCode === market.marketCode
            && updatedMarket?.marketType?.params?.TEAM === market?.params?.TEAM
            && updatedMarket?.marketType?.params?.PERIOD === market?.params?.PERIOD
            && updatedMarket?.marketType?.params?.HALF === market?.params?.HALF
            && updatedMarket?.marketType?.params?.RESULT_AFTER_X === market?.params?.RESULT_AFTER_X
          ));
          if (!foundMarket) return market;
          return {
            ...market,
            ...foundMarket,
          };
        });
      } else {
        commit(types.SET_PARAMS_MANAGER_MARKETS, map(getters.paramsManagerMarkets, (market) => {
          const foundMarket = find(response?.data?.markets, (updatedMarket) => (
            updatedMarket?.marketType?.marketCode === market.marketCode
            && updatedMarket?.marketType?.params?.TEAM === market?.params?.TEAM
            && updatedMarket?.marketType?.params?.PERIOD === market?.params?.PERIOD
            && updatedMarket?.marketType?.params?.HALF === market?.params?.HALF
            && updatedMarket?.marketType?.params?.RESULT_AFTER_X === market?.params?.RESULT_AFTER_X
          ));
          if (!foundMarket) return market;
          return {
            ...market,
            ...foundMarket,
          };
        }));
      }
      updatedEvent.params.isSimulatingPrices = false;
      updatedEvent.params.numberOfSimulateAttempts = 0;
      updatedEvent.params.isSimulateEnabled = false;
      commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, cloneDeep(updatedEvent));
      dispatch('addNotification', {
        message: 'Prices have been successfully updated!',
        type: 'success',
        duration: 2000,
      });
    } catch (e) {
      let errorMessages = e.messages || [];
      if (e?.statusCode === 503) {
        const numberOfAttempts = updatedEvent.params?.numberOfSimulateAttempts;
        if (numberOfAttempts < 5) {
          updatedEvent.params.numberOfSimulateAttempts = numberOfAttempts + 1;
          commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, cloneDeep(updatedEvent));
          dispatch('simulateMultiviewGameParams');
          return;
        }
        errorMessages = ['Unable to get prices, try again in 10 seconds'];
      }
      updatedEvent.params.isSimulateEnabled = true;
      updatedEvent.params.numberOfSimulateAttempts = 0;
      updatedEvent.params.isSimulatingPrices = false;
      commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, cloneDeep(updatedEvent));

      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading prices');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  socketUpdateMultiviewGameParams({ commit, getters }, data) {
    const multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
    const updatedEvent = multiviewListEventsById[data.eventId];
    updatedEvent.params = ({
      ...updatedEvent.params,
      gameParams: mapPlayerSetupGameParams(data.gameParams || {}, updatedEvent?.sportId),
      selectedParamSource: data.paramSource,
      selectedMarkets: {
        ...updatedEvent.params.selectedMarkets,
        limitedOffer: data.limitedOffer,
      },
    });
    commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, updatedEvent);
  },
  toggleShowSimulatePreviewPrices({ getters, commit }, { eventId }) {
    const multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
    const updatedEvent = multiviewListEventsById[eventId];
    updatedEvent.showSimulatedMarkets = !updatedEvent.showSimulatedMarkets;
    commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, updatedEvent);
  },
  updateMultiviewListGameParams({ getters, commit }, { eventId, updatedParam }) {
    const multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
    const updatedEvent = multiviewListEventsById[eventId];
    const updatedParams = updatedEvent.params.gameParams;
    updatedEvent.params.isSimulateEnabled = true;
    const paramIndex = findIndex(updatedParams, { key: updatedParam.key });
    updatedParams[paramIndex] = updatedParam;
    commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, updatedEvent);
  },
  updateMultiviewListBaseballGameParams({ getters, commit }, { eventId, updatedParam }) {
    const multiviewListEventsById = cloneDeep(getters.multiviewListEventsById);
    const updatedEvent = multiviewListEventsById[eventId];
    const updatedParams = updatedEvent.params.gameParams;
    updatedEvent.params.isSimulateEnabled = true;
    set(updatedParams, updatedParam.mappableKey, updatedParam);
    commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID, updatedEvent);
  },
  async reloadMultiviewEventList({ getters, dispatch }) {
    const { multiviewListLatestOptions } = getters;
    dispatch('loadMultiviewEventList', multiviewListLatestOptions);
  },
  async suspendMultiviewEventListEvent({ getters, commit, dispatch }, eventId) {
    const oldEventsById = cloneDeep(getters.multiviewListEventsById);
    try {
      dispatch('setMultiviewEventListEventSuspendStatus', {
        eventId,
        isSuspended: true,
      });
      await api.suspendAllMarkets(eventId);
    } catch (error) {
      console.error(error);
      commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, oldEventsById);
    }
  },
  async unsuspendMultiviewEventListEvent({ getters, commit, dispatch }, eventId) {
    const oldEventsById = cloneDeep(getters.multiviewListEventsById);
    try {
      dispatch('setMultiviewEventListEventSuspendStatus', {
        eventId,
        isSuspended: false,
      });
      await api.unsuspendAllMarkets(eventId);
    } catch (error) {
      console.error(error);
      commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, oldEventsById);
    }
  },
  async setMultiviewEventListEventSuspendStatus({ getters, commit }, { eventId, isSuspended }) {
    if (!getters.multiviewListEventsById?.[eventId]) return;
    const newEventsById = cloneDeep(getters.multiviewListEventsById);
    if (isSuspended) {
      set(newEventsById[eventId], 'operatorEventSuspensionsByEventId.nodes', [{}]);
    } else {
      set(newEventsById[eventId], 'operatorEventSuspensionsByEventId.nodes', []);
    }
    commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, newEventsById);
  },
  async setMultiviewEventListEventLimits({ getters, commit }, limitConfiguration) {
    if (!limitConfiguration) return;

    const oldEventsById = cloneDeep(getters.multiviewListEventsById);

    try {
      const newEventsById = cloneDeep(getters.multiviewListEventsById);
      const { eventId = '' } = limitConfiguration;
      set(newEventsById[eventId], 'limitConfiguration.nodes', [{ limitConfiguration }]);
      commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, newEventsById);
      await api.overrideLimits(limitConfiguration);
    } catch (error) {
      console.error(error);
      commit(types.SET_MULTIVIEW_LIST_COMPETITIONS_BY_ID, oldEventsById);
    }
  },
  removeMultiviewEventListEvent({ getters, commit, dispatch }, eventId) {
    dispatch('unsubscribeMultiviewEventList');
    const newEventsById = cloneDeep(getters.multiviewListEventsById);
    delete newEventsById[eventId];
    commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, newEventsById);
    dispatch('subscribeMultiviewEventList');
  },
  clearMultiviewEventList({ commit }) {
    commit(types.SET_MULTIVIEW_LIST_LATEST_OPTIONS, null);
    commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, {});
    commit(types.SET_MULTIVIEW_LIST_COMPETITIONS_BY_ID, {});
    commit(types.SET_MULTIVIEW_LIST_MARKETS, {});
  },

  reloadMultiview({ getters, dispatch }) {
    const latestOptions = getters.multiviewListLatestOptions;
    if (isNil(latestOptions)) return;
    if (latestOptions.eventIds) dispatch('loadMultiviewEventList', latestOptions);
  },
  updateMultiviewEventListInfo({ getters, commit }, data) {
    const {
      eventId,
      eventName,
      startDateTime: startsAt,
      status,
      matchStatus: matchState,
      competitors,
      isUSAView: isUsaView,
    } = data ?? {};

    if (!getters.multiviewListEventsById[eventId]) return;

    const updatedEvents = cloneDeep(getters.multiviewListEventsById);
    updatedEvents[eventId] = {
      ...updatedEvents[eventId],
      eventName,
      startsAt,
      status,
      matchState,
      competitors,
      isUsaView,
    };
    commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, updatedEvents);
  },
  updateMultiviewListEventDetails({ getters, commit }, payload) {
    let matchState;
    let eventDetails;
    let eventDetailsField;

    if (!getters.multiviewListEventsById[payload.eventId]) return;
    const event = cloneDeep(getters.multiviewListEventsById[payload.eventId]);

    if (event.matchState !== 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'FIRST_PERIOD')
        || payload.state.period === 'FIRST_QUARTER' || payload.state.period === 'IN_FIRST_HALF') {
        matchState = 'LIVE';
      } else {
        matchState = event.matchState;
      }
    } else if (event.matchState === 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'POST_GAME')
        || payload.state.period === 'EVENT_COMPLETED') {
        matchState = 'FINISHED';
      } else {
        matchState = event.matchState;
      }
    } else {
      matchState = event.matchState;
    }

    switch (event.sportId) {
    case SOCCER_ID:
      eventDetailsField = 'soccerEventDetails';
      eventDetails = Soccer.updateEvent(event[eventDetailsField]?.nodes?.[0], payload);
      break;
    case BASKETBALL_ID:
      eventDetailsField = 'basketballEventDetails';
      eventDetails = Basketball.updateEvent(eventHelper.findEventDetails(event), payload);
      break;
    case FOOTBALL_ID:
      eventDetailsField = 'footballEventDetails';
      eventDetails = Football.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    case ULTIMATE_ID:
      eventDetailsField = 'ultimateEventDetails';
      eventDetails = Ultimate.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    case HOCKEY_ID:
      eventDetailsField = 'hockeyEventDetails';
      eventDetails = Hockey.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    case BASEBALL_ID:
      eventDetailsField = 'baseballEventDetails';
      eventDetails = Baseball.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    default:
      return;
    }

    commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, {
      ...getters.multiviewListEventsById,
      [payload.eventId]: {
        ...event,
        matchState,
        [eventDetailsField]: {
          nodes: [eventDetails],
        },
      },
    });
  },
  updateMultiviewEventDrawerEventDetails({ getters, commit }, payload) {
    let matchState;
    let eventDetails;
    let eventDetailsField;

    const event = cloneDeep(getters.multiviewDrawerEvent);

    if (event.matchState !== 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'FIRST_PERIOD')
        || payload.state.period === 'FIRST_QUARTER' || payload.state.period === 'IN_FIRST_HALF') {
        matchState = 'LIVE';
      } else {
        matchState = event.matchState;
      }
    } else if (event.matchState === 'LIVE') {
      if ((payload.state.currentClock && payload.state.currentClock?.period === 'POST_GAME')
        || payload.state.period === 'EVENT_COMPLETED') {
        matchState = 'FINISHED';
      } else {
        matchState = event.matchState;
      }
    } else {
      matchState = event.matchState;
    }

    switch (event.sportId) {
    case SOCCER_ID:
      eventDetailsField = 'soccerEventDetails';
      eventDetails = Soccer.updateEvent(event[eventDetailsField]?.nodes?.[0], payload);
      break;
    case BASKETBALL_ID:
      eventDetailsField = 'basketballEventDetails';
      eventDetails = Basketball.updateEvent(eventHelper.findEventDetails(event), payload);
      break;
    case FOOTBALL_ID:
      eventDetailsField = 'footballEventDetails';
      eventDetails = Football.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    case ULTIMATE_ID:
      eventDetailsField = 'ultimateEventDetails';
      eventDetails = Ultimate.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    case HOCKEY_ID:
      eventDetailsField = 'hockeyEventDetails';
      eventDetails = Hockey.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    case BASEBALL_ID:
      eventDetailsField = 'baseballEventDetails';
      eventDetails = Baseball.updateEvent(event[eventDetailsField].nodes?.[0], payload);
      break;
    default:
      return;
    }

    commit(types.SET_MULTIVIEW_DRAWER_EVENT, {
      ...event,
      matchState,
      [eventDetailsField]: {
        nodes: [eventDetails],
      },
    });
  },
  batchUpdateMultiviewMarkets({ state, commit }, incomingMarkets) {
    // Update drawer event markets
    if (state.multiviewDrawer?.event) {
      const allMarkets = cloneDeep(state.tradingEventMarkets);
      each(incomingMarkets, (market) => {
        if (state.multiviewDrawer.event.eventId !== market.eventId) return;
        const updatedTradingUiMarket = mergeTradingUiMarket(allMarkets, market);
        if (updatedTradingUiMarket.update) commit(types.UPDATE_SINGLE_TRADING_EVENT_MARKET, updatedTradingUiMarket.market);
      });
    }
    // Update multiview list events markets
    const allMultiviewListMarkets = cloneDeep(state.multiviewList.markets);
    each(incomingMarkets, (market) => {
      const updatedTradingUiMarket = mergeTradingUiMarket(allMultiviewListMarkets, market, 'multiViewList');
      if (updatedTradingUiMarket.update) {
        commit(types.UPDATE_SINGLE_MULTIVIEW_LIST_MARKET, { eventId: market.eventId, market: updatedTradingUiMarket.market });
      }
    });
  },
  async loadMultiviewDrawer({ commit, dispatch, getters }, eventId) {
    const { multiviewDrawerEvent } = getters;
    if (multiviewDrawerEvent && multiviewDrawerEvent?.eventId !== eventId) {
      dispatch('clearMultiviewDrawer');
    }
    try {
      commit(types.SET_MULTIVIEW_DRAWER_LOADING, true);
      commit(types.SET_MULTIVIEW_DRAWER_EVENT, null);
      socket.subscribeMultiviewEvent(eventId);
      socket.subscribeToPricingProbabilities(eventId);
      const response = await api.findMultiviewDrawer({ eventId });
      // const { event } = response.data;
      commit(types.SET_MULTIVIEW_DRAWER_EVENT, response.data?.event);
      const sportId = response.data?.event?.sport?.sportId;
      const { marketDisplayConfigurations } = await api.getMarketDisplayConfigurations({ sportId });
      const tradingEventMarketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
        ...markets,
        [`${market.marketCode}_${market.selections}`]: market,
      }), {});
      commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, tradingEventMarketsDisplayConfiguration);
      const tradingEventMarkets = response.data?.event?.markets?.nodes;
      const tradingEventMarketsObject = tradingEventMarkets.reduce((markets, market) => ({ ...markets, [market.marketId]: market }), {});
      commit(types.SET_TRADING_EVENT_MARKETS, tradingEventMarketsObject);
      try {
        const queryOptions = {
          filter: {
            sportId: {
              in: sportId,
            },
          },
        };
        const marketGroupsResponse = await api.getAllMarketGroups(queryOptions);
        commit(types.SET_MARKET_GROUPS, marketGroupsResponse?.marketGroups?.nodes || []);
        commit(types.SET_MARKET_FAVORITES, marketGroupsResponse?.allMarketFavorites?.nodes || []);
      } catch {
        commit(types.SET_MARKET_GROUPS, []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit(types.SET_MULTIVIEW_DRAWER_LOADING, false);
    }
  },
  async getMultiviewGameMarkets({ commit, getters, dispatch }) {
    const { multiviewDrawerEvent } = getters;
    try {
      const sportNameById = getSportNameBySportId(multiviewDrawerEvent.sportId).toLowerCase();
      const allMarkets = await api.getGameAvailableMarkets({ sport: sportNameById });
      const initialOfferingStatusInfo = multiviewDrawerEvent?.marketsOfferingStatus?.nodes;
      commit(types.SET_PARAMS_MANAGER_MARKETS,
        getParamsManagerMarketsBySport(allMarkets.marketTypes, multiviewDrawerEvent.sportId, initialOfferingStatusInfo, multiviewDrawerEvent?.isUsaView));
    } catch {
      dispatch('addNotification', {
        message: 'Error while loading markets data',
        type: 'error',
      });
    } finally {
      dispatch('simulateMultiviewGameParams', { eventId: multiviewDrawerEvent?.eventId });
    }
  },
  async getMultiviewPlayerParams({ commit, dispatch, getters }) {
    // Getting player params data
    const event = getters.multiviewDrawerEvent;
    const sportId = event?.sportId;
    const sportNameById = getSportNameBySportId(sportId).toLowerCase();
    try {
      commit(types.SET_MULTIVIEW_PLAYER_PARAMS_LOADING, true);
      const playerPropsData = await api.getPlayerSetupDetails({ sport: sportNameById, eventId: event.eventId });
      // Getting team squad data
      const homeSquad = await api.getTeamSquad({
        teamId: find(event.competitors, { side: 'HOME' }).teamId,
        sportId: event.sportId,
      });
      const awaySquad = await api.getTeamSquad({
        teamId: find(event.competitors, { side: 'AWAY' }).teamId,
        sportId: event.sportId,
      });
      const data = mapPlayerProps(
        playerPropsData,
        { homeSquad, awaySquad },
        event,
        event.sportId,
      );
      commit(types.SET_MULTIVIEW_ORIGINAL_PLAYER_PARAMS, data);
      commit(types.SET_MULTIVIEW_LAST_SIMULATE_PLAYER_PARAMS, data);
      commit(types.SET_MULTIVIEW_PLAYER_PARAMS, data);
      commit(types.SET_MULTIVIEW_SQUAD, mapSquadData(homeSquad, awaySquad));
      socket.subscribeToPlayerProps(event.eventId);
      dispatch('getPriceOnDemandMultiview');
    } catch {
      dispatch('addNotification', {
        message: 'Error while loading player params data',
        type: 'error',
      });
    } finally {
      commit(types.SET_MULTIVIEW_PLAYER_PARAMS_LOADING, false);
    }
  },
  updateMultiviewPlayerProps({ commit, getters }, data) {
    commit(types.SET_MULTIVIEW_PLAYER_PARAMS, data);
    // Check is simulate button enabled
    commit(types.SET_MULTIVIEW_SIMULATE_BTN_ENABLED,
      isTradingPlayerPropsStateChanged(
        getters.multiviewLastSimulatePlayerParams,
        data,
      ) && checkIsTradingPropsModelValid(data, getters.multiviewDrawerEvent.sportId));
    // Check is publish button enabled
    commit(types.SET_MULTIVIEW_SUBMIT_BTN_ENABLED,
      isTradingPlayerPropsStateChanged(
        getters.multiviewOriginalPlayerParams,
        data,
      ) && checkIsTradingPropsModelValid(data, getters.multiviewDrawerEvent.sportId));
  },
  async submitMultiviewPlayerProps({ commit, getters, dispatch }) {
    const { multiviewPlayerParams, multiviewDrawerEvent } = getters;
    const sportNameById = getSportNameBySportId(multiviewDrawerEvent.sportId).toLowerCase();
    const payload = mapTradingPlayerPropsPublishPayload(multiviewPlayerParams, multiviewDrawerEvent, getters.playerParamsSelectedMode || 'MANUAL');
    commit(types.SET_MULTIVIEW_SUBMIT_BTN_LOADING, true);
    try {
      await api.setPlayerParams({
        eventId: multiviewDrawerEvent.eventId,
        sport: sportNameById,
        data: payload,
      });

      dispatch('loadMultiviewDrawer', multiviewDrawerEvent.eventId);
      commit(types.SET_MULTIVIEW_SUBMIT_BTN_ENABLED, false);

      dispatch('addNotification', {
        message: 'Player params successfully edited',
        type: 'success',
      });
    } catch {
      dispatch('addNotification', {
        message: 'Submitting has not been processed because of an error',
        type: 'error',
      });
    }
    commit(types.SET_MULTIVIEW_SUBMIT_BTN_LOADING, false);
  },
  setIsMultiviewSimulateBtnFrozen({ commit }, isFrozen) {
    commit(types.SET_MULTIVIEW_SIMULATE_BTN_FROZEN, isFrozen);
  },
  async getPriceOnDemandMultiview({ getters, dispatch, commit }, isPricingPreview = false) {
    const { multiviewDrawerEvent, multiviewPlayerParams } = getters;
    if (!multiviewPlayerParams?.teamA?.playerProps?.length && !multiviewPlayerParams?.teamB?.playerProps?.length) return;
    const playerParamsData = {
      teamA: multiviewPlayerParams?.teamA?.playerProps,
      teamB: multiviewPlayerParams?.teamB?.playerProps,
    };
    const priceOnDemandPayload = mapPriceOnDemandPayload(multiviewDrawerEvent, playerParamsData, isPricingPreview);
    const sportNameById = getSportNameBySportId(multiviewDrawerEvent.sportId).toLowerCase();
    commit(types.SET_IS_MULTIVIEW_DRAWER_FROZEN, true);
    try {
      const response = await api.getPriceOnDemand({
        eventId: multiviewDrawerEvent.eventId,
        sport: sportNameById,
        data: priceOnDemandPayload,
      });
      if (!response) return;

      // For the case where the request from previous event is loaded after we open new one
      if (router.currentRoute?.value?.query?.event !== multiviewDrawerEvent.eventId) return;

      const marketsByPlayerIndex = mapMarketsByPlayerIndex(response.data.playerMarkets);
      const playerParamsWithMarketPrices = mergePlayerDataWithMarketPrices(
        playerParamsData,
        marketsByPlayerIndex,
      );
      const newData = cloneDeep(multiviewPlayerParams);
      newData.teamA.playerProps = playerParamsWithMarketPrices.teamA;
      newData.teamB.playerProps = playerParamsWithMarketPrices.teamB;
      commit(types.SET_MULTIVIEW_PLAYER_PARAMS, newData);
      commit(types.SET_IS_MULTIVIEW_DRAWER_FROZEN, false);
      commit(types.SET_MULTIVIEW_NUMBER_OF_SIMULATE_ATTEMPTS, 0);
      commit(types.SET_MULTIVIEW_SIMULATE_BTN_ENABLED, false);
      commit(types.SET_IS_MULTIVIEW_SIMULATE_BY_SOCKET_CHANGE_ENABLED, false);
      socket.subscribeToPricingOnDemand(multiviewDrawerEvent.eventId);
      dispatch('addNotification', {
        message: 'Prices have been successfully updated!',
        type: 'success',
        duration: 2000,
      });
    } catch (e) {
      let errorMessages = e.messages || [];
      if (e?.statusCode === 503) {
        const numberOfAttempts = getters.numberOfSimulateAttempts;
        if (numberOfAttempts < 5) {
          commit(types.SET_MULTIVIEW_NUMBER_OF_SIMULATE_ATTEMPTS, numberOfAttempts + 1);
          dispatch('getPriceOnDemandMultiview');
          return;
        }
        errorMessages = ['Unable to get prices, try again in 10 seconds'];
        commit(types.SET_MULTIVIEW_SIMULATE_BTN_FROZEN, true);
      }
      commit(types.SET_IS_MULTIVIEW_DRAWER_FROZEN, false);
      commit(types.SET_MULTIVIEW_NUMBER_OF_SIMULATE_ATTEMPTS, 0);

      // Fallback if there are aren't any messages
      if (!errorMessages.length) {
        errorMessages.push('Error while loading prices');
      }
      forEach(errorMessages, (message) => {
        dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    }
  },
  setIsMultiviewSimulateBySocketChangeEnabled({ commit }, isEnabled) {
    commit(types.SET_IS_MULTIVIEW_SIMULATE_BY_SOCKET_CHANGE_ENABLED, isEnabled);
  },
  setIsTradingSimulateBySocketChangeEnabled({ commit }, isEnabled) {
    commit(types.SET_IS_TRADING_SIMULATE_BY_SOCKET_CHANGE_ENABLED, isEnabled);
  },
  async updateMultiviewPlayerParams({ getters, commit }, data) {
    if (getters.multiviewDrawerLoading) return;
    const { multiviewPlayerParams } = getters;
    const updatedMultiviewPlayerParams = updatePlayerPropsDataTradingUI(multiviewPlayerParams, data, getters.multiviewSquad);
    commit(types.SET_MULTIVIEW_PLAYER_PARAMS, updatedMultiviewPlayerParams);
  },
  async toggleMultiviewDrawerEventHighlight({ getters, commit }) {
    if (!getters.multiviewDrawerEvent) return;

    const oldEvent = cloneDeep(getters.multiviewDrawerEvent);

    try {
      commit(types.SET_MULTIVIEW_DRAWER_PROCESSING, true);
      const newEvent = cloneDeep(getters.multiviewDrawerEvent);
      if (oldEvent.eventHighlightsByEventId.nodes.length) {
        set(newEvent, 'eventHighlightsByEventId.nodes', []);
      } else {
        set(newEvent, 'eventHighlightsByEventId.nodes', [{}]);
      }
      commit(types.SET_MULTIVIEW_DRAWER_EVENT, newEvent);
      await api.toggleEventHighlight({
        eventId: oldEvent.eventId,
        isHighlighted: !!newEvent.eventHighlightsByEventId.nodes.length,
      });
    } catch (error) {
      console.error(error);
      commit(types.SET_MULTIVIEW_DRAWER_EVENT, oldEvent);
    } finally {
      commit(types.SET_MULTIVIEW_DRAWER_PROCESSING, false);
    }
  },
  async setMultiviewDrawerPricingMode({ getters, commit }, pricingMode) {
    if (
      !getters.multiviewDrawerEvent
      || pricingMode.id === getters.multiviewDrawerEvent?.booked?.nodes?.[0]?.id
    ) return;

    const oldEvent = cloneDeep(getters.multiviewDrawerEvent);

    try {
      commit(types.SET_MULTIVIEW_DRAWER_PROCESSING, true);
      const newEvent = cloneDeep(getters.multiviewDrawerEvent);
      set(newEvent, 'booked.nodes', [{
        ...(newEvent.booked?.nodes?.[0] || {}),
        pricingMode: pricingMode.id,
      }]);
      commit(types.SET_MULTIVIEW_DRAWER_EVENT, newEvent);
      await api.bookEvent({ eventId: oldEvent.eventId });
    } catch (error) {
      console.error(error);
      commit(types.SET_MULTIVIEW_DRAWER_EVENT, oldEvent);
    } finally {
      commit(types.SET_MULTIVIEW_DRAWER_PROCESSING, false);
    }
  },
  async reloadMultiviewDrawer({ getters, dispatch }) {
    if (!getters.multiviewDrawerEvent) return;
    const id = getters.multiviewDrawerEvent.eventId;
    dispatch('clearMultiviewDrawer', id);
    dispatch('loadMultiviewDrawer', id);
  },
  clearMultiviewDrawer({ getters, commit }, eventId) {
    socket.unsubscribeMultiviewEvent(eventId || getters.multiviewDrawerEvent?.eventId);
    socket.unsubscribeFromPlayerProps(eventId || getters.multiviewDrawerEvent?.eventId);
    socket.unsubscribeFromPricingOnDemand(eventId || getters.multiviewDrawerEvent?.eventId);
    socket.unsubscribeFromPricingProbabilities(eventId || getters.multiviewDrawerEvent?.eventId);
    commit(types.SET_MULTIVIEW_DRAWER_EVENT, null);
    commit(types.SET_MULTIVIEW_DRAWER_LOADING, false);
    commit(types.SET_TRADING_EVENT_MARKETS, {});
    commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, null);
    commit(types.SET_MARKET_GROUPS, []);
    commit(types.SET_MULTIVIEW_ORIGINAL_PLAYER_PARAMS, null);
    commit(types.SET_MULTIVIEW_LAST_SIMULATE_PLAYER_PARAMS, null);
    commit(types.SET_MULTIVIEW_PLAYER_PARAMS, null);
    commit(types.SET_MULTIVIEW_SQUAD, null);
    commit(types.SET_PARAMS_MANAGER_MARKETS, null);
  },
  updateMultiviewDrawerPricingProbablities({ getters, commit }, data) {
    const event = getters.multiviewDrawerEvent;
    const pricingProbabilitiesToUpdate = find(event.pricingProbabilitiesByEventId.nodes, (item) => item.eventId === data.eventId);
    if (pricingProbabilitiesToUpdate) {
      pricingProbabilitiesToUpdate.probabilities = data.probabilities;
    }
    commit(types.SET_MULTIVIEW_DRAWER_EVENT, event);
  },
  loadMarketDisplayConfigurations(_, sportId) {
    const options = {
      filter: {
        sportId: {
          in: [sportId],
        },
      },
    };
    return new Promise((resolve, reject) => {
      api.loadMarketDisplayConfigurations(options)
        .then((response) => {
          const allMarketDisplayConfigurations = response?.data?.allMarketDisplayConfigurations?.nodes || [];
          resolve(allMarketDisplayConfigurations);
        })
        .catch((errorMessage) => {
          reject(errorMessage);
        });
    });
  },
  loadSelectionDisplayConfigurations(_, sportId) {
    const options = {
      filter: {
        sportId: {
          in: [sportId],
        },
      },
    };
    return new Promise((resolve, reject) => {
      api.loadSelectionDisplayConfigurations(options)
        .then((response) => {
          const allSelectionDisplayConfigurations = response?.data?.allSelectionDisplayConfigurations?.nodes || [];
          resolve(allSelectionDisplayConfigurations);
        })
        .catch((errorMessage) => {
          reject(errorMessage);
        });
    });
  },
  loadAllMarketParameters({ commit }) {
    return new Promise((resolve, reject) => {
      api.loadMarketParameters()
        .then((response) => {
          const allMarketParameters = response?.data?.allMarketParameters?.nodes || [];
          commit(types.SET_ALL_MARKET_PARAMETERS, allMarketParameters);
          resolve(allMarketParameters);
        })
        .catch((errorMessage) => {
          commit(types.SET_ALL_MARKET_PARAMETERS, {});
          reject(errorMessage);
        });
    });
  },
  loadAllSelectionParameters({ commit }) {
    return new Promise((resolve, reject) => {
      api.loadSelectionParameters()
        .then((response) => {
          const allSelectionParameters = response?.data?.allSelectionParameters?.nodes || [];
          commit(types.SET_ALL_MARKET_PARAMETERS, allSelectionParameters);
          resolve(allSelectionParameters);
        })
        .catch((errorMessage) => {
          commit(types.SET_ALL_MARKET_PARAMETERS, {});
          reject(errorMessage);
        });
    });
  },
  updateMarketDisplayConfiguration(_, payload) {
    return new Promise((resolve, reject) => {
      api.manuallyUpdateMarketConfig(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateSelectionDisplayConfiguration(_, payload) {
    return new Promise((resolve, reject) => {
      api.manuallyUpdateSelectionConfig(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadAllMarketGroupsList(_, sportId) {
    const options = {
      filter: {
        sportId: {
          in: [sportId],
        },
      },
    };
    return new Promise((resolve, reject) => {
      api.loadAllMarketGroupsList(options)
        .then((response) => {
          let marketGroups = response?.data?.marketGroups?.nodes || [];
          marketGroups = map(marketGroups, (marketGroup) => ({
            ...marketGroup,
            marketCodes: map(marketGroup?.marketCodes?.nodes, (marketCode) => marketCode.marketCode),
          }));
          resolve(marketGroups);
        })
        .catch((errorMessage) => {
          reject(errorMessage);
        });
    });
  },
  updateMarketGroup(_, payload) {
    return new Promise((resolve, reject) => {
      api.manuallyUpdateMarketGroup(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  removeMarketGroup({ dispatch }, marketGroupId) {
    return new Promise((resolve, reject) => {
      api.manuallyRemoveMarketGroup(marketGroupId)
        .then((response) => {
          dispatch('addNotification', {
            message: 'Market group successfully removed',
            type: 'success',
          });
          resolve(response);
        }).catch((err) => {
          dispatch('addNotification', {
            message: 'Unable to remove market group.',
            type: 'error',
          });
          reject(err);
        });
    });
  },
  async loadAllMarketCodes({ commit }, { searchValue, marketCodes }) {
    const options = {
      first: 50,
      offset: 0,
      filter: {
        code: { includesInsensitive: searchValue },
      },
    };
    if (marketCodes.length) {
      options.filter.code = { ...options.filter.code, ...{ notIn: marketCodes } };
    }
    commit(types.UPDATE_MARKET_GROUP_MARKET_CODE_LIST_LOADING, true);
    const res = await api.useSearchAllMarketCodesQuery({
      queryOptions: options,
    });

    commit(types.UPDATE_MARKET_GROUP_MARKET_CODE_LIST_LOADING, false);
    commit(types.UPDATE_MARKET_GROUP_MARKET_CODE_LIST, map(res.nodes, (marketCode) => marketCode.code));
  },
  // odds checker
  async loadOddsCheckerEvents({ commit, getters }, {
    searchQuery, sportId, competitionIds, stateIds, routeName,
  }) {
    const playerMarketCodes = ['PLAYER_PASSING_ATTEMPTS_OVER_UNDER', 'PLAYER_PASSING_COMPLETIONS_OVER_UNDER', 'PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER',
      'PLAYER_PASSING_YARDS_OVER_UNDER', 'PLAYER_RECEIVING_YARDS_OVER_UNDER', 'PLAYER_RECEPTIONS_OVER_UNDER', 'PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER',
      'PLAYER_RUSHING_YARDS_OVER_UNDER', 'PLAYER_TOUCHDOWN_X_OR_MORE', // american football
      'PLAYER_POINT_OVER_UNDER', 'PLAYER_REBOUND_OVER_UNDER', 'PLAYER_ASSIST_OVER_UNDER', 'PLAYER_THREE_POINTER_OVER_UNDER', // basketball
      'PLAYER_GOAL_OVER_UNDER', 'PLAYER_FIRST_GOALSCORER', 'PLAYER_LAST_GOALSCORER', 'PLAYER_ANYTIME_GOALSCORER', // hockey
      'PLAYER_STRIKEOUT_OVER_UNDER', 'PLAYER_BASES_OVER_UNDER', 'PLAYER_RUN_OVER_UNDER', 'PLAYER_HOME_RUN', // hockey
    ];
    const coreMarketCodes = ['RESULT', 'GOAL_OVER_UNDER', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'GOAL_HANDICAP', 'POINT_HANDICAP', 'RUN_HANDICAP'];
    const marketCodes = routeName === 'playerProps'
      ? playerMarketCodes
      : coreMarketCodes;
    if (!isEmpty(getters.oddsCheckerEvents)) {
      socket.unsubscribeOddsCheckerEvents(map(getters.oddsCheckerEvents, (event) => event.eventId), concat(coreMarketCodes, playerMarketCodes));
      commit(types.UPDATE_ODDS_CHECKER_EVENTS, {});
    }

    try {
      commit(types.SET_ODDS_CHECKER_EVENTS_LOADING, true);

      const queryOptions = {
        filter: {
          startsAt: {
            greaterThanOrEqualTo: computeStartsAt(),
          },
          bookedEventsByEventIdExist: true,
        },
        condition: {},
        marketsFilter: {},
        marketsCondition: {},
      };

      if (sportId) {
        queryOptions.filter.sportId = {
          in: [sportId],
        };
        queryOptions.marketsFilter.marketCode = {
          in: marketCodes,
        };
        queryOptions.marketsCondition = {
          displayStatus: 'ON',
        };
        queryOptions.marketsFilter.source = {
          in: ['INTERNAL_AGGREGATION', 'DPS', 'BET_365'],
        };
      }

      if (searchQuery) {
        queryOptions.condition.eventNameSimilar = searchQuery;
      }

      if (competitionIds?.length) {
        queryOptions.filter.competitionId = {
          in: competitionIds,
        };
      }

      if (stateIds?.length) {
        queryOptions.filter.matchState = {
          in: stateIds,
        };
      } else {
        queryOptions.filter.matchState = {
          notIn: 'FINISHED',
        };
      }

      const response = await api.queryOddsCheckerEvents(queryOptions);
      const eventsObj = response.data?.allEvents?.events.reduce((events, event) => ({ ...events, [event.eventId]: event }), {});
      commit(types.UPDATE_ODDS_CHECKER_EVENTS, eventsObj);
      const eventIds = map(response.data?.allEvents?.events, (event) => event.eventId);
      commit(types.UPDATE_ODDS_CHECKER_QUERY_OPTIONS, {
        searchQuery,
        sportId,
        competitionIds,
        stateIds,
        routeName,
        eventIds,
        marketCodes,
      });
      socket.subscribeOddsCheckerEvents(eventIds, marketCodes);
    } catch (error) {
      console.error('Querying events failed', error);
      commit(types.UPDATE_ODDS_CHECKER_EVENTS, {});
    } finally {
      commit(types.SET_ODDS_CHECKER_EVENTS_LOADING, false);
    }
  },
  unloadOddsCheckerEvents({ getters, commit }) {
    if (getters.oddsCheckerQueryOptions) {
      const { eventIds, marketCodes } = getters.oddsCheckerQueryOptions;
      socket.unsubscribeOddsCheckerEvents(eventIds, marketCodes);
    }
    commit(types.UPDATE_ODDS_CHECKER_EVENTS, {});
    commit(types.SET_ODDS_CHECKER_EVENTS_LOADING, false);
  },
  reloadOddsCheckerEvents({ getters, dispatch }) {
    dispatch('unloadOddsCheckerEvents');
    dispatch('loadOddsCheckerEvents', getters.oddsCheckerQueryOptions);
  },
  async loadFiltersMeta({ commit, dispatch }, data) {
    if (data.competitionsOnly) {
      const competitions = await api.getCompetitionsWithEvents({
        sportId: data.sportId,
        startDate: computeStartsAt(),
      });
      commit(types.UPDATE_ODDS_CHECKER_FILTERS_META, { competitions });
      dispatch('loadOddsCheckerEvents', { sportId: data.sportId, stateIds: data.statesQuery, routeName: data.routeName });
      return;
    }
    const response = await api.fetchEligibleSports();
    const selectedSportId = data.sportId || response.data.sports.nodes[0]?.sportId;
    const competitions = await api.getCompetitionsWithEvents({
      sportId: selectedSportId,
      startDate: computeStartsAt(),
    });
    commit(types.UPDATE_ODDS_CHECKER_FILTERS_META, { sports: response.data.sports.nodes, competitions });
    dispatch('loadOddsCheckerEvents', {
      sportId: selectedSportId,
      competitionIds: data.competitionsQuery,
      stateIds: data.statesQuery,
      routeName: data.routeName,
    });
  },
  batchUpdateOddsCheckerMarkets({ commit, getters }, incomingMarkets) {
    each(incomingMarkets, (market) => {
      const event = cloneDeep(getters.oddsCheckerEventById(market.eventId));
      if (!event) return;
      const updatedMarkets = mergeOddsCheckerMarkets(event.markets?.nodes, market);
      if (updatedMarkets.update) commit(types.UPDATE_ODDS_CHECKER_EVENT_BY_ID, { ...event, markets: { nodes: updatedMarkets.markets } });
    });
  },
  updateOddsCheckerEvents({ getters, commit }, data) {
    const {
      eventId,
      eventName,
      startDateTime: startsAt,
      matchStatus: matchState,
      isUSAView: isUsaView,
    } = data ?? {};

    if (!getters.oddsCheckerEventById(eventId)) return;

    let event = cloneDeep(getters.oddsCheckerEventById(eventId));
    event = {
      ...event,
      eventName,
      startsAt,
      matchState,
      isUsaView,
    };
    commit(types.UPDATE_ODDS_CHECKER_EVENT_BY_ID, event);
  },
  updateMarketFavorites({ getters, commit }, { marketCode, sportId }) {
    let marketFavorites = cloneDeep(getters.marketFavorites);
    if (find(marketFavorites, (favorite) => favorite.code === marketCode)) {
      api.updateMarketFavorites({ marketCode, sportId, action: 'delete' })
        .then(() => {
          marketFavorites = filter(marketFavorites, (favorite) => favorite.code !== marketCode);
          commit(types.SET_MARKET_FAVORITES, marketFavorites);
        })
        .catch((error) => console.log(error));
    } else {
      api.updateMarketFavorites({ marketCode, sportId, action: 'post' })
        .then(() => {
          marketFavorites.push({ code: marketCode });
          commit(types.SET_MARKET_FAVORITES, marketFavorites);
        })
        .catch((error) => console.log(error));
    }
  },
  async reloadTradingEventState({ commit, dispatch }, { id, feed }) {
    let event;
    try {
      commit(types.SET_TRADING_EVENT_LOADING, true);
      event = await api.findTradingEventById(id);
      commit(types.SET_TRADING_EVENT, {
        ...event,
        isSuspended: !!event?.operatorEventSuspensionsByEventId?.nodes?.length,
      });
      socket.subscribeToEvents({
        ...eventHelper.mapEventIdAndSource(event, feed),
        type: socketEventTypes.TRADING,
      });
      commit(types.SET_TRADING_EVENT_LOADING, false);
    } catch {
      dispatch('addNotification', {
        message: 'Error while loading event data',
        type: 'error',
      });
      commit(types.SET_TRADING_EVENT, null);
      commit(types.SET_TRADING_EVENT_MARKETS, {});
      commit(types.SET_TRADING_EVENT_FEEDS, []);
      commit(types.SET_TRADING_EVENT_SELECTED_FEED, null);
      commit(types.SET_TRADING_EVENT_LOADING, false);
    }
  },
  setTradingEventSelectedFeed({
    dispatch, state, getters, commit,
  }, feed) {
    if (state.tradingEvent?.eventId) {
      const eventSource = getters.tradingEventSelectedFeed || eventHelper.getEventDetailsSource(state.tradingEvent);
      socket.unsubscribeFromEvents({
        ...eventHelper.mapEventIdAndSource(state.tradingEvent, eventSource),
        type: socketEventTypes.TRADING,
      });
    }
    commit(types.SET_TRADING_EVENT_SELECTED_FEED, feed);
    dispatch('reloadTradingEventState', { id: state.tradingEvent.eventId, feed });
  },
  async loadAuditLogEvent({ commit, dispatch }, id) {
    if (!id) return;
    let event;

    // Getting event
    try {
      commit(types.SET_AUDIT_LOG_EVENT_LOADING, true);
      event = await api.findAuditLogEventById(id);
      commit(types.SET_AUDIT_LOG_EVENT, event);
      const { marketDisplayConfigurations } = await api.getMarketDisplayConfigurations({ sportId: event?.sportId });
      const auditLogEventMarkets = event.markets.nodes;
      const auditLogEventMarketsObject = auditLogEventMarkets.reduce((markets, market) => ({ ...markets, [market.marketId]: market }), {});
      const auditLogEventMarketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
        ...markets,
        [`${market.marketCode}_${market.selections}`]: market,
      }), {});
      commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, auditLogEventMarketsDisplayConfiguration);
      commit(types.SET_AUDIT_LOG_EVENT_MARKETS, auditLogEventMarketsObject);
    } catch {
      dispatch('addNotification', {
        message: 'Error while loading event data',
        type: 'error',
      });
      commit(types.SET_AUDIT_LOG_EVENT, null);
      commit(types.SET_AUDIT_LOG_EVENT_MARKETS, {});
      commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, null);
    } finally {
      commit(types.SET_AUDIT_LOG_EVENT_LOADING, false);
    }
  },
  clearAuditLogEvent({ getters, commit }) {
    if (!getters.auditLogEvent) return;
    commit(types.SET_AUDIT_LOG_EVENT, null);
    commit(types.SET_AUDIT_LOG_EVENT_MARKETS, {});
    commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, null);
  },
  loadMarketHistoryDashboard({ commit, dispatch }, { marketId, query, timezone }) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_AUDIT_LOG_MARKET_HISTORY_DASHBOARD_LOADING, true);
        api.getMarketMetabaseDashboard({ marketId, query, timezone })
          .then((dashboardResponse) => {
            commit(types.SET_MARKET_HISTORY_SELECTIONS_URLS, dashboardResponse?.urlPerSelectionId);
            commit(types.SET_AUDIT_LOG_MARKET_HISTORY_DASHBOARD_LOADING, false);
            resolve();
          });
      } catch {
        dispatch('addNotification', {
          message: 'Error while loading market history dashboard',
          type: 'error',
        });
        commit(types.SET_AUDIT_LOG_MARKET_HISTORY_DASHBOARD_LOADING, false);
        reject();
      }
    });
  },

  // Market templates
  saveMarketTemplateMarketsScrollPosition({ commit }, scrollPosition) {
    commit(types.SET_MARKET_TEMPLATES_SCROLL_POSITION, scrollPosition);
  },
  async disableEvent({ dispatch }, eventId) {
    try {
      await api.disableEvent(eventId);

      dispatch('addNotification', {
        message: 'Event disabled',
        type: 'success',
      });
    } catch {
      dispatch('addNotification', {
        message: 'Error while disabling event',
        type: 'error',
      });
    }
  },
  processEventDisabled({ state, getters, commit }, eventId) {
    if (router.currentRoute.value.name === 'trading-ui-multiview') {
      const event = cloneDeep(getters.multiviewListEventsById[eventId]);
      if (event) {
        commit(types.SET_MULTIVIEW_LIST_EVENTS_BY_ID, {
          ...getters.multiviewListEventsById,
          [eventId]: {
            ...event,
            limbo: true,
          },
        });
      }
      return;
    }

    if (router.currentRoute.value.name === 'trading-ui') {
      const event = cloneDeep(getters.tradingEvent);
      if (event) {
        commit(types.SET_TRADING_EVENT, {
          ...event,
          limbo: true,
        });
      }
      return;
    }

    if (router.currentRoute.value.name === 'params-setup') {
      const playersSetupData = cloneDeep(getters.playerSetupData);
      if (playersSetupData) {
        commit(types.SET_PLAYER_SETUP_DATA, {
          ...playersSetupData,
          limbo: true,
        });
      }
      return;
    }

    if (router.currentRoute.value.name === 'manual-resulting') {
      const events = cloneDeep(getters.manualResultingEvents);
      if (events) {
        commit(types.SET_MANUAL_RESULTING_EVENTS,
          map(events, (event) => (event.eventId === eventId ? { ...event, limbo: true } : event)));
      }
      return;
    }

    if (router.currentRoute.value.name === 'core-markets') {
      const event = cloneDeep(getters.oddsCheckerEvents[eventId]);
      if (event) {
        commit(types.UPDATE_ODDS_CHECKER_EVENTS, {
          ...getters.oddsCheckerEvents,
          [eventId]: {
            ...event,
            limbo: true,
          },
        });
      }
      return;
    }

    if (getters.event && getters.event.eventId === eventId) {
      const event = cloneDeep(getters.event);
      const updatedEvent = {
        ...event,
        limbo: true,
      };
      commit(types.UPDATE_EVENT, updatedEvent);
    }
    if (!getters.eventList?.length) return;
    const eventIndex = findIndex(state.eventList, { eventId });
    if (eventIndex === -1) return;

    const updatedEvents = cloneDeep(state.eventList);
    updatedEvents[eventIndex] = {
      ...updatedEvents[eventIndex],
      limbo: true,
    };
    commit(types.UPDATE_EVENT_LIST, updatedEvents);
  },
  processEventBooked({ state, getters, commit }, eventId) {
    if (getters.event && getters.event.id === eventId) {
      const event = cloneDeep(getters.event);
      const updatedEvent = {
        ...event,
        isBooked: true,
      };
      commit(types.UPDATE_EVENT, updatedEvent);
    }
    if (!getters.eventList?.length) return;
    const eventIndex = findIndex(state.eventList, { eventId });
    if (eventIndex === -1) return;

    const updatedEvents = cloneDeep(state.eventList);
    updatedEvents[eventIndex] = {
      ...updatedEvents[eventIndex],
      booked: true,
    };
    commit(types.UPDATE_EVENT_LIST, updatedEvents);
  },
  updateCompetitionsSubscriptionsFilters({ state, commit, dispatch }, { type, data }) {
    if (type === 'clear') {
      commit(types.UPDATE_COMPETITIONS_SUBSCRIPTIONS_FILTERS, {
        subscription: null,
        mapping: null,
      });
      return;
    }
    if (type === 'subscription') {
      const newFilter = {
        ...state.competitionsSubscriptionsFilters,
        subscription: data,
      };
      commit(types.UPDATE_COMPETITIONS_SUBSCRIPTIONS_FILTERS, newFilter);
      dispatch('loadSidebar');
      return;
    }
    const newFilter = {
      ...state.competitionsSubscriptionsFilters,
      mapping: data,
    };
    commit(types.UPDATE_COMPETITIONS_SUBSCRIPTIONS_FILTERS, newFilter);
    dispatch('loadSidebar');
  },
  async getMarketDisplayConfigurations({ commit }, sportId) {
    await api.getMarketDisplayConfigurations({ sportId })
      .then(({ marketDisplayConfigurations }) => {
        const marketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
          ...markets,
          [`${market.marketCode}_${market.selections}`]: market,
        }), {});
        commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, marketsDisplayConfiguration);
      })
      .catch((err) => {
        console.error(err);
        commit(types.SET_DISPLAY_MARKETS_CONFIGURATION, {});
      });
  },
  toggleClientSettingsModal({ commit }, opened) {
    commit(types.TOGGLE_CLIENT_SETTINGS_MODAL, opened);
  },
  subscribeToProductHealth(_, operatorIds) {
    socket.subscribeToProductHealth(operatorIds);
  },
  updateProductHealth({ getters, dispatch }, payload) {
    const operators = getters.operatorsList;
    const foundOperator = find(operators, (operator) => operator.operatorId === payload.operatorId);
    if (!foundOperator) return;
    const operatorProduct = find(foundOperator.productHealthStatuses, (product) => product.product === payload.product);
    if (!operatorProduct) return;
    const product = payload.product === 'odds-feed' ? 'Odds feed' : 'SGP';
    let message = '';
    let notificationType = 'success';
    let changeDetected = false;
    if (payload.hasOverride && payload.overrideStatus !== operatorProduct.overrideStatus) {
      message = `${payload.operatorId} ${product} product was manually overriden to ${payload.overrideStatus}`;
      notificationType = payload.overrideStatus === 'UP' ? 'success' : 'error';
      changeDetected = true;
    }
    if (!payload.hasOverride && operatorProduct.overrideStatus !== null) {
      message = `${payload.operatorId} ${product} product manual override was reset and product is now ${payload.productStatus}`;
      notificationType = payload.productStatus === 'UP' ? 'success' : 'error';
      changeDetected = true;
    }
    if (!payload.hasOverride && operatorProduct.productStatus !== payload.productStatus) {
      message = `${payload.operatorId} ${product} product is now ${payload.productStatus}`;
      notificationType = payload.productStatus === 'UP' ? 'success' : 'error';
      changeDetected = true;
    }
    if (!changeDetected) return;
    dispatch('addNotification', {
      message,
      type: notificationType,
      duration: 5000,
    });
    dispatch('getAllOperators');
  },
};
